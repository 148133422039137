















































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import { formatNumber } from '@/i18n';
import { PublishStatus } from '@/store/types';
import { LocationsActions } from '@/store/modules/locations/types';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Modal from '@/components/layout/modal.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import LocationReviewForm from '@/components/forms/location-review.vue';

export default Vue.extend({
  name: 'QuickReviewLocationsView',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    Modal,
    SubmitButton,
    LocationReviewForm,
    NotificationBanner,
  },
  data() {
    return {
      explanation: '',
      localIsDeclining: false,
    };
  },
  computed: {
    ...mapState('locations', {
      total: 'quickReviewTotal',
    }),
    ...mapGetters('locations', {
      isLoading: 'quickReviewIsLoading',
      isLoaded: 'quickReviewIsLoaded',
      isSaving: 'quickReviewIsSaving',
      isSaved: 'quickReviewIsSaved',
      isFailed: 'quickReviewIsFailed',
      isFetching: 'quickReviewIsFetching',
      isApproving: 'quickReviewIsApproving',
      isDeclining: 'quickReviewIsDeclining',
    }),
    totalItems(): string {
      return formatNumber(this.total);
    },
  },
  methods: {
    ...mapActions('locations', {
      save: LocationsActions.SAVE_LOCATIONS_QUICK_REVIEW,
    }),
    resetLocalState() {
      this.explanation = '';
      this.localIsDeclining = false;
    },
    approve() {
      this.localIsDeclining = false;
      this.$nextTick(() => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.save({ approved: true }).then(() => this.resetLocalState());
          }
        });
      });
    },
    decline() {
      this.localIsDeclining = true;
      this.$nextTick(() => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.save({
              approved: false,
              explanation: this.explanation,
            }).then(() => this.resetLocalState());
          }
        });
      });
    },
    goBack() {
      this.$router.push({
        name: 'locations',
        query: {
          status: String(PublishStatus.Pending),
        },
      });
    },
  },
});
