












import Vue from 'vue';

export default Vue.extend({
  name: 'SortOrderControl',
  computed: {
    order: {
      get(): string {
        return this.$route.query.sort ? this.$route.query.sort : 'created_at';
      },
      set(val): void {
        this.$emit('requestSort', val);
      },
    },
  },
});
