





































import Vue from 'vue';
import PaginationControl from '@/components/controls/pagination.vue';
import BlogListItem from '@/components/blocks/blog-list-item.vue';
import { mapState, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BlogListBlock',
  components: {
    PaginationControl,
    BlogListItem,
  },
  computed: {
    ...mapState('blog', {
      posts: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('blog', ['isLoading', 'isLoaded', 'isEmpty']),
    highlightFirst() {
      return this.pagination.currentPage === 1;
    },
    highlightedList() {
      if (!this.isLoaded || !this.highlightFirst) {
        return [];
      }

      return this.posts.slice(0, 3);
    },
    postsList() {
      if (!this.isLoaded) {
        return [];
      }

      if (this.highlightFirst) {
        if (this.posts.length < 4) {
          return [];
        }

        return this.posts.slice(3);
      }

      return this.posts;
    },
  },
});
