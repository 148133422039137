






















import Vue from 'vue';

interface DropdownItem {
  icon?: string;
  label: string;
  hasTopBorder?: boolean;
  count?: number;
  disabled?: boolean;
}

export interface DropdownItems {
  [key: string]: DropdownItem;
}

export default Vue.extend({
  name: 'DropdownControl',
  props: {
    items: {
      type: Object as () => DropdownItems,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleOutsideClick(event: MouseEvent): void {
      if (!event.target || !this.$el.contains(event.target as Node)) {
        this.isOpen = false;
      }
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    handleClick(itemValue: string) {
      if (this.items[itemValue].disabled) {
        return;
      }

      this.$emit('click', itemValue);
      this.isOpen = false;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
});
