

















































































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import createSlug from '@/utils/slug';
import { formatNumber } from '@/i18n';

import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import { ReportsActions } from '@/store/modules/reports/types';
import FormInput from '@/components/controls/form-input.vue';
import SelectInput from '@/components/controls/select-input.vue';

export default mixins(tableViewMixin).extend({
  name: 'ResourceDownloadsView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    SubmitButton,
    FormInput,
    SelectInput,
  },
  data() {
    return {
      interval: '30',
      start: '',
      end: '',
    };
  },
  computed: {
    ...mapState('statistics', ['downloads', 'downloadsPagination']),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapGetters('statistics', {
      isLoading: 'downloadsIsLoading',
      isLoaded: 'downloadsIsLoaded',
      isEmpty: 'downloadsIsEmpty',
    }),
    dropdownItems() {
      const items: DropdownItems = {};
      if (this.isSuperAdmin) {
        items.report = {
          label: this.$t('Generate Report'),
          icon: 'document',
        };
      }
      return items;
    },
  },
  methods: {
    ...mapActions('reports', {
      requestReport: ReportsActions.REQUEST,
    }),
    formatNumber,
    createSlug,
    applyFilters() {
      const query: { days: string; start?: string; end?: string } = {
        days: this.interval,
      };

      if (this.interval === 'custom') {
        query.start = this.start;
        query.end = this.end;
      }

      this.$router.push({
        name: 'resource-downloads',
        query,
      });
    },
    handleDropdownClick(action: string): void {
      if ('report' === action) {
        this.requestReport();
      }
    },
  },
  mounted() {
    if (this.$route.query) {
      if (this.$route.query.days === '7') {
        this.interval = '7';
        this.applyFilters();
      } else if (this.$route.query.days === 'custom') {
        this.interval = 'custom';

        if (this.$route.query.start) {
          this.start = this.$route.query.start;
        }

        if (this.$route.query.end) {
          this.end = this.$route.query.end;
        }
        this.applyFilters();
      }
    }
  },
});
