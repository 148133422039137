import httpClient from '@/api/http-client';
import { createCRUDEndpoints } from '@/api/factory/crud';
import { createTranslateEndpoint } from '@/api/factory/translate';

import { CategoryJSON } from '@/store/modules/categories/category';
import { RequestConfig } from '../types';

type ListParams =
  | {
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove, restore } = createCRUDEndpoints<CategoryJSON, ListParams>({
  slug: 'category',
});

export const translate = createTranslateEndpoint<CategoryJSON>({ slug: 'category' });

export function listAll(
  params?: { products?: number[]; treatments?: number[]; search?: string },
  config?: RequestConfig,
) {
  return httpClient.get<CategoryJSON[]>(`categories/list`, { params, ...config });
}

export function getLaserCategory(config?: RequestConfig) {
  return httpClient.get<CategoryJSON>('category/laser', config);
}
