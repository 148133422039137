









































































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import Modal from '@/components/layout/modal.vue';
import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import CheckboxControl from '@/components/controls/checkbox.vue';
import Autocomplete, {
  AutocompleteOption,
  AutocompleteResponse,
  AutocompletePromise,
} from '@/components/controls/autocomplete.vue';
import TranslationsControl from '@/components/controls/translations.vue';

import Taxonomy from '@/store/models/taxonomy';
import { TreatmentActions } from '@/store/modules/treatments/types';
import { EditableStatus } from '@/store/types';

export default Vue.extend({
  inject: {
    $validator: '$validator',
  },
  name: 'EditTreatmentView',
  components: {
    Modal,
    FormInput,
    SubmitButton,
    NotificationBanner,
    CheckboxControl,
    Autocomplete,
    TranslationsControl,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productsInput: '',
    };
  },
  computed: {
    ...mapGetters(['isDirect']),
    ...mapState('treatments', {
      treatment: 'editable',
      translationsStatus: 'translationsStatus',
    }),
    ...mapGetters('treatments', {
      isLoading: 'editableIsLoading',
      isLoaded: 'editableIsLoaded',
      isSaving: 'editableIsSaving',
      isSaved: 'editableIsSaved',
      isFailed: 'editableIsFailed',
      hasUnsavedChanges: 'editableHasUnsavedChanges',
    }),
    ...mapState('products', {
      products: 'tree',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    getProducts(): AutocompleteOption[] {
      const ids = this.treatment.products.map(({ id }) => id);

      return this.products
        .filter(({ id }) => ids.indexOf(id) === -1)
        .map(({ id, title }) => ({
          label: title,
          value: id,
        }))
        .filter(({ label }) => label.toLowerCase().indexOf(this.productsInput.toLowerCase()) > -1);
    },
  },
  methods: {
    ...mapActions('treatments', {
      updateTreatment: TreatmentActions.UPDATE_EDITABLE,
      saveTreatment: TreatmentActions.SAVE_EDITABLE,
      saveTranslations: TreatmentActions.SAVE_TRANSLATIONS,
    }),
    updateMultipleProducts(val) {
      this.updateTreatment({
        multipleProducts: Boolean(val),
      });
    },

    close(): void {
      if (this.hasUnsavedChanges) {
        const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

        if (answer) {
          this.goBack();
        }
      } else {
        this.goBack();
      }
    },

    goBack(): void {
      if (this.isDirect) {
        this.$router.push({
          name: 'treatments',
        });
      } else {
        this.$router.back();
      }
    },

    addProduct(option: AutocompleteOption) {
      const products = [...this.treatment.products];
      const product = this.products.find((item) => item.id === option.value);
      if (!product) {
        return;
      }

      products.push(Taxonomy.fromJSON(product.toJSON()));

      this.updateTreatment({ products });
    },

    removeProduct(productId: number) {
      const products = this.treatment.products.filter(({ id }) => id !== productId);

      this.updateTreatment({ products });
    },
  },
});
