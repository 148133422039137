




























































import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import isEqual from 'lodash/isEqual';

import { EditableStatus, ListStatus, RootActions } from '@/store/types';
import Country from '@/store/models/country';
import { SettingsActions } from '@/store/modules/settings/types';
import { NotificationsActions } from '@/store/modules/notifications/types';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Publish from '@/components/forms/publish.vue';
import Tabs from '@/components/layout/tabs.vue';
import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';

import GeneralSettingsForm from './general-settings.vue';
import CountrySettingsForm from './country-settings.vue';
import LegalSettingsForm from './legal-settings.vue';
import SupportSettingsForm from './support-settings.vue';
import EmailSettingsForm from './email-settings.vue';

export default Vue.extend({
  name: 'SettingsView',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    NotificationBanner,
    Publish,
    Tabs,
    Autocomplete,
    GeneralSettingsForm,
    CountrySettingsForm,
    LegalSettingsForm,
    SupportSettingsForm,
    EmailSettingsForm,
  },
  computed: {
    ...mapState('settings', {
      editableStatus: 'editableSettingsStatus',
    }),
    ...mapGetters('settings', {
      hasUnsavedChanges: 'editableSettingsHasUnsavedChanges',
    }),
  },
  methods: {
    ...mapActions('settings', {
      saveEditable: SettingsActions.SAVE_EDITABLE_SETTINGS,
    }),
    ...mapActions('notifications', {
      showError: NotificationsActions.SHOW_ERROR,
      hideErrors: NotificationsActions.HIDE_NOTIFICATION,
    }),
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.hideErrors();
          this.saveEditable();
        } else {
          this.showError({
            title: this.$t('Error'),
            body: this.$t('Please correct the errors below and try again:'),
            errorList: this.errors.all(),
          });
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
});
