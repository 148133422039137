

















import Vue from 'vue';
import EventItem from '@/components/blocks/events-widget-item.vue';
import { mapState, mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    EventItem,
  },
  computed: {
    ...mapState('events', {
      eventsList: 'list',
    }),
    ...mapGetters('events', ['listIsLoaded', 'listIsLoading', 'listIsEmpty']),
  },
});
