import axios, {
  AxiosPromise,
  CancelToken,
  AxiosError,
  AxiosResponse,
  CancelTokenSource,
  Method,
  HeadersDefaults,
  AxiosRequestHeaders,
} from 'axios';
import { addBreadcrumb, Severity } from '@sentry/browser';
import store from '@/store';
import config from '@/config';

const api = axios.create({
  baseURL: config.BLOG_API,
});

api.defaults.headers.common = {
  Authorization: '',
};

/**
 * Simple utility to apstract API calls
 */
export default {
  request(
    method: Method,
    url: string,
    data?: object,
    params?: object,
    cancelToken?: CancelToken | undefined,
  ): AxiosPromise<any> {
    addBreadcrumb({
      category: 'blog-api-request',
      message: 'Blog API Request made',
      data: {
        url,
        data,
        method,
        params,
      },
      level: Severity.Info,
    });

    return api.request({ url, data, method, params, cancelToken });
  },

  get(url: string, params?: object, cancelToken?: CancelToken | undefined): AxiosPromise<any> {
    return this.request('get', url, null, params, cancelToken);
  },

  post(url: string, data: object, cancelToken?: CancelToken | undefined): AxiosPromise<any> {
    return this.request('post', url, data, cancelToken);
  },

  put(url: string, data: object, cancelToken?: CancelToken | undefined): AxiosPromise<any> {
    return this.request('put', url, data, cancelToken);
  },

  delete(url: string, data: object = {}, cancelToken?: CancelToken | undefined): AxiosPromise<any> {
    return this.request('delete', url, data, cancelToken);
  },

  init() {
    api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (!axios.isCancel(error)) {
          store.dispatch('ERROR', error);
        }

        return Promise.reject(error);
      },
    );

    return this;
  },

  // Intercept the request to make sure the token is injected into the header.
  setToken(token: string): void {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  removeToken(): void {
    delete api.defaults.headers.common.Authorization;
  },

  getCancelTokenSource(): CancelTokenSource {
    return axios.CancelToken.source();
  },

  isCancel(value: any): boolean {
    return axios.isCancel(value);
  },
};
