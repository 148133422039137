

















































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import { formatNumber } from '@/i18n';

import Pagination from '@/store/models/pagination';
import { TreatmentActions } from '@/store/modules/treatments/types';
import PublicSite, { PublicSiteStatus } from '@/store/modules/treatments/public-site';

import CheckboxControl from '@/components/controls/checkbox.vue';
import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import PublicSitesFilter from '@/components/forms/public-sites-filter.vue';
import CountryName from '@/components/blocks/country-name.vue';

export default mixins(tableViewMixin).extend({
  name: 'PublicSitesView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    PublicSitesFilter,
    CheckboxControl,
    CountryName,
  },
  data() {
    return {
      selectedItems: [],
      PublicSiteStatus,
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('treatments', {
      publicSites: 'publicSiteList',
      pagination: 'publicSiteListPagination',
    }),
    ...mapGetters('treatments', {
      isLoading: 'publicSiteListIsLoading',
      isLoaded: 'publicSiteListIsLoaded',
      isEmpty: 'publicSiteListIsEmpty',
    }),
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.publicSites.length;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('treatments', {
      restoreAction: TreatmentActions.RESTORE_PUBLIC_SITE,
      removeAction: TreatmentActions.DELETE_PUBLIC_SITE,
    }),
    dropdownItems(publicSite: PublicSite) {
      const items: DropdownItems = {
        edit: {
          label: this.$t('Edit'),
          icon: 'pencil',
        },
      };

      if (publicSite.status === PublicSiteStatus.Inactive) {
        items.restore = {
          label: this.$t('Restore'),
          icon: 'restore',
        };
      } else {
        items.remove = {
          label: this.$t('Delete'),
          hasTopBorder: true,
          icon: 'trash',
        };
      }

      return items;
    },
    handleClick(publicSite: PublicSite, action: string) {
      if ('edit' === action) {
        this.$router.push({
          name: 'edit-public-site',
          params: { id: String(publicSite.id) },
        });
      }

      if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this public site?'));

        if (answer) {
          this.restoreAction({ id: publicSite.id });
        }
      }

      if ('remove' === action) {
        const answer = window.confirm(this.$t('Do you really want to delete this public site?'));

        if (answer) {
          this.removeAction({ id: publicSite.id });
        }
      }
    },
    isSelected(publicSite: PublicSite): boolean {
      return this.selectedItems.indexOf(publicSite.id) !== -1;
    },
    selectItem(publicSite: PublicSite) {
      if (this.isSelected(publicSite)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== publicSite.id);
        return;
      }

      this.selectedItems.push(publicSite.id);
    },
    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = (this.publicSites as PublicSite[]).map((publicSite) => publicSite.id);
      }
    },
  },
});
