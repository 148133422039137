






























import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import LocationsList from '@/components/blocks/locations-list.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import ViewToggleControl from '@/components/controls/view-toggle.vue';
import { formatNumber } from '@/i18n';
import { PublishStatus } from '@/store/types';

export default Vue.extend({
  name: 'LocationsView',
  components: {
    LocationsList,
    NotificationBanner,
    ViewToggleControl,
  },
  data() {
    return {
      listView: 'grid',
      status: '',
    };
  },
  computed: {
    ...mapState('locations', ['pagination']),
    ...mapGetters('locations', ['listIsLoading', 'listIsLoaded']),
    ...mapGetters('user/profile', ['displayName', 'profileImage', 'isSuperAdmin', 'isAdmin']),
    totalItems(): string {
      if (this.listIsLoaded) {
        return formatNumber(this.pagination.total);
      }

      return '0';
    },
    hasMoreLocations(): boolean {
      return this.pagination.total > 8 ? true : false;
    },
    showQuickReviewButton(): boolean {
      if (!this.listIsLoaded) {
        return false;
      }

      if (!this.isSuperAdmin && !this.isAdmin) {
        return false;
      }

      return this.status === String(PublishStatus.Pending) && this.pagination.total > 0;
    },
  },
  methods: {
    defaultView(): string {
      return this.isSuperAdmin || this.isAdmin || this.pagination.total > 5 ? 'table' : 'grid';
    },
    initDefaults(): void {
      this.listView = this.defaultView();
    },
  },
  watch: {
    listIsLoaded: 'initDefaults',
    $route: {
      handler(route) {
        const { query = {} } = route;
        const { status = '' } = query;

        this.status = status;
      },
      immediate: true,
    },
  },
  mounted() {
    this.initDefaults();
  },
});
