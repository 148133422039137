


































































































































































































































































































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import mixins from 'vue-typed-mixins';

import api from '@/api';

import i18n from '@/components/i18n';
import { imageSizeMixin } from '@/components/mixins/image-size';
import ModalWithSidebar from '@/components/layout/modal-with-sidebar.vue';
import Modal from '@/components/layout/modal.vue';
import WistiaVideo from '@/components/layout/wistia-video.vue';
import SelectInput from '@/components/controls/select-input.vue';
import FormInput from '@/components/controls/form-input.vue';
import CheckboxInput from '@/components/controls/checkbox.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import { EventTypes, RootActions } from '@/store/types';

enum devices {
  'Potenza' = 'Potenza',
  'Icon (Skin)' = 'Icon (Skin)',
  'Icon (Hair)' = 'Icon (Hair)',
  'Elite iQ (Skin)' = 'Elite iQ (Skin)',
  'Elite iQ (Hair)' = 'Elite iQ (Hair)',
  'FlexSure' = 'FlexSure',
  'SculpSure' = 'SculpSure',
  'Picosure' = 'Picosure',
  'TempSure Envi' = 'TempSure Envi',
  'TempSure (Firm)' = 'TempSure (Firm)',
}

enum assetTypes {
  'Seasonal Promotion (Flyer)' = 'Seasonal Promotion (Flyer)',
  'Email Template' = 'Email Template',
  'Takeaway Card' = 'Takeaway Card',
  'Social Post' = 'Social Post',
  'Poster' = 'Poster',
  'Event Flyer' = 'Event Flyer',
  'Gift Certificate' = 'Gift Certificate',
  'Referral Card' = 'Referral Card',
  'Banner Stand' = 'Banner Stand',
  'Brochure' = 'Brochure',
}

const headlines = {
  [devices.Potenza]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes['Email Template']]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes['Takeaway Card']]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes['Social Post']]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes.Poster]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer'],
  },
  [devices['TempSure Envi']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes['Email Template']]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes['Takeaway Card']]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes['Social Post']]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes.Poster]: [
      'Show Off Your Skins Potential',
      'Ready. Set. Glow',
      'Your Skin Deserves the Best',
      'The Secret for Smooth, Glowing Skin',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer'],
  },
  [devices['Icon (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Email Template']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Takeaway Card']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Social Post']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes.Poster]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer'],
  },
  [devices['Elite iQ (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Email Template']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Takeaway Card']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Social Post']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes.Poster]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer'],
  },
  [devices.Picosure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Email Template']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Takeaway Card']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Social Post']]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes.Poster]: [
      'Reveal the "real" you with skin revitalization',
      'Restore Your Youthful Skin',
      'Stop the Cover Up',
      'Coming Soon!',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer'],
  },
  [devices.SculpSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes['Email Template']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes['Takeaway Card']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes['Social Post']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes.Poster]: ['New Year, New Sculpted Body', 'Your Best Kept Secret', 'Now Offering', 'Coming Soon'],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House'],
    [assetTypes['Gift Certificate']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes['Referral Card']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
  },
  [devices.FlexSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes['Email Template']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes['Takeaway Card']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes['Social Post']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes.Poster]: ['New Year, New Sculpted Body', 'Your Best Kept Secret', 'Now Offering', 'Coming Soon'],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House'],
    [assetTypes['Gift Certificate']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
    [assetTypes['Referral Card']]: [
      'New Year, New Sculpted Body',
      'Your Best Kept Secret',
      'Now Offering',
      'Coming Soon',
    ],
  },
  [devices['TempSure (Firm)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      "You're Not Alone",
      'Smooth With TempSure',
      'Bare It All',
      "Dimples Aren't Always Cute",
    ],
    [assetTypes['Email Template']]: [
      "You're Not Alone",
      'Smooth With TempSure',
      'Bare It All',
      "Dimples Aren't Always Cute",
    ],
    [assetTypes['Takeaway Card']]: [
      "You're Not Alone",
      'Smooth With TempSure',
      'Bare It All',
      "Dimples Aren't Always Cute",
    ],
    [assetTypes['Social Post']]: [
      "You're Not Alone",
      'Smooth With TempSure',
      'Bare It All',
      "Dimples Aren't Always Cute",
    ],
    [assetTypes.Poster]: ["You're Not Alone", 'Smooth With TempSure', 'Bare It All', "Dimples Aren't Always Cute"],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House'],
    [assetTypes['Gift Certificate']]: [
      "You're Not Alone",
      'Smooth With TempSure',
      'Bare It All',
      "Dimples Aren't Always Cute",
    ],
    [assetTypes['Referral Card']]: [
      "You're Not Alone",
      'Smooth With TempSure',
      'Bare It All',
      "Dimples Aren't Always Cute",
    ],
  },
  [devices['Icon (Hair)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Email Template']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Takeaway Card']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Social Post']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes.Poster]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House'],
    [assetTypes['Gift Certificate']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Referral Card']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
  },
  [devices['Elite iQ (Hair)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Email Template']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Takeaway Card']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Social Post']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes.Poster]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House'],
    [assetTypes['Gift Certificate']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
    [assetTypes['Referral Card']]: [
      "We've Got Your Back",
      'Freedom From Unwanted Hair',
      'Feel Natural, Be Natural',
      'Unwanted Hair Is So Last Season',
    ],
  },
};

const eventContents = {
  live_demonstrations: 'Live Demonstrations',
  light_bites_and_refreshments: 'Light Bites and Refreshments',
  special_event_pricing: 'Special Event Pricing',
  raffle_prices_giveaways_more: 'Raffle Prizes Giveaways & More!',
};

const ctas = {
  [devices.Potenza]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['TempSure Envi']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Icon (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Elite iQ (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices.Picosure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices.SculpSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices.FlexSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['TempSure (Firm)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Icon (Hair)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Elite iQ (Hair)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
};

const modelImageAssetTypes = [
  assetTypes['Seasonal Promotion (Flyer)'],
  assetTypes['Email Template'],
  assetTypes['Takeaway Card'],
  assetTypes['Social Post'],
  assetTypes.Poster,
  assetTypes['Event Flyer'],
];

const beforeAfterAssetTypes = [
  assetTypes['Seasonal Promotion (Flyer)'],
  assetTypes['Email Template'],
  assetTypes['Takeaway Card'],
  assetTypes.Poster,
  assetTypes['Event Flyer'],
];

const eventDataAssetTypes = [assetTypes['Event Flyer']];

const discounts = {
  [devices.Potenza]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['TempSure Envi']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Icon (Skin)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Elite iQ (Skin)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices.Picosure]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices.SculpSure]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices.FlexSure]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['TempSure (Firm)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Icon (Hair)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Elite iQ (Hair)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
};

const practiceInfoAssetTypes = [
  assetTypes['Seasonal Promotion (Flyer)'],
  assetTypes['Email Template'],
  assetTypes['Takeaway Card'],
  assetTypes['Event Flyer'],
  assetTypes['Gift Certificate'],
  assetTypes['Referral Card'],
  assetTypes['Banner Stand'],
  assetTypes.Poster,
  assetTypes.Brochure,
];

const skinTypes = {
  'Type 1': {
    image: {
      src: require('@/assets/images/skin_1.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 2': {
    image: {
      src: require('@/assets/images/skin_2.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 3': {
    image: {
      src: require('@/assets/images/skin_3.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 4': {
    image: {
      src: require('@/assets/images/skin_4.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 5': {
    image: {
      src: require('@/assets/images/skin_5.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 6': {
    image: {
      src: require('@/assets/images/skin_6.jpg'),
      width: 200,
      height: 200,
    },
  },
};

export default mixins(imageSizeMixin).extend({
  name: 'ResourcePersonalize',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    i18n,
    ModalWithSidebar,
    Modal,
    WistiaVideo,
    SelectInput,
    FormInput,
    CheckboxInput,
    SubmitButton,
    ImageUploadControl,
  },
  data() {
    return {
      formStep: 1,
      isSubmitting: false,
      isSuccess: false,
      isError: false,
      device: '',
      asset_type: '',
      headline: '',
      use_ba_images: '',
      call_to_action: '',
      event_date: '',
      event_location: '',
      event_content: [],
      practice_logo: null,
      practice_address: '',
      practice_phone: '',
      model_gender: '',
      model_age: '',
      model_body: '',
      model_skin: '',
      discount: '',
      isHowToModalOpen: false,
    };
  },
  computed: {
    ...mapState('user/profile', ['currentProfile']),
    skinTypes() {
      return skinTypes;
    },
    devices() {
      if (this.currentProfile && Array.isArray(this.currentProfile.personalization)) {
        return Object.keys(devices)
          .filter((deviceKey) => this.currentProfile.personalization.includes(devices[deviceKey]))
          .reduce((obj, key) => {
            obj[key] = devices[key];
            return obj;
          }, {});
      }
      return {};
    },
    assetTypes() {
      return assetTypes;
    },
    headlines() {
      return headlines;
    },
    eventContents() {
      return eventContents;
    },
    ctas() {
      return ctas;
    },
    modelImageAssetTypes() {
      return modelImageAssetTypes;
    },
    beforeAfterAssetTypes() {
      return beforeAfterAssetTypes;
    },
    eventDataAssetTypes() {
      return eventDataAssetTypes;
    },
    practiceInfoAssetTypes() {
      return practiceInfoAssetTypes;
    },
    discounts() {
      return discounts;
    },
  },
  methods: {
    ...mapActions([RootActions.DISPATCH_EVENT]),
    submitFirstStep() {
      this.isSubmitting = true;
      this.isSuccess = false;
      this.isError = false;

      this.$validator
        .validateAll('firstStep')
        .then((result) => {
          this.isSubmitting = false;
          if (result) {
            this.formStep = 2;
          }
        })
        .catch((err) => {
          this.isSubmitting = false;
          throw err;
        });
    },
    submitSecondStep() {
      this.isSubmitting = true;
      this.isSuccess = false;
      this.isError = false;

      this.$validator
        .validateAll('secondStep')
        .then((result) => {
          if (result) {
            return api.personalize
              .personalize({
                product: this.device,
                template: this.asset_type,
                headline: this.headline || null,
                model_gender: this.model_gender || null,
                model_age: this.model_age || null,
                model_skin: this.model_skin || null,
                model_body: this.model_body || null,
                use_ba_images: this.use_ba_images || null,
                event_date: this.event_date || null,
                event_location: this.event_location || null,
                address: this.practice_address || null,
                phone: this.practice_phone || null,
                call_to_action: this.call_to_action || null,
                logo: this.practice_logo && this.practice_logo.id ? { id: this.practice_logo.id } : null,
                event_content: {
                  live_demonstrations: this.event_content.includes('live_demonstrations'),
                  light_bites_and_refreshments: this.event_content.includes('light_bites_and_refreshments'),
                  special_event_pricing: this.event_content.includes('special_event_pricing'),
                  raffle_prices_giveaways_more: this.event_content.includes('raffle_prices_giveaways_more'),
                },
                discount: this.discount || null,
              })
              .then(() => {
                this.isSuccess = true;
                this[RootActions.DISPATCH_EVENT]({
                  type: EventTypes.PERSONALIZE,
                  eventObj: { product: this.device, asset_type: this.asset_type },
                });
                setTimeout(() => {
                  this.$emit('close');
                }, 500);
              });
          }
        })
        .then(() => {
          this.isSubmitting = false;
        })
        .catch((err) => {
          this.isSubmitting = false;
          this.isError = true;
          throw err;
        });
    },
    onClose() {
      if (!this.isSubmitting) {
        this.$emit('close');
      }
    },
    resetValues(device: keyof devices, assetType: keyof assetTypes) {
      if (
        typeof this.headlines[device] === 'undefined' ||
        typeof this.headlines[device][assetType] === 'undefined' ||
        !Array.isArray(this.headlines[device][assetType]) ||
        !this.headlines[device][assetType].includes(this.headline)
      ) {
        this.headline = '';
      }

      if (
        typeof this.ctas[device] === 'undefined' ||
        typeof this.ctas[device][assetType] === 'undefined' ||
        !Array.isArray(this.ctas[device][assetType]) ||
        !this.ctas[device][assetType].includes(this.call_to_action)
      ) {
        this.call_to_action = '';
      }

      if (
        typeof this.discounts[device] === 'undefined' ||
        typeof this.discounts[device][assetType] === 'undefined' ||
        !Array.isArray(this.discounts[device][assetType]) ||
        !this.discounts[device][assetType].includes(this.discount)
      ) {
        this.discount = '';
      }
    },
    openHowToModal() {
      this.isHowToModalOpen = true;
    },
  },
  watch: {
    device(value) {
      this.resetValues(value, this.asset_type);
    },
    asset_type(value) {
      this.resetValues(this.device, value);
    },
  },
  created() {
    const keys = Object.keys(this.devices);
    if (keys.length === 1) {
      this.device = this.devices[keys[0]];
    }
  },
});
