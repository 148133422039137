














import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'LoginIndicatorLayout',
  computed: {
    ...mapState(['loadProgress']),
    isActive(): boolean {
      return this.loadProgress > 0 && this.loadProgress < 100 ? true : false;
    },
    isComplete(): boolean {
      return this.loadProgress >= 100 ? true : false;
    },
  },
});
