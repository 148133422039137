


























































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import ResourceBlock from '@/components/blocks/resource.vue';
import ResourceSearchForm from '@/components/forms/resource-search.vue';

export default Vue.extend({
  name: 'FeaturedResourcesWidgetBlock',
  components: {
    ResourceBlock,
    ResourceSearchForm,
  },
  computed: {
    /**
     * --- RESOURCES ---
     */
    ...mapState('resources', ['featured', 'recentlyViewedResources']),
    ...mapGetters('resources', [
      'recentlyViewedIsLoaded',
      'recentlyViewedIsLoading',
      'recentlyViewedIsEmpty',
      'featuredIsLoaded',
      'featuredIsLoading',
      'featuredIsEmpty',
    ]),
  },
  methods: {
    doResourceSearch({ search }): void {
      this.$router.push({
        name: 'resources',
        params: {
          page: 1,
        },
        query: {
          search,
        },
      });
    },
  },
});
