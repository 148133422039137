























import Vue, { PropOptions } from 'vue';

import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';
import { JSONModel } from '@/store/models/jsonModel';

export interface MultiSelectValueItem {
  id: number | string;
  title: string;
}

export default Vue.extend({
  name: 'MultiSelectInput',
  inject: {
    $validator: '$validator',
  },
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.validationValue;
    },
  },
  components: {
    Autocomplete,
  },
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    value: {
      type: Array,
      default: () => [],
    } as PropOptions<MultiSelectValueItem[]>,
    options: {
      type: Array,
      required: true,
    } as PropOptions<MultiSelectValueItem[]>,
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    validationValue() {
      return this.value.join(',');
    },
    autocompleteOptions(): AutocompleteOption[] {
      return (this.options as MultiSelectValueItem[]).map((option) => ({
        value: option.id,
        label: option.title,
      }));
    },
  },
  methods: {
    search(option: AutocompleteOption) {
      this.searchInput = option.label;
      this.$emit('search-input', this.searchInput);
    },
    add(option: AutocompleteOption) {
      const selectedOption = (this.options as MultiSelectValueItem[]).find((item) => item.id === option.value);
      if (selectedOption) {
        if (selectedOption instanceof JSONModel) {
          this.$emit('input', [...this.value, selectedOption.clone()]);
        } else {
          this.$emit('input', [...this.value, { ...selectedOption }]);
        }
      }
    },
    remove(removeId: number | string) {
      this.$emit('input', [...this.value.filter((item) => item.id !== removeId)]);
    },
  },
});
