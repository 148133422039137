import 'vue-svgicon/dist/polyfill';
import Vue, { PluginObject } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as svgicon from 'vue-svgicon';
import './sass/main.scss';
import VueMoment from 'vue-moment';
import moment, { Moment } from 'moment';
import VueTransmit from 'vue-transmit';
import './i18n';
import './plugins/vee-validate';
import Modal from './plugins/modal';
import TrackOutboundPlugin from './plugins/track-outbound';
import GTMPlugin from './plugins/gtm';
import Drift from './plugins/drift';
import { addDispatchStoreErrorInterceptor } from '@/api/http-client';
// TODO: define typings for this lib
import VueIntro from 'vue-introjs';
import 'intro.js/introjs.css';
import PortalVue from 'portal-vue';

declare global {
  interface Window {
    loader: (isLoading: boolean) => void;
  }
}

window.loader(true);

addDispatchStoreErrorInterceptor(store);

Vue.config.productionTip = false;

// TODO: move all these to plugins
Vue.use(svgicon, {
  tagName: 'svgicon',
  classPrefix: 'o-svg',
});

Vue.use(VueMoment as unknown as PluginObject<(vue: Vue, options?: { moment: Moment }) => void>, { moment });

Vue.use(VueTransmit);

Vue.use(Modal);

Vue.use(TrackOutboundPlugin);

Vue.use(GTMPlugin, { router });

Vue.use(Drift);

Vue.use(VueIntro);

Vue.use(PortalVue);

Vue.use((vue) => {
  const stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  vue.directive('click-outside', {
    bind(el, bindings, vnode) {
      const { value } = bindings;

      el.addEventListener('click', stopPropagation);
      document.body.addEventListener<'click'>('click', value);
    },
    unbind(el, { value }) {
      el.removeEventListener('click', stopPropagation);
      document.body.removeEventListener<'click'>('click', value);
    },
  });
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

if (process.env.VUE_APP_MODE !== 'development') {
  import('@/plugins/sentry');
}
