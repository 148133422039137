






















import Vue from 'vue';
import { mapState } from 'vuex';

import i18n from '@/i18n';
import { ListStatus } from '@/store/types';
import SelectInput from '@/components/controls/select-input.vue';

export default Vue.extend({
  name: 'CountrySelectionControl',
  inheritAttrs: false,
  inject: ['$validator'],
  components: {
    SelectInput,
  },
  props: {
    name: {
      type: String,
      default: 'country',
    },
    label: {
      type: String,
      default: i18n.t('Country'),
    },
    value: {
      type: Number,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['countries', 'countriesStatus']),
    areCountriesLoaded() {
      return this.countriesStatus === ListStatus.Loaded;
    },
    stringifiedValue() {
      return this.value ? String(this.value) : '';
    },
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event.target.value);
    },
  },
});
