
































































import Vue, { PropOptions } from 'vue';
import debounce from 'lodash/debounce';
import { AxiosError } from 'axios';

import api from '@/api';
import { RootActions } from '@/store/types';

export interface AutocompleteOption {
  label: string;
  value?: string | number;
  frequency?: number;
}

export interface AutocompleteResponse {
  results: AutocompleteOption[];
}

export type AutocompletePromise = Promise<AutocompleteResponse>;
export type AutocompleteRequest = (userInput: string) => AutocompletePromise;

export default Vue.extend({
  name: 'AutocompleteControl',
  inject: {
    $validator: '$validator',
  },
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value.value;
    },
  },
  props: {
    /**
     * Allow string values not available in the autocomplete list
     */
    allowFreeEntry: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    buttonText: {
      type: String,
      default: 'Search',
    },
    options: {
      type: Array,
    },
    request: {
      type: Function,
      default: (userInput: string): AutocompletePromise => {
        return Promise.resolve({
          results: [],
        });
      },
    },
    value: {
      type: Object,
      default: () => {
        return {
          label: '',
          value: undefined,
          frequency: 1,
        };
      },
    } as PropOptions<{
      label: string;
      value?: string | number;
      frequency?: number;
    }>,
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      dropdownOpen: false,
      selectionPointer: -1,
      isLoading: false,
      autocompleteOptions: [],
      autocompleteAttr:
        'autocomplete_' +
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, 5),
    };
  },
  computed: {
    hasIcon() {
      return !!this.icon;
    },
    hasStaticOptions(): boolean {
      return typeof this.options !== 'undefined';
    },
    selectOptions(): AutocompleteOption[] {
      return this.options ? this.options : this.autocompleteOptions;
    },
    areOptionsEmpty(): boolean {
      return this.selectOptions.length === 0;
    },
    isOpen: {
      get(): boolean {
        return this.dropdownOpen && !this.disabled;
      },
      set(val: boolean) {
        this.dropdownOpen = val;
      },
    },
    infoMsg() {
      if (this.areOptionsEmpty) {
        return this.$t('No results.');
      }

      return this.$t('Select an item from the list or filter using search above.');
    },
    hasError() {
      return this.errors.has(this.name);
    },
    fieldError() {
      return this.errors.items.find((item) => item.field === this.name);
    },
    fieldErrorRule(): string {
      if (!this.fieldError || !this.fieldError.rule) {
        return '';
      } else {
        return this.fieldError.rule;
      }
    },
    fieldErrorMsg(): string {
      if (!this.fieldError || !this.fieldError.msg) {
        return '';
      } else {
        if (this.label) {
          return this.fieldError.msg.replace(this.name, this.label);
        }

        return this.fieldError.msg;
      }
    },
    showFieldError(): boolean {
      return Boolean(this.fieldErrorMsg.length);
    },
  },
  methods: {
    /**
     * Check the item index against the index pointer
     */
    isSelectedOption(index: number): boolean {
      return this.selectionPointer === index ? true : false;
    },
    /**
     * Change value and emit input and select events
     */
    selectOption(option: AutocompleteOption): void {
      const value: AutocompleteOption = {
        label: '',
        value: undefined,
      };

      if (option) {
        value.label = option.label || '';
        value.value = option.value;
      }

      if (this.closeOnSelect) {
        this.value.label = value.label;
        this.value.value = value.value;
      }

      this.$emit('input', { ...value });
      this.$emit('select', { ...value });

      if (this.closeOnSelect) {
        this.close(value);
      } else {
        this.$refs.input.focus();
      }
    },
    /**
     * Handles label input change event
     */
    handleChange(event: InputEvent) {
      const target = event.target as HTMLInputElement;

      if (target.value !== this.value.label) {
        this.value.value = undefined;
      }
      this.value.label = target.value;

      this.$emit('input', { ...this.value });
      this.$emit('search-input', { ...this.value });
      this.loadOptions(target.value);
    },
    loadOptions(userInput?: string) {
      if (this.hasStaticOptions) {
        this.isOpen = true;
        return;
      }

      this.isLoading = true;
      this.isOpen = true;

      this.debouncedRequest(userInput);
    },
    preformRequest(userInput?: string) {
      this.request(userInput)
        .then((res: AutocompleteResponse) => {
          this.isLoading = false;
          this.autocompleteOptions = res.results;

          if (!this.autocompleteOptions || !this.autocompleteOptions.length) {
            this.isOpen = false;
          }
        })
        .catch((error: AxiosError) => {
          if (!api.isCancel(error)) {
            this.isLoading = false;
            this.$store.dispatch(RootActions.ERROR, error);
          }
        });
    },
    /**
     * Preform search when the input field updates
     */
    debouncedRequest: debounce(function (userInput?: string) {
      this.preformRequest(userInput);
    }, 300),
    /**
     * Move the selection pointer on key events
     */
    handleArrowUp(event: KeyboardEvent): void {
      event.preventDefault();

      if (this.selectionPointer > 0) {
        this.selectionPointer = this.selectionPointer - 1;
      } else {
        this.selectionPointer = this.selectOptions.length - 1;
      }
    },
    /**
     * Move the selection pointer on key events
     */
    handleArrowDown(event: KeyboardEvent): void {
      event.preventDefault();

      if (this.selectionPointer < this.selectOptions.length - 1) {
        this.selectionPointer = this.selectionPointer + 1;
      } else {
        this.selectionPointer = 0;
      }
    },
    /**
     * Update the slection pointer when the mouse hovers over items
     */
    handleMouseSelection(index) {
      this.selectionPointer = index;
    },
    /**
     * Apply the current input on button click event
     */
    handleButtonClick(): void {
      this.selectOption({ ...this.value, value: this.value.label });
    },
    /**
     * Apply the current input/selection on key event
     */
    handleEnter(event: KeyboardEvent): void {
      const target = event.target as HTMLInputElement;

      // Handle custom input when allow free entry is enabled
      if ((this.allowFreeEntry && this.selectionPointer < 0) || this.selectionPointer >= this.selectOptions.length) {
        this.selectOption({ ...this.value, value: this.value.label });
      } else {
        // When only one item is available in the autocomplete list
        if (this.selectOptions.length === 1) {
          this.selectionPointer = 0;
        }

        // Checks if selection is valid
        if (
          this.selectionPointer >= 0 &&
          this.selectionPointer < this.selectOptions.length &&
          this.selectOptions[this.selectionPointer]
        ) {
          this.selectOption(this.selectOptions[this.selectionPointer]);
        }
      }

      target.blur();
    },
    /**
     * Close the autocomplete list when the user clicks outside of it
     */
    handleOutsideClick(event: MouseEvent): void {
      if (!this.isOpen) {
        return;
      }

      if (!event.target) {
        return;
      }

      if (this.$el.contains(event.target)) {
        return;
      }

      this.close();
    },
    handleFocus(event: FocusEvent | MouseEvent): void {
      if (this.isOpen || this.disabled) {
        return;
      }

      if (this.isLoading || (this.selectOptions && this.selectOptions.length)) {
        this.isOpen = true;
        return;
      }

      this.handleChange(event);
    },
    /**
     * Set the selection pointer to a unselected state
     */
    deselect() {
      this.selectionPointer = -1;
    },
    /**
     * Resets compontent to initial state
     */
    reset(): void {
      this.selectOption({
        label: '',
        value: undefined,
        frequency: 1,
      });
    },
    close(value?: AutocompleteOption) {
      this.$refs.input.blur();
      this.isOpen = false;
      this.deselect();

      if (!this.value.value && this.value.label) {
        this.value.label = '';
        this.autocompleteOptions = [];
        this.$emit('input', { ...this.value });
        this.$emit('search-input', { ...this.value });
      }

      this.$emit('closed', value);
    },
  },
  /**
   * Apply any inits and bind event listeners
   */
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  /**
   * Clean up any listeners on teardown
   */
  destroyed() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
});
