import Vue from 'vue';
import Router, { Location, RouteConfig } from 'vue-router';

import store from '@/store';
import { RootActions, RouterDispatchedActionPromiseValue } from '@/store/types';
import { UserRole } from '@/store/modules/user/profile/types';

/**
 * Layout
 */
import MainLayout from '@/layouts/main.vue';
import BasicLayout from '@/layouts/basic.vue';

/**
 * Dashboard
 */
import Dashboard from '@/views/dashboard/index.vue';

/**
 * PrivacyPolicy
 */
import PrivacyPolicy from '@/views/privacy.vue';
import Terms from '@/views/terms.vue';

/**
 * Profile
 */
import EditProfile from '@/views/edit-profile.vue';

/**
 * Auth
 */
import Login from '@/views/login.vue';
import ResetPassword from '@/views/reset-password.vue';
import ConfirmPassword from '@/views/confirm-password.vue';

/**
 * My Practice
 */
import Practice from '@/views/practice.vue';

/**
 * Practices
 */
import Practices from '@/views/practices/all.vue';
import ViewPractice from '@/views/practices/view.vue';
import EditPractice from '@/views/practices/edit.vue';

/**
 * Locations
 */
import Locations from '@/views/locations/all.vue';
import EditLocation from '@/views/locations/edit.vue';
import LocationPreview from '@/views/locations/preview.vue';
import LocationsQuickReview from '@/views/locations/quick-review.vue';

/**
 * Practitioners
 */
import Practitioners from '@/views/practitioners/all.vue';
import EditPractitioner from '@/views/practitioners/edit.vue';

/**
 * Users
 */
import Users from '@/views/users/all.vue';
import EditUser from '@/views/users/edit.vue';

/**
 * Resources
 */
import Resources from '@/views/resources/all.vue';
import Resource from '@/views/resources/view.vue';
import EditResource from '@/views/resources/edit.vue';
import Categories from '@/views/categories/all.vue';
import EditCategory from '@/views/categories/edit.vue';
import ResourceDownloads from '@/views/resources/downloads.vue';

/**
 * Products
 */
import Products from '@/views/products/all.vue';
import EditProduct from '@/views/products/edit.vue';
import Accessories from '@/views/accessories/all.vue';
import EditAccessory from '@/views/accessories/edit.vue';

/**
 * Treatments
 */
import Treatments from '@/views/treatments/all.vue';
import EditTreatment from '@/views/treatments/edit.vue';
import Enhancements from '@/views/treatments/enhancements/all.vue';
import EditEnhancement from '@/views/treatments/enhancements/edit.vue';
import PublicTreatments from '@/views/treatments/public-treatments/all.vue';
import EditPublicTreatment from '@/views/treatments/public-treatments/edit.vue';
import PublicSites from '@/views/treatments/public-sites/all.vue';
import EditPublicSite from '@/views/treatments/public-sites/edit.vue';

/**
 * Reports
 */
import Reports from '@/views/reports/all.vue';

/**
 * Blog
 */
import BlogList from '@/views/blog/index.vue';
import BlogAuthor from '@/views/blog/author.vue';
import BlogPost from '@/views/blog/single.vue';

/**
 * Settings
 */
import Settings from '@/views/settings/index.vue';
import BannedWords from '@/views/settings/banned-words.vue';

/**
 * Consultations
 */
import Consultations from '@/views/consultations/all.vue';
import ViewConsultation from '@/views/consultations/view.vue';

/**
 * Support
 */
import Support from '@/views/support/index.vue';
import GDPRInformationRequest from '@/views/support/gdpr-information-request.vue';

/**
 * Privacy requests
 */
import PrivacyRequests from '@/views/privacy_requests/all.vue';
import ViewPrivacyRequest from '@/views/privacy_requests/view.vue';

/**
 * Other
 */
import Preview from '@/views/preview.vue';
import NotFound from '@/views/404.vue';

Vue.use(Router);

const routes: RouteConfig[] = [
  {
    path: '/',
    component: MainLayout,
    meta: {
      roles: [UserRole.User, UserRole.Sales, UserRole.Clinical, UserRole.Admin, UserRole.SuperAdmin],
    },
    children: [
      /**
       * Dashboard
       */
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
      },

      /**
       * Privacy Policy
       */
      {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPolicy,
      },

      /**
       * Terms & Conditions
       */
      {
        path: '/terms',
        name: 'terms',
        component: Terms,
      },

      /**
       * Locations
       */
      {
        path: '/locations/:page?',
        name: 'locations',
        component: Locations,
        meta: {
          roles: [UserRole.User, UserRole.SuperAdmin, UserRole.Admin],
        },
        children: [
          {
            path: 'quick-review/:id?',
            name: 'locations-quick-review',
            component: LocationsQuickReview,
            meta: {
              roles: [UserRole.SuperAdmin, UserRole.Admin],
            },
          },
        ],
      },
      {
        path: '/location/:id/edit',
        name: 'edit-location',
        component: EditLocation,
        meta: {
          roles: [UserRole.User, UserRole.SuperAdmin, UserRole.Admin],
        },
        children: [
          {
            path: 'preview',
            name: 'preview-edit-location',
            component: LocationPreview,
            meta: {
              keepScrollPosition: true,
            },
          },
        ],
      },
      {
        path: '/location/add',
        name: 'add-location',
        component: EditLocation,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.User, UserRole.SuperAdmin, UserRole.Admin],
        },
      },

      /**
       * Practitioners
       */
      {
        path: '/practitioners/:page?',
        name: 'practitioners',
        component: Practitioners,
        meta: {
          roles: [UserRole.User, UserRole.SuperAdmin, UserRole.Admin],
        },
      },
      {
        path: '/practitioner/:id/edit',
        name: 'edit-practitioner',
        component: EditPractitioner,
        meta: {
          roles: [UserRole.User, UserRole.SuperAdmin, UserRole.Admin],
        },
      },
      {
        path: '/practitioner/add',
        name: 'add-practitioner',
        component: EditPractitioner,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.User, UserRole.SuperAdmin, UserRole.Admin],
        },
      },

      /**
       * Profile
       */
      {
        path: '/profile/edit',
        name: 'edit-profile',
        component: EditProfile,
      },

      /**
       * My Practice
       */
      {
        path: '/practice',
        name: 'practice',
        component: Practice,
        meta: {
          roles: [UserRole.User],
        },
      },

      /**
       * Resource Center
       */
      {
        path: '/resource/categories/:page?',
        name: 'categories',
        component: Categories,
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin],
        },
        children: [
          {
            path: '/resource/categories/edit/:id',
            name: 'edit-category',
            component: EditCategory,
            meta: {
              keepScrollPosition: true,
            },
          },
          {
            path: '/resource/categories/add',
            name: 'add-category',
            component: EditCategory,
            props: {
              add: true,
            },
            meta: {
              keepScrollPosition: true,
            },
          },
        ],
      },
      {
        path: '/resource/downloads/:page?',
        name: 'resource-downloads',
        component: ResourceDownloads,
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin],
        },
      },
      {
        path: '/resource/add',
        name: 'add-resource',
        component: EditResource,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Clinical],
        },
        children: [
          {
            path: 'add-category',
            name: 'add-resource/add-category',
            component: EditCategory,
            props: {
              add: true,
            },
            meta: {
              keepScrollPosition: true,
            },
          },
        ],
      },
      {
        path: '/resources/:page?',
        name: 'resources',
        component: Resources,
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Clinical, UserRole.Sales, UserRole.User],
        },
      },
      {
        path: '/resource/:slug/:id',
        name: 'resource',
        component: Resource,
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Clinical, UserRole.Sales, UserRole.User],
        },
      },
      {
        path: '/resource/:slug/:id/edit',
        name: 'edit-resource',
        component: EditResource,
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Clinical],
        },
        children: [
          {
            path: 'add-category',
            name: 'edit-resource/add-category',
            component: EditCategory,
            props: {
              add: true,
            },
            meta: {
              keepScrollPosition: true,
            },
          },
        ],
      },

      /**
       * Users
       */
      {
        path: '/users/add',
        name: 'add-user',
        component: EditUser,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/users/:page?',
        name: 'users',
        component: Users,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/users/edit/:id',
        name: 'edit-user',
        component: EditUser,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },

      /**
       * Practices
       */
      {
        path: '/practices/add',
        name: 'add-practice',
        component: EditPractice,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin],
        },
      },
      {
        path: '/practices/:page?',
        name: 'practices',
        component: Practices,
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin],
        },
      },
      {
        path: '/practice/:id',
        name: 'view-practice',
        component: ViewPractice,
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin],
        },
      },
      {
        path: '/practice/:id/edit',
        name: 'edit-practice',
        component: EditPractice,
        meta: {
          roles: [UserRole.SuperAdmin, UserRole.Admin],
        },
      },

      /**
       * Products
       */
      {
        path: '/products/add',
        name: 'add-product',
        component: EditProduct,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/products/:page?',
        name: 'products',
        component: Products,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/products/edit/:id',
        name: 'edit-product',
        component: EditProduct,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },

      /**
       * Accessories
       */
      {
        path: '/accessories/:page?',
        name: 'accessories',
        component: Accessories,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
        children: [
          {
            path: '/accessories/edit/:id',
            name: 'edit-accessory',
            component: EditAccessory,
            meta: {
              keepScrollPosition: true,
            },
          },
          {
            path: '/accessories/add',
            name: 'add-accessory',
            component: EditAccessory,
            props: {
              add: true,
            },
            meta: {
              keepScrollPosition: true,
            },
          },
        ],
      },

      /**
       * Treatments
       */
      {
        path: '/treatments/:page?',
        name: 'treatments',
        component: Treatments,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
        children: [
          {
            path: '/treatments/edit/:id',
            name: 'edit-treatment',
            component: EditTreatment,
            meta: {
              keepScrollPosition: true,
            },
          },
          {
            path: '/treatments/add',
            name: 'add-treatment',
            component: EditTreatment,
            props: {
              add: true,
            },
            meta: {
              keepScrollPosition: true,
            },
          },
        ],
      },

      /**
       * Treatment Enhancements
       */
      {
        path: '/enhancements/add',
        name: 'add-enhancement',
        component: EditEnhancement,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/enhancements/edit/:id',
        name: 'edit-enhancement',
        component: EditEnhancement,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/enhancements/:page?',
        name: 'enhancements',
        component: Enhancements,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },

      /**
       * Public Treatments
       */
      {
        path: '/public-treatments/add',
        name: 'add-public-treatment',
        component: EditPublicTreatment,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/public-treatments/edit/:id',
        name: 'edit-public-treatment',
        component: EditPublicTreatment,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/public-treatments/:page?',
        name: 'public-treatments',
        component: PublicTreatments,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },

      /**
       * Public Sites
       */
      {
        path: '/public-sites/add',
        name: 'add-public-site',
        component: EditPublicSite,
        props: {
          add: true,
        },
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/public-sites/edit/:id',
        name: 'edit-public-site',
        component: EditPublicSite,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/public-sites/:page?',
        name: 'public-sites',
        component: PublicSites,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },

      /**
       * Reports
       */
      {
        path: '/reports/:page?',
        name: 'reports',
        component: Reports,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },

      /**
       * Blog
       */
      {
        path: '/blog/:page(\\d+)?',
        name: 'blog',
        component: BlogList,
      },
      {
        path: '/blog/category/:category/:page(\\d+)?',
        name: 'blog-category',
        component: BlogList,
      },
      {
        path: '/blog/author/:author/:page(\\d+)?',
        name: 'blog-author',
        component: BlogAuthor,
      },
      {
        path: '/blog/:slug',
        name: 'blog-post',
        component: BlogPost,
      },

      /**
       * Settings
       */
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        children: [
          {
            path: 'banned-words',
            name: 'banned-words',
            component: BannedWords,
          },
        ],
      },

      /**
       * Consultations
       */
      {
        path: '/consultations/:page?',
        name: 'consultations',
        component: Consultations,
        meta: {
          roles: [UserRole.User, UserRole.SuperAdmin, UserRole.Admin],
        },
        children: [
          {
            path: '/consultations/view/:id',
            name: 'view-consultation',
            component: ViewConsultation,
            meta: {
              roles: [UserRole.User, UserRole.SuperAdmin, UserRole.Admin],
            },
          },
        ],
      },

      /**
       * Support
       */
      {
        path: '/support/gdpr-information-request',
        name: 'gdpr-information-request',
        component: GDPRInformationRequest,
      },
      {
        path: '/support',
        name: 'support',
        component: Support,
      },

      /**
       * Privacy requests
       */
      {
        path: '/privacy-requests/view/:id',
        name: 'view-privacy-request',
        component: ViewPrivacyRequest,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
      {
        path: '/privacy-requests/:page?',
        name: 'privacy-requests',
        component: PrivacyRequests,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },

      /**
       * UI Preview
       */
      {
        path: '/preview',
        name: 'preview',
        component: Preview,
        meta: {
          roles: [UserRole.SuperAdmin],
        },
      },
    ],
  },
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
      },
      {
        path: '/reset',
        name: 'reset',
        component: ResetPassword,
      },
      {
        path: '/confirm',
        name: 'confirm',
        component: ConfirmPassword,
      },
    ],
  },
  {
    // Do NOT put this route as children of '/' path
    path: '*',
    component: MainLayout,
    children: [
      {
        path: '*',
        name: '404',
        component: NotFound,
      },
    ],
  },
];

if (process.env.NODE_ENV === 'development' && routes[1] && routes[1].children) {
  routes[1].children.push({
    path: '/dev-preview',
    name: 'dev-preview',
    component: Preview,
  });
}

/**
 * Main route definition
 */
const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.meta && to.meta.keepScrollPosition) {
      return;
    }

    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 0 },
      };
    }

    return { x: 0, y: 0 };
  },
});

const actionableName = (name: string): string => {
  return name.replace(/[\/-]+/g, '_').toUpperCase();
};

/**
 * Global navigation guard to present the login for unauthenticated users
 */
router.beforeEach(async (to, from, routerNext) => {
  store.dispatch(RootActions.CLEAR_ERRORS);
  store.dispatch(RootActions.LOAD_PROGRESS_RESET);

  const next = (nextTo: string | false | void | Location | undefined = undefined): Promise<void> => {
    store.dispatch(RootActions.LOAD_PROGRESS_COMPLETE);
    window.loader(false); // make sure we hide the global loader

    routerNext(nextTo);

    return Promise.resolve();
  };

  const fromName = from.name ? actionableName(from.name) : 'DIRECT';
  const toName = to.name ? actionableName(to.name) : 'ROOT';

  if (from.name) {
    window.loader(false); // if its not a direct request we can hide the global loader earlier
  }

  const enter: RouterDispatchedActionPromiseValue = await store.dispatch(RootActions.ROUTE_ENTER, { to, from });

  if (enter && enter.next) {
    return next(enter.next);
  }

  const enterFrom: RouterDispatchedActionPromiseValue = await store.dispatch('DISPATCH_ROUTER_ACTION', {
    actionName: 'ROUTE_ENTER_FROM_' + fromName,
    to,
    from,
  });

  if (enterFrom && enterFrom.next) {
    return next(enterFrom.next);
  }

  const enterToFrom: RouterDispatchedActionPromiseValue = await store.dispatch('DISPATCH_ROUTER_ACTION', {
    actionName: 'ROUTE_ENTER_' + toName + '_FROM_' + fromName,
    to,
    from,
  });

  if (enterToFrom && enterToFrom.next) {
    return next(enterToFrom.next);
  }

  const enterTo: RouterDispatchedActionPromiseValue = await store.dispatch('DISPATCH_ROUTER_ACTION', {
    actionName: 'ROUTE_ENTER_' + toName,
    to,
    from,
  });

  if (enterTo && enterTo.next) {
    return next(enterTo.next);
  }

  return next();
});

router.afterEach((to, from) => {
  store.dispatch(RootActions.PAGE_CHANGE, { to, from });
});

export default router;
