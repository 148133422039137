



















import Vue, { VNode } from 'vue';
/**
 * This is a container vue for all of the modal
 * It will get bound at the root element, and act
 * as an airtraffic controller to make sure that the modals
 * don't collide
 */
// Make sure that we don't get wonky namespace colitions
const PREFIX = '_modal_';

// If they don't sent an id, lets make sure its unique
// This will generate a unique id
const generateId = (
  (index = 0) =>
  () =>
    (index++).toString()
)();
import Modal from './Modal.vue';

export default Vue.extend({
  components: {
    Modal,
  },
  data(): {
    modals: {
      id: string;
      modalAttrs: Record<string, any>;
      modalListeners: any;
      component: VNode;
      componentAttrs: Record<string, any>;
    }[];
  } {
    return {
      modals: [],
    };
  },
  // When created, attch this to the root container
  created() {
    // bind this to the root container
    this.$root._dynamicContainer = this;
  },
  methods: {
    add(component, componentAttrs = {}, modalAttrs, modalListeners) {
      const id = generateId();
      const name = modalAttrs.name || PREFIX + id;
      // Cre
      this.modals.push({
        id,
        modalAttrs: { ...modalAttrs, name },
        modalListeners,
        component,
        componentAttrs,
      });
      this.$nextTick(() => {
        this.$modal.show(name);
      });
    },
    remove(id) {
      for (const i in this.modals) {
        if (this.modals[i].id === id) {
          this.modals.splice(i, 1);
          return;
        }
      }
    },
  },
});
