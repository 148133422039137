import Vue from 'vue';
import { PractitionersState, PractitionersMutations } from '@/store/modules/practitioners/types';
import { ListStatus, MutationsTree, EditableStatus } from '@/store/types';
import Practitioner from './practitioner';
import Pagination from '@/store/models/pagination';
import { updateEditable } from '@/store/utils/editable';
import PractitionerRelationship from './practitioner-relationship';

export const mutations: MutationsTree<PractitionersState, typeof PractitionersMutations> = {
  /**
   * List
   */
  [PractitionersMutations.SET_LIST_STATUS](state, status: ListStatus) {
    state.listStatus = status;
  },
  [PractitionersMutations.SET_LIST](state, payload: Practitioner[]) {
    state.practitionersList = payload;
  },
  [PractitionersMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.pagination = payload;
  },

  /**
   * Menu list
   */
  [PractitionersMutations.SET_MENU_LIST_STATUS](state, status: ListStatus) {
    state.menuListStatus = status;
  },
  [PractitionersMutations.SET_MENU_LIST](state, payload: Practitioner[]) {
    state.menuPractitionersList = payload;
  },
  [PractitionersMutations.SET_MENU_LIST_TOTAL](state, payload: number) {
    state.menuListTotalItems = payload;
  },

  /**
   * Practitioners Hours List
   */
  [PractitionersMutations.SET_PRACTITIONERS_HOURS_LIST_STATUS](state, status: ListStatus) {
    state.practitionersHoursListStatus = status;
  },
  [PractitionersMutations.SET_PRACTITIONERS_HOURS_LIST](state, payload: PractitionerRelationship[]) {
    state.practitionersHoursList = payload;
  },

  /**
   * Edit
   */
  [PractitionersMutations.SET_EDITABLE](state, payload: Practitioner) {
    Vue.set(state, 'editablePractitioner', payload);
    Vue.set(state, 'initialEditable', Practitioner.clone(payload));
  },
  [PractitionersMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [PractitionersMutations.UPDATE_EDITABLE]: updateEditable<PractitionersState>('editablePractitioner'),
  [PractitionersMutations.UPDATE_INITIAL_EDITABLE]: updateEditable<PractitionersState>('initialEditable'),
};

export default mutations;
