

















































































































































































































import Vue from 'vue';
import mixins from 'vue-typed-mixins';

import { imageSizeMixin } from '@/components/mixins/image-size';
import ModalWithSidebar from '@/components/layout/modal-with-sidebar.vue';
import i18n from '@/components/i18n';
import SelectInput from '@/components/controls/select-input.vue';
import FormInput from '@/components/controls/form-input.vue';
import CheckboxInput from '@/components/controls/checkbox.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';

export default mixins(imageSizeMixin).extend({
  name: 'ResourcePersonalize',
  components: {
    i18n,
    ModalWithSidebar,
    SelectInput,
    FormInput,
    CheckboxInput,
    SubmitButton,
    ImageUploadControl,
  },
  data() {
    return {
      showPersonalizeModal: false,
      formStep: 1,
      eventSubheading: '',
      event_content: [],
      practice_logo: undefined,
      model_age: '',
      featured_body_part: '',
    };
  },
});
