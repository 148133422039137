var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-card"},[(!_vm.hasPracticeAssigned)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('Please select a practice for this location first.'))+" ")]):(!_vm.isPracticeReady || !_vm.practitionersIsReady)?_c('p',[_vm._v(_vm._s(_vm.$t('Loading...')))]):(!_vm.practitioners.length)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("You don't have any practitioners added yet.")))]),_c('router-link',{staticClass:"c-button c-button--full c-button--primary-transparent",attrs:{"to":{
        name: 'add-practitioner',
        query: { practice_id: _vm.location.practiceId },
      }}},[_vm._v(" "+_vm._s(_vm.$t('Add a Practitioner'))+" ")])],1):_c('draggable',{staticClass:"c-practitioner c-practitioner--tile c-practitioner--draggable",attrs:{"animation":400},model:{value:(_vm.practitioners),callback:function ($$v) {_vm.practitioners=$$v},expression:"practitioners"}},_vm._l((_vm.practitioners),function(practitioner){return _c('div',{key:practitioner.id,staticClass:"c-practitioner__user"},[_c('div',{class:[
          'c-form-element',
          'c-checkbox-wrapper',
          'c-checkbox-wrapper--right',
          { 'is-selected': practitioner.active } ]},[_c('input',{attrs:{"type":"checkbox","id":'practitioner-checkbox-' + practitioner.id},domProps:{"checked":practitioner.active},on:{"click":function($event){return _vm.togglePractitioner(practitioner)}}}),_c('label',{attrs:{"for":'practitioner-checkbox-' + practitioner.id}},[_c('div',{staticClass:"c-user__item"},[_c('span',{staticClass:"c-user__avatar"},[_c('img',{attrs:{"src":practitioner.avatar,"alt":practitioner.title}})]),_c('p',{staticClass:"c-user__content"},[_vm._v(" "+_vm._s(practitioner.title)+" "),(_vm.isSuperAdmin || _vm.isAdmin)?_c('router-link',{staticClass:"u-mb-0 u-ml-2 u-text-underline u-text-no-underline:hover",attrs:{"to":{
                  name: 'edit-practitioner',
                  params: { id: practitioner.id },
                }}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]):_vm._e()],1)])])]),(practitioner.active)?_c('div',{staticClass:"c-practitioner__hours"},[(!practitioner.hours.length)?_c('small',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('edit-hours', practitioner.id)}}},[_vm._v(" "+_vm._s(_vm.$t('Change Work Hours'))+" ")])]):_c('div',{staticClass:"o-row"},[_c('div',{staticClass:"o-col-6@lg"},_vm._l((practitioner.hours.slice(0, Math.round((practitioner.hours.length + 1) / 2))),function(workingDay){return _c('small',{key:workingDay.day},[_vm._v(" "+_vm._s(_vm.workingHoursLabel(workingDay))+" ")])}),0),_c('div',{staticClass:"o-col-6@lg"},[_vm._l((practitioner.hours.slice(Math.round((practitioner.hours.length + 1) / 2))),function(workingDay){return _c('small',{key:workingDay.day},[_vm._v(" "+_vm._s(_vm.workingHoursLabel(workingDay))+" ")])}),_c('small',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('edit-hours', practitioner.id)}}},[_vm._v(" "+_vm._s(_vm.$t('Change Work Hours'))+" ")])])],2)])]):_vm._e()])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }