












import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';

import Modal from '@/components/layout/modal.vue';
import { ProfileActions } from '@/store/modules/user/profile/types';
import MarkdownRederer from '@/components/blocks/markdown-renderer.vue';

export default Vue.extend({
  name: 'TermsModalLayout',
  components: {
    Modal,
    MarkdownRederer,
  },
  computed: {
    ...mapGetters('user/profile', ['showTermsModal']),
    ...mapState('settings', ['settings']),
  },
  methods: {
    ...mapActions('user/profile', {
      save: ProfileActions.SAVE_TERMS_AGREE,
    }),
  },
});
