













































import Vue from 'vue';
import { mapGetters } from 'vuex';

import SubmitButton from '@/components/controls/submit-button.vue';
import PracticeSelectionControl from '@/components/controls/practice-selection.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import { PrivacyRequestStatus } from '@/store/modules/privacy_requests/types';

export default Vue.extend({
  name: 'PrivacyRequestsFilterForm',
  components: {
    SubmitButton,
    PracticeSelectionControl,
    Dropdown,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => [] as number[],
    },
  },
  data() {
    return {
      PrivacyRequestStatus,
      availableStatuses: {
        [PrivacyRequestStatus.Pending]: this.$t('Pending'),
        [PrivacyRequestStatus.Closed]: this.$t('Closed'),
      },
      status: '',
      practice: undefined,
    };
  },
  computed: {
    ...mapGetters('privacy_requests', ['isLoading']),
    dropdownItems() {
      const items: DropdownItems = {};

      items.reset = {
        label: this.$t('Reset Filters'),
        icon: 'close',
      };

      return items;
    },
  },
  methods: {
    initFilters(): void {
      this.status = this.$router.currentRoute.query.status || '';

      let newPracticeValue;

      if (this.$router.currentRoute.query.practice_id) {
        newPracticeValue = Number(this.$router.currentRoute.query.practice_id);

        if (!newPracticeValue || Number.isNaN(newPracticeValue)) {
          newPracticeValue = undefined;
        }
      }

      if (this.practice !== newPracticeValue) {
        this.practice = newPracticeValue;
      }
    },
    applyFilters(): void {
      const { sort, direction, ...queryParams } = this.$route.query;

      this.$router.push({
        params: {
          page: 1,
        },
        query: {
          ...queryParams,
          status: this.status,
          practice_id: this.practice,
        },
      });
    },
    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: 1,
          },
          query: {},
        });
      }
    },
  },
  created(): void {
    this.initFilters();
  },
  watch: {
    $route(): void {
      this.initFilters();
    },
  },
});
