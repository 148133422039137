








import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { RootActions, EventTypes } from '@/store/types';

export default Vue.extend({
  name: 'App',
});
