









































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ContactWidgetBlock',
  computed: {
    ...mapGetters('user/profile', ['isInUK']),
  },
});
