














































import Vue from 'vue';

import { formatDate } from '@/i18n';
import api from '@/api';
import FormInput from '@/components/controls/form-input.vue';
import FileUpload from '@/components/controls/file-upload.vue';
import { addURLParams } from '@/utils/url';

export default Vue.extend({
  name: 'ResourceFileForm',
  model: {
    prop: 'file',
  },
  components: {
    FormInput,
    FileUpload,
  },
  props: {
    parentThumbnail: {
      type: String,
    },
    file: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  data(): {
    isUploading: boolean;
    fileErrors: any[];
  } {
    return {
      isUploading: false,
      fileErrors: [],
    };
  },
  computed: {
    thumbnail(): string {
      if (this.file && this.file.thumbnail) {
        return addURLParams(this.file.thumbnail, {
          'w': '480',
          'h': '660',
          'fit': 'crop',
          'crop': 'focalpoint',
          'fp-x': '0',
          'fp-y': '0',
        });
      }

      if (this.parentThumbnail) {
        return this.parentThumbnail;
      }

      return require('@/assets/images/paper-cover-default.png');
    },
    fileMeta(): string {
      if (!this.file) {
        return '';
      }

      if (this.file.user && this.file.user.name) {
        return this.$t('Uploaded %(date)% by %(userName)%', {
          date: formatDate(this.file.createdAt),
          userName: this.file.user.name,
        });
      }

      return this.$t('Uploaded %(date)%', { date: formatDate(this.file.createdAt) });
    },
  },
  methods: {
    handleUpload(file?: File) {
      if (!file) {
        return;
      }

      let title;

      if (this.file.title && this.file.title.length) {
        title = this.file.title;
      }

      const formData = new FormData();
      formData.append('file', file, file.name);
      if (title) {
        formData.append('title', title);
      }

      this.fileError = false;
      this.isUploading = true;

      api.upload
        .file(formData)
        .then((response) => response.data)
        .then((uploadedFile) => {
          this.emitFile(uploadedFile);

          this.isUploading = false;
        })
        .catch((error) => {
          let errorMessage: string[] = [];

          if (error && error.response && error.response.data && error.response.data.errors) {
            const errorValues = Object.keys(error.response.data.errors)
              .filter((key) => key === 'file')
              // Grab object values
              .map((key) => error.response.data.errors[key]);

            const errorStrings: string[] = []
              .concat(...errorValues)
              // Filter out empty strings
              .filter(Boolean);

            if (errorStrings.length) {
              errorMessage = errorStrings;
            }
          }

          if (!errorMessage.length && error && error.response && error.response.status === 413) {
            errorMessage = ['The file is too large.'];
          }

          if (!errorMessage.length && error && error.message) {
            errorMessage = [error.message];
          }

          if (!errorMessage.length) {
            errorMessage = [this.$t('File upload failed. Please try again.')];
          }

          this.fileErrors = errorMessage;
          this.isUploading = false;
        });
    },
    emitFile(changes = {}) {
      this.$emit('input', { ...this.file, ...changes });
    },
    updateTitle(title: string) {
      this.emitFile({ title });
    },
  },
});
