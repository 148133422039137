








import Vue from 'vue';
import mixins from 'vue-typed-mixins';

import Modal from './index';
import domUtils from '@/components/mixins/dom-utils';

export default mixins(domUtils).extend({
  data() {
    return {
      show: false,
    };
  },
  beforeMount() {
    Modal.event.$on('toggle', this.toggle);
  },
  beforeDestroy() {
    Modal.event.$off('toggle', this.handleToggleEvent);
  },
  methods: {
    toggle() {
      this.show = !this.show;
      if (this.show) {
        this.addBodyClass('u-overflow-hidden');
      } else {
        this.removeBodyClass('u-overflow-hidden');
        this.$emit('closed');
      }
    },
    keyupHandler(event) {
      if (event.keyCode === 27) {
        this.toggle();
      }
    },
  },
  created() {
    document.addEventListener('keyup', this.keyupHandler);
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyupHandler);
  },
});
