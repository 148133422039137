



































































































import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import { formatNumber } from '@/i18n';
import CountryName from '@/components/blocks/country-name.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import CheckboxControl from '@/components/controls/checkbox.vue';
import PrivacyRequestsFilterForm from '@/components/forms/privacy-requests-filter.vue';
import tableViewMixin from '@/components/mixins/table-view';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PrivacyRequest from '@/store/modules/privacy_requests/privacy_request';
import { PrivacyRequestActions, PrivacyRequestStatus } from '@/store/modules/privacy_requests/types';

export default mixins(tableViewMixin).extend({
  name: 'LocationsView',
  components: {
    NotificationBanner,
    PaginationControl,
    CheckboxControl,
    PrivacyRequestsFilterForm,
    TableListSkeleton,
    CountryName,
    Dropdown,
  },
  data() {
    return {
      sort: 'title',
      allSelected: false,
      selectedItems: [],
    };
  },
  computed: {
    ...mapState('privacy_requests', ['list', 'listPagination']),
    ...mapGetters('privacy_requests', ['isLoading', 'isLoaded', 'isEmpty']),
    totalItems(): string {
      if (this.isLoaded) {
        return formatNumber(this.listPagination.total);
      }

      return '0';
    },
    // areAllItemsSelected(): boolean {
    //   return this.selectedItems.length === this.list.length;
    // },
  },
  methods: {
    ...mapActions('privacy_requests', {
      closeAction: PrivacyRequestActions.DELETE,
    }),
    dropdownItems(privacyRequest: PrivacyRequest) {
      const items: DropdownItems = {
        view: {
          label: this.$t('View'),
          icon: 'preview',
        },
        download: {
          label: this.$t('Download'),
          icon: 'download',
        },
      };

      if (privacyRequest.status === PrivacyRequestStatus.Pending) {
        items.close = {
          label: this.$t('Close'),
          icon: 'check-mark',
        };
      }

      return items;
    },
    handleClick(privacyRequest: PrivacyRequest, action: string) {
      if ('view' === action) {
        this.$router.push({
          name: 'view-privacy-request',
          params: { id: String(privacyRequest.id) },
        });
      }

      if ('download' === action) {
        window.open(privacyRequest.downloadUrl, '_blank');
      }

      if ('close' === action) {
        const answer = window.confirm(this.$t('Do you really want to close this privacy request?'));

        if (answer) {
          this.closeAction({ id: privacyRequest.id });
        }
      }
    },
    // isSelected(privacyRequest: PrivacyRequest): boolean {
    //   return this.selectedItems.indexOf(privacyRequest.id) !== -1;
    // },
    // selectItem(privacyRequest: PrivacyRequest) {
    //   if (this.isSelected(privacyRequest)) {
    //     this.selectedItems = (this.selectedItems as number[]).filter((item) => item !== privacyRequest.id);
    //     return;
    //   }
    //   this.selectedItems.push(privacyRequest.id);
    // },
    // selectAll() {
    //   if (this.areAllItemsSelected) {
    //     this.selectedItems = [];
    //   } else {
    //     this.selectedItems = this.list.map((privacyRequest: PrivacyRequest) => privacyRequest.id);
    //   }
    // },
  },
});
