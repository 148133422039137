









































































import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';

import { PublishStatus } from '@/store/types';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Publish from '@/components/forms/publish.vue';
import ResourceEditForm from '@/components/forms/resource.vue';
import { ResourcesActions } from '@/store/modules/resources/types';
import ResourcePublishBoxForm from '@/components/forms/resource-publish-box-form.vue';
import ResourceCategoriesForm from '@/components/forms/resource-categories.vue';
import ResourcePersonalizationForm from '@/components/forms/resource-personalization.vue';
import SelectInput from '@/components/controls/select-input.vue';
import LanguagePicker from '@/components/controls/language-picker.vue';
import CountriesSelection from '@/components/controls/countries-selection.vue';

export default Vue.extend({
  name: 'EditResourceView',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    NotificationBanner,
    ResourceEditForm,
    Publish,
    ResourcePublishBoxForm,
    ResourceCategoriesForm,
    SelectInput,
    LanguagePicker,
    CountriesSelection,
    ResourcePersonalizationForm,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formTitle(): string {
      return this.add ? this.$t('Add Resource') : this.$t('Edit Resource');
    },
    ...mapState('resources', {
      editableStatus: 'editableStatus',
      resource: 'editable',
    }),
    ...mapGetters('resources', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
      isLoaded: 'editableIsLoaded',
      isSaved: 'editableIsSaved',
      hasUnsavedChanges: 'editableHasUnsavedChanges',
    }),
    isInactive() {
      return this.resource.status === PublishStatus.Deleted;
    },
  },
  methods: {
    ...mapActions('resources', {
      save: ResourcesActions.SAVE_EDITABLE,
      remove: ResourcesActions.DELETE_EDITABLE,
      restore: ResourcesActions.RESTORE_EDITABLE,
      update: ResourcesActions.UPDATE_EDITABLE,
    }),
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});
