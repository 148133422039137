















import Vue from 'vue';
import api from '@/api';
import AutocompleteControl, { AutocompleteOption, AutocompletePromise } from '@/components/controls/autocomplete.vue';

export default Vue.extend({
  name: 'ResourceSearchForm',
  components: {
    AutocompleteControl,
  },
  data() {
    return {
      value: {
        label: '',
        value: undefined,
      },
      cancelTokenSource: undefined,
    };
  },
  props: {
    hasIcon: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    autocompleteIcon(): string | void {
      return this.hasIcon ? 'search' : undefined;
    },
  },
  methods: {
    getSearchValue(option: AutocompleteOption): string {
      let search = '';

      if (option.value) {
        search = String(option.value);
      } else if (option.label) {
        search = option.label;
      }

      return search;
    },
    emitInput(option: AutocompleteOption): void {
      this.$emit('input', { search: this.getSearchValue(option) });
    },
    emitSearch(option: AutocompleteOption): void {
      this.$emit('search', { search: this.getSearchValue(option) });
    },
    doAutocomplete(search: string): AutocompletePromise {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = undefined;
      }

      if (!search) {
        return Promise.resolve({
          results: [],
        });
      }

      this.cancelTokenSource = api.getCancelTokenSource();

      return api.assets.search(search, { cancelToken: this.cancelTokenSource.token }).then((response) => {
        return {
          results: response.data.map((item) => {
            return {
              label: item.word,
              value: item.word,
              frequency: item.frequency,
            };
          }),
        };
      });
    },
    reset(): void {
      this.$refs.control.reset();
    },
  },
  created() {
    if (this.initialValue) {
      this.value = {
        label: this.initialValue,
        value: this.initialValue,
      };
    }
  },
});
