








































































































import { mapState, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import LocationBlock from '@/components/blocks/location.vue';
import CountryName from '@/components/blocks/country-name.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import CheckboxControl from '@/components/controls/checkbox.vue';
import LocationsFilterForm from '@/components/forms/locations-filter.vue';
import tableViewMixin from '@/components/mixins/table-view';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import GridListSkeleton from '@/components/skeletons/grid-list.vue';
import Location from '@/store/modules/locations/location';
import PublishStatusBlock from '@/components/blocks/publish-status.vue';

export default mixins(tableViewMixin).extend({
  name: 'LocationsListBlock',
  components: {
    LocationBlock,
    PaginationControl,
    CheckboxControl,
    LocationsFilterForm,
    TableListSkeleton,
    GridListSkeleton,
    CountryName,
    PublishStatusBlock,
  },
  data() {
    return {
      gridView: false,
      sort: 'title',
      allSelected: false,
      selectedItems: [],
    };
  },
  props: {
    view: {
      type: String,
      default: 'grid',
      validator: (value) => {
        return ['grid', 'table'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapState('locations', ['locationsList', 'pagination']),
    ...mapGetters('locations', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isEmpty: 'listIsEmpty',
    }),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    isGridView(): boolean {
      return this.view === 'grid';
    },
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.locationsList.length;
    },
  },
  methods: {
    isSelected(location: Location): boolean {
      return this.selectedItems.indexOf(location.id) !== -1;
    },
    selectItem(location: Location) {
      if (this.isSelected(location)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== location.id);
        return;
      }

      this.selectedItems.push(location.id);
    },
    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.locationsList.map((location: Location) => location.id);
      }
    },
  },
});
