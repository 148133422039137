var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"c-site-header"},[_c('div',{staticClass:"o-container-fluid"},[_c('router-link',{staticClass:"c-site-logo",attrs:{"to":{ name: 'dashboard' }}},[_c('h1',[_vm._v("AMPS2")])]),(_vm.isSuperAdmin || _vm.isAdmin)?_c('nav',{staticClass:"c-site-nav c-site-nav--admin"},[_c('ul',{staticClass:"c-main-menu"},[_c('router-link',{class:[
            'has-children',
            {
              'is-parent': _vm.isRoute(['practices', 'add-practice', 'edit-practice', 'view-practice']),
            } ],attrs:{"tag":"li","to":{ name: 'practices' }}},[_c('a',[_c('svgicon',{attrs:{"name":"home"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Practices')))])],1),_c('div',{staticClass:"c-menu-dropdown c-menu-dropdown--narrow"},[_c('div',{staticClass:"c-tile c-tile--item"},[_c('div',{staticClass:"c-card"},[_c('ul',{staticClass:"c-main-menu__small-list"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'add-practice' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add New Practice')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'practices' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('View All')))])])],1)])])])])]),_c('router-link',{class:['has-children', { 'is-parent': _vm.isRoute(['add-location', 'edit-location']) }],attrs:{"tag":"li","to":{ name: 'locations' }}},[_c('a',[_c('svgicon',{attrs:{"name":"location"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Locations')))])],1),_c('div',{staticClass:"c-menu-dropdown c-menu-dropdown--narrow"},[_c('div',{staticClass:"c-tile c-tile--item"},[_c('div',{staticClass:"c-card"},[_c('ul',{staticClass:"c-main-menu__small-list"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'add-location' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add New Location')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'locations' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('View All')))])])],1)])])])])]),_c('router-link',{class:[
            'has-children',
            {
              'is-parent': _vm.isRoute(['add-practitioner', 'edit-practitioner']),
            } ],attrs:{"tag":"li","to":{ name: 'practitioners' }}},[_c('a',[_c('svgicon',{attrs:{"name":"practitioner"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Practitioners')))])],1),_c('div',{staticClass:"c-menu-dropdown c-menu-dropdown--narrow"},[_c('div',{staticClass:"c-tile c-tile--item"},[_c('div',{staticClass:"c-card"},[_c('ul',{staticClass:"c-main-menu__small-list"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'add-practitioner' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add New Practitioner')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'practitioners' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('View All')))])])],1)])])])])]),(_vm.isSuperAdmin)?_c('router-link',{class:[
            'has-children',
            {
              'is-parent': _vm.isRoute(['users', 'add-user', 'edit-user']),
            } ],attrs:{"tag":"li","to":{ name: 'users' }}},[_c('a',[_c('svgicon',{attrs:{"name":"user-admin"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Users')))])],1),(_vm.isSuperAdmin)?_c('div',{staticClass:"c-menu-dropdown c-menu-dropdown--narrow"},[_c('div',{staticClass:"c-tile c-tile--item"},[_c('div',{staticClass:"c-card"},[_c('ul',{staticClass:"c-main-menu__small-list"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'add-user' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add New User')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'users' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('View All')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'privacy-requests' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Privacy Requests')))])])],1)])])])]):_vm._e()]):_vm._e()],1),_c('ul',{staticClass:"c-main-menu"},[(_vm.isSuperAdmin || _vm.isAdmin)?_c('router-link',{class:[
            'has-children',
            {
              'is-parent': _vm.isRoute([
                'resource',
                'add-resource',
                'edit-resource',
                'categories',
                'add-category',
                'edit-category',
                'add-resource/add-category',
                'edit-resource/add-category' ]),
            } ],attrs:{"tag":"li","to":{ name: 'resources', query: { language: _vm.currentUser.languageCode }}}},[_c('a',[_vm._v(_vm._s(_vm.$t('Resource Center')))]),_c('div',{staticClass:"c-menu-dropdown c-menu-dropdown--narrow"},[_c('div',{staticClass:"c-tile c-tile--item"},[_c('div',{staticClass:"c-card"},[_c('ul',{staticClass:"c-main-menu__small-list"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'add-resource' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add New Resource')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'resources', query: { language: _vm.currentUser.languageCode }}}},[_c('span',[_vm._v(_vm._s(_vm.$t('View All')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'categories' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Categories')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'resource-downloads' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Downloads')))])])],1)])])])])]):_vm._e(),_c('router-link',{class:[
            {
              'is-parent': _vm.isRoute(['blog', 'blog-category', 'blog-author', 'blog-post']),
            } ],attrs:{"tag":"li","to":{ name: 'blog' }}},[_c('a',[_vm._v(_vm._s(_vm.$t('AMPS Advisor Archives')))])])],1)]):_c('nav',{staticClass:"c-site-nav"},[_c('ul',{staticClass:"c-main-menu"},[(_vm.isUser)?_c('router-link',{class:[
            'has-children',
            {
              'is-parent': _vm.isRoute([
                'locations',
                'add-location',
                'edit-location',
                'practitioners',
                'add-practitioner',
                'edit-practitioner' ]),
            } ],attrs:{"tag":"li","to":{ name: 'practice' }}},[_c('a',{attrs:{"id":"intro-my-practice"}},[_c('span',[_vm._v(_vm._s(_vm.$t('My Practice')))])]),_c('div',{staticClass:"c-menu-dropdown"},[_c('div',{staticClass:"c-tile c-tile--item"},[_c('div',{staticClass:"c-card"},[_c('locations-menu-block'),_c('practitioners-menu-block'),_c('div',{staticClass:"c-main-menu__group c-tile__element--expand-has-padding"},[_c('ul',{staticClass:"c-main-menu__small-list"},[_c('li',{staticClass:"c-main-menu__more"},[_c('router-link',{attrs:{"to":{ name: 'consultations' }}},[_c('svgicon',{attrs:{"name":"pulse"}}),_vm._v(" "+_vm._s(_vm.$t('My Leads'))+" ")],1)],1)])])],1)])])]):_vm._e(),_c('router-link',{class:[
            {
              'has-children': _vm.isClinical,
              'is-parent': _vm.isRoute([
                'resource',
                'add-resource',
                'edit-resource',
                'categories',
                'add-category',
                'edit-category',
                'add-resource/add-category',
                'edit-resource/add-category' ]),
            } ],attrs:{"tag":"li","to":{ name: 'resources', query: { language: _vm.currentUser.languageCode }}}},[_c('a',{attrs:{"id":"intro-resource-center"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Resource Center')))])]),(_vm.isClinical)?_c('div',{staticClass:"c-menu-dropdown c-menu-dropdown--right c-menu-dropdown--narrow"},[_c('div',{staticClass:"c-tile c-tile--item"},[_c('div',{staticClass:"c-card"},[_c('ul',{staticClass:"c-main-menu__small-list"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'add-resource' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add New Resource')))])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'resources', query: { language: _vm.currentUser.languageCode }}}},[_c('span',[_vm._v(_vm._s(_vm.$t('View All')))])])],1)])])])]):_vm._e()]),_c('router-link',{attrs:{"tag":"li","to":{ name: 'support' }}},[_c('a',[_c('span',[_vm._v(_vm._s(_vm.$t('Contact Support')))])])]),_c('router-link',{class:[
            {
              'is-parent': _vm.isRoute(['blog', 'blog-category', 'blog-author', 'blog-post']),
            } ],attrs:{"tag":"li","to":{ name: 'blog' }}},[_c('a',[_vm._v(_vm._s(_vm.$t('AMPS Advisor Archives')))])]),_c('router-link',{staticClass:"has-children",attrs:{"tag":"li","to":{ name: 'edit-profile' }}},[_c('a',{attrs:{"id":"intro-my-profile"}},[_c('span',{staticClass:"c-user__avatar"},[_c('img',{attrs:{"src":_vm.sizedProfileImage,"alt":""}})]),_c('span',[_vm._v(_vm._s(_vm.displayName))])]),_c('div',{staticClass:"c-menu-dropdown c-menu-dropdown--right c-menu-dropdown--narrow"},[_c('div',{staticClass:"c-tile c-tile--item"},[_c('div',{staticClass:"c-card"},[_c('ul',{staticClass:"c-main-menu__small-list"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'edit-profile' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('My Profile')))])])],1),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('svgicon',{attrs:{"name":"out"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Logout')))])],1)])])])])])]),_c('li',{staticClass:"c-main-menu__item--splitter"},[(_vm.isInNorthAmerica)?_c('a',{directives:[{name:"track-outbound",rawName:"v-track-outbound"}],staticClass:"c-button c-button--primary-transparent c-main-menu__outlink",attrs:{"href":"https://store.cynosure.com?utm_source=amps&utm_medium=nav","target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Store')))])]):_vm._e()])],1)]),_c('a',{staticClass:"c-menu-toggle js-menu-toggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleMobileHandler.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('Menu')))]),_vm._m(0)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-menu-toggle__hamburger"},[_c('span'),_c('span'),_c('span')])}]

export { render, staticRenderFns }