




import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import Checkbox from '@/components/controls/checkbox.vue';
import { ProductsActions } from '@/store/modules/products/types';
import { ProductPriority } from '@/store/modules/products/product';

export default Vue.extend({
  name: 'ProductIsFeaturedForm',
  components: {
    Checkbox,
  },
  computed: {
    ...mapState('products', {
      product: 'editable',
    }),
    ...mapGetters('products', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    isFeatured(): boolean {
      return this.product && this.product.priority === ProductPriority.Featured;
    },
  },
  methods: {
    ...mapActions('products', {
      updateEditable: ProductsActions.UPDATE_EDITABLE,
    }),
    updateFeatured(val) {
      this.updateEditable({
        priority: val ? ProductPriority.Featured : ProductPriority.Normal,
      });
    },
  },
});
