



























































































































import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import { ProfileActions } from '@/store/modules/user/profile/types';
import { NotificationsActions } from '@/store/modules/notifications/types';
import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import SelectInput from '@/components/controls/select-input.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import LanguagePicker from '@/components/controls/language-picker.vue';

export default Vue.extend({
  name: 'EditProfileView',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    NotificationBanner,
    FormInput,
    ImageUploadControl,
    SubmitButton,
    SelectInput,
    LanguagePicker,
  },
  data() {
    return {
      passwordRepeat: '',
      passwordsResetCount: 0,
    };
  },
  computed: {
    ...mapState('user/profile', {
      editable: 'editableProfile',
      editableStatus: 'editableStatus',
    }),
    ...mapGetters('user/profile', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
      isSaved: 'editableIsSaved',
      isFailed: 'editableIsFailed',
      isFetching: 'editableIsFetching',
      hasUnsavedChanges: 'editableHasUnsavedChanges',
    }),
  },
  methods: {
    ...mapActions('user/profile', {
      saveProfile: ProfileActions.SAVE_PROFILE,
      update: ProfileActions.UPDATE_EDITABLE_PROFILE,
    }),
    ...mapActions('notifications', [NotificationsActions.SHOW_ERROR, NotificationsActions.HIDE_NOTIFICATION]),
    cleanPasswordInputs() {
      this.passwordRepeat = '';
      this.passwordsResetCount += 1;
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          this[NotificationsActions.HIDE_NOTIFICATION]();

          return this.saveProfile().then(() => this.cleanPasswordInputs());
        }

        this[NotificationsActions.SHOW_ERROR]({
          title: this.$t('Error'),
          body: this.$t('Please correct the errors below and try again:'),
          errorList: this.errors.all(),
        });
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});
