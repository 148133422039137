






















































import Vue from 'vue';
import config from '@/config';
import { getAccessToken } from '@/api/utils';

export default Vue.extend({
  name: 'ImageUploadControl',
  inject: {
    $validator: '$validator',
  },
  props: {
    value: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    placeholderIcon: {
      type: String,
      default: 'profile',
      validator: (val) => ['profile', 'images'].indexOf(val) > -1,
    },
    minWidth: {
      type: Number,
      default: 800,
      validator: (val) => val >= 1,
    },
    minHeight: {
      type: Number,
      default: 800,
      validator: (val) => val >= 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
    containPreviewImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadErrorMsg: false,
      isOver: false,
      isLoading: false,
      options: {
        acceptedFileTypes: ['image/*'],
        url: config.API_HOSTNAME + '/upload/image',
        maxFiles: 1,
        maxFileSize: 20,
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
        },
        clickable: false,
      },
    };
  },
  computed: {
    hasThumbnail() {
      return this.value && this.value.thumbnail && this.value.thumbnail !== '';
    },
    hasError(): boolean {
      if (this.uploadErrorMsg) {
        return true;
      }

      if (this.name && this.errors.first(this.name)) {
        return true;
      }

      return false;
    },
    errorMessages(): string[] {
      return [
        this.uploadErrorMsg,
        this.name && this.errors.first(this.name)
          ? this.errors.first(this.name).replace(this.name, this.label ? this.label : this.name)
          : undefined,
      ].filter(Boolean);
    },
  },
  methods: {
    processing() {
      this.isLoading = true;
      this.uploadErrorMsg = false;
    },
    triggerBrowse() {
      if (this.isLoading || this.loading) {
        return;
      }

      this.$refs.uploader.triggerBrowseFiles();
    },
    dragOverHandler(event) {
      this.isOver = !this.isOver;
    },
    remove() {
      this.$refs.uploader.removeAllFiles(true);
      this.$emit('input', null);
    },
    success(file, response, formData) {
      this.isLoading = false;
      this.$emit('input', response);
    },
    error(file, message: string, xhr) {
      let msg = message;

      if (file.size > this.options.maxFileSize * 1024 * 1024) {
        msg = this.$t('File exceeds the max size of %(maxSize)%MB.', { maxSize: this.options.maxFileSize });
      }

      this.isLoading = false;
      this.uploadErrorMsg = msg;
    },
  },
  filters: {
    json(value) {
      return JSON.stringify(value, null, 2);
    },
  },
});
