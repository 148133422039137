























































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';

import { isString } from '@/utils/is';

import CategoriesFilterForm from '@/components/forms/categories-filter.vue';
import ResourceSearchForm from '@/components/forms/resource-search.vue';
import TreatmentsFilterForm from '@/components/forms/treatments-filter.vue';
import CountrySelection from '@/components/controls/country-selection.vue';
import ResourcesLanguagePicker from '@/components/controls/resources-language-picker.vue';

export default Vue.extend({
  name: 'ResourcesFilterForm',
  props: {
    scrollTo: {
      type: String,
      default: undefined,
    },
  },
  components: {
    CategoriesFilterForm,
    ResourceSearchForm,
    TreatmentsFilterForm,
    CountrySelection,
    ResourcesLanguagePicker,
  },
  data() {
    return {
      internalFilters: {
        product: 0,
        status: '1',
        treatments: [],
        categories: [],
        search: '',
        country: 0,
        language: '',
      },
    };
  },
  computed: {
    ...mapState('treatments', ['treatmentsTree']),
    ...mapGetters('treatments', {
      treatmentsTreeIsLoaded: 'treatmentsTreeIsLoaded',
    }),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin', 'isClinical', 'isSales']),
    isAdministrator() {
      return this.isAdmin || this.isSuperAdmin;
    },
    isClinicalSales() {
      return this.isClinical || this.isSales;
    },
    filters: {
      get() {
        return this.internalFilters;
      },
      set(filters: any) {
        let {
          product = this.internalFilters.product,
          treatments = this.internalFilters.treatments,
          categories = this.internalFilters.categories,
        } = filters;

        const {
          search = this.internalFilters.search,
          status = this.internalFilters.status,
          country = this.internalFilters.country,
          language = this.internalFilters.language,
        } = filters;

        // If its a string, make it a number
        product = isString(product) ? Number(product) : product;
        // If its a single value, make it an array
        treatments = isString(treatments) ? [Number(treatments)] : treatments;
        categories = isString(categories) ? [Number(categories)] : categories;

        // Make sure these are arrays of numbers
        treatments = treatments.map((id) => Number(id));
        categories = categories.map((id) => Number(id));

        // Do checks only if treatment list is loaded
        if (this.treatmentsTreeIsLoaded) {
          // Make sure that product and treatments exists
          const productObj = this.treatmentsTree.find((item) => item.id === product);

          if (!productObj) {
            product = 0;
            treatments = [];
          } else {
            const productTreatments = productObj.treatments.map((item) => item.id);

            // Filter out treatments that don't belong to this product
            treatments = treatments.filter((itemId) => productTreatments.indexOf(itemId) > -1);
          }
        }

        this.internalFilters = {
          product,
          status,
          treatments,
          categories,
          search,
          country: Number(country),
          language,
        };
      },
    },
  },
  methods: {
    handleFilterUpdate(filters): void {
      this.filters = { ...this.filters, ...filters };

      this.applyFilters();
    },
    resetFilters(): void {
      this.handleFilterUpdate({
        product: 0,
        status: '1',
        treatments: [],
        categories: [],
        search: '',
        country: 0,
        language: '',
      });

      this.$refs.searchInput.reset();
    },
    applyFilters: debounce(function (): void {
      const { product, status, treatments, categories, search, country, language } = this.filters;

      const query: Record<string, string | string[] | undefined> = {
        ...omit(this.$route.query, ['product', 'status', 'treatments', 'categories', 'search', 'country', 'language']),
        product: product ? product : undefined,
        treatments,
        categories,
        search: search && search.length ? search : undefined,
      };

      if (language) {
        query.language = language;
      }

      if (this.isAdministrator) {
        if (status) {
          query.status = status;
        }

        if (country) {
          query.country = country;
        }
      }

      this.$router.push({
        name: 'resources',
        hash: this.scrollTo ? `#${this.scrollTo}` : undefined,
        query,
      });
    }, 1000),
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        const {
          product = 0,
          status = '1',
          treatments = [],
          categories = [],
          search = '',
          country = 0,
          language = '',
        } = this.$route.query;

        this.filters = { product, status, treatments, categories, search, country, language };
      },
    },
  },
});
