


























































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import Category from '@/store/modules/categories/category';
import Pagination from '@/store/models/pagination';
import { CategoryActions } from '@/store/modules/categories/types';

import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import { formatNumber } from '@/i18n';
import i18n from '@/components/i18n';

export default mixins(tableViewMixin).extend({
  name: 'CategoriesView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    i18n,
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('categories', {
      categories: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('categories', ['isLoading', 'isLoaded', 'isEmpty']),
  },
  methods: {
    formatNumber,
    ...mapActions('categories', {
      deleteAction: CategoryActions.DELETE,
      restoreAction: CategoryActions.RESTORE,
    }),
    handleClick(category: Category, action: string) {
      if ('resources' === action) {
        this.$router.push({
          name: 'resources',
          query: { categories: String(category.id) },
        });
      } else if ('edit' === action) {
        this.$router.push({
          name: 'edit-category',
          params: { id: String(category.id) },
        });
      } else if ('remove' === action) {
        const answer = window.confirm(this.$t('Do you really want to delete this category?'));

        if (answer) {
          this.deleteAction({ id: category.id });
        }
      } else if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this category?'));

        if (answer) {
          this.restoreAction({ id: category.id });
        }
      }
    },
  },
});
