






















































import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';

import FloatingLabel from '@/components/controls/floating-label.vue';
import CustomInputComponent from '@/components/mixins/custom-input-component';

// TODO: make label required.

export default mixins(CustomInputComponent).extend({
  name: 'SelectInputControl',
  components: {
    FloatingLabel,
  },
  props: {
    iconName: {
      type: String,
      required: false,
    },
    extraClasses: {
      type: Array,
      default: () => [],
    } as PropOptions<string[]>,
    errorLabel: {
      type: String,
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    classes() {
      return [
        'c-form-element',
        { 'c-icon-wrapper--has-splitter': this.hasIcon },
        { 'c-form-element__error': this.showFieldError },
      ].concat(this.extraClasses);
    },
    hasIcon(): boolean {
      return this.iconName ? true : false;
    },
    fieldError() {
      return this.errors.items.find((item) => item.field === this.name);
    },
    fieldErrorRule(): string {
      if (!this.fieldError || !this.fieldError.rule) {
        return '';
      } else {
        return this.fieldError.rule;
      }
    },
    fieldErrorMsg(): string {
      if (!this.fieldError || !this.fieldError.msg) {
        return '';
      } else {
        if (this.errorLabel) {
          return this.fieldError.msg.replace(this.name, this.errorLabel);
        } else if (this.label) {
          return this.fieldError.msg.replace(this.name, this.label);
        } else {
          return this.fieldError.msg;
        }
      }
    },
    showFieldError(): boolean {
      return Boolean(this.fieldError);
    },
    isEmpty() {
      return !this.value || !this.value.length;
    },
  },
});
