

























import Vue from 'vue';
import { mapState } from 'vuex';

import i18n from '@/i18n';

import { ListStatus } from '@/store/types';
import CountryState from '@/store/models/country-state';
import Country from '@/store/models/country';

import SelectInput from '@/components/controls/select-input.vue';

export default Vue.extend({
  name: 'ProvinceSelectionControl',
  inheritAttrs: false,
  inject: ['$validator'],
  components: {
    SelectInput,
  },
  props: {
    country: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: 'state',
    },
    label: {
      type: String,
      default: i18n.t('State'),
    },
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['countries', 'countriesStatus']),
    areCountriesLoaded() {
      return this.countriesStatus === ListStatus.Loaded;
    },
    states(): CountryState[] {
      if (!this.country) {
        return [];
      }

      const country: Country | undefined = this.countries.find(({ id }) => id === this.country);

      if (!country || !country.states) {
        return [];
      }

      return country.states;
    },
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event.target.value);
    },
  },
});
