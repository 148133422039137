












































import Vue from 'vue';
import { mapState } from 'vuex';
import BlogList from '@/components/blocks/blog-list.vue';

export default Vue.extend({
  name: 'BlogView',
  components: {
    BlogList,
  },
  data() {
    return {
      categoriesSelectIsOpen: false,
    };
  },
  computed: {
    ...mapState('blog', {
      categories: 'categories',
    }),
    activeCategoryName() {
      if (this.$route.name === 'blog-category') {
        const { category: categorySlug } = this.$route.params;

        const category = this.categories.find((item) => item.slug === categorySlug);

        if (category) {
          return category.name;
        }
      }

      return this.$t('All Categories');
    },
  },
  methods: {
    toggleCategoriesSelect() {
      if (this.categoriesSelectIsOpen) {
        this.categoriesSelectIsOpen = false;
      } else {
        this.categoriesSelectIsOpen = true;
      }
    },
    hideCategoriesSelect() {
      this.categoriesSelectIsOpen = false;
    },
  },
});
