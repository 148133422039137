var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"c-main"},[_c('div',{staticClass:"o-container-fluid"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Dashboard')))]),_c('hr',{staticClass:"c-divider"}),_c('notification-banner'),_c('div',{staticClass:"o-row"},[_c('div',{staticClass:"o-col-7@md"},[_c('div',{staticClass:"c-tile"},[_c('div',{staticClass:"c-tile__title"},[_c('router-link',{attrs:{"to":{
                  name: 'locations',
                  query: { status: _vm.PublishStatus.Pending },
                }}},[_c('svgicon',{attrs:{"name":"location"}}),_c('h6',[_vm._v(_vm._s(_vm.$t('Locations')))])],1)],1),_c('hr',{staticClass:"c-divider--full-width"}),(_vm.locationsIsLoading)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Loading...')))])]):(_vm.locationsIsError)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Could not load locations.')))])]):(_vm.locationsIsLoaded && _vm.locationsTotalItems === 0)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('No pending locations.')))])]):(_vm.locationsIsLoaded)?_c('ul',{staticClass:"c-tile__list"},[_vm._l((_vm.locations),function(location){return _c('li',{key:location.id,staticClass:"c-location o-row"},[_c('div',{staticClass:"o-col-4"},[_c('router-link',{attrs:{"to":{
                      name: 'edit-location',
                      params: { id: location.id },
                    }}},[_c('div',{staticClass:"c-location__image-wrapper"},[(location.mapUrl)?_c('img',{attrs:{"src":location.mapUrl + '?fit=crop&crop=focalpoint&w=300&h=200&fp-x=0.5&fp-y=0.5&fp-z=2'}}):_c('img',{attrs:{"src":require("@/assets/images/location-empty.png"),"alt":"No Location"}}),_c('ul',{staticClass:"c-user__avatar-list"},[_vm._l((location.practitioners.slice(0, 3)),function(practitioner){return _c('li',{key:practitioner.id,staticClass:"c-user__avatar"},[_c('figure',{staticClass:"c-user__avatar"},[_c('img',{attrs:{"src":practitioner.avatar,"title":practitioner.title}})])])}),(location.practitioners && location.practitioners.length > 3)?_c('li',{key:"additional",staticClass:"c-user__avatar c-user__avatar-number"},[_vm._v(" +"+_vm._s(location.practitioners.length - 3)+" ")]):_vm._e()],2)])])],1),_c('div',{staticClass:"o-col-8"},[_c('h5',[_c('router-link',{attrs:{"to":{
                        name: 'edit-location',
                        params: { id: location.id },
                      }}},[_c('a',[_vm._v(_vm._s(location.title))])])],1),_c('div',[_c('small',{staticClass:"u-inline-block"},[_vm._v(_vm._s(location.humanReadableAddress))]),_vm._v(" "+_vm._s(' ')+" "),_c('publish-status-block',{staticClass:"u-inline-block",attrs:{"tag":"small","status":location.status,"practice":location.practice}})],1),_c('router-link',{attrs:{"to":{
                      name: 'locations-quick-review',
                      params: { id: location.id },
                    }}},[_vm._v(_vm._s(_vm.$t('Quick Review')))])],1)])}),(_vm.locationsTotalItems > _vm.locations.length)?_c('li',[_c('small',[_c('router-link',{attrs:{"to":{
                      name: 'locations',
                      query: { status: _vm.PublishStatus.Pending },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('View All Pending Locations'))+" ")])],1)]):_vm._e()],2):_vm._e()])]),_c('div',{staticClass:"o-col-5@md"},[(_vm.isSuperAdmin)?_c('div',{staticClass:"c-tile"},[_c('div',{staticClass:"c-tile__title"},[_c('router-link',{attrs:{"to":{ name: 'resource-downloads' }}},[_c('svgicon',{attrs:{"name":"pulse"}}),_c('h6',[_vm._v(_vm._s(_vm.$t('Downloads')))])],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.downloadsInterval),expression:"downloadsInterval"}],staticClass:"c-select-small",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.downloadsInterval=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"7"}},[_vm._v(_vm._s(_vm.$t('Last 7 Days')))]),_c('option',{attrs:{"value":"30"}},[_vm._v(_vm._s(_vm.$t('Last 30 Days')))])])],1),_c('hr',{staticClass:"c-divider--full-width"}),(_vm.downloadsIsLoading)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Loading...')))])]):(_vm.downloadsIsError)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Could not load downloads.')))])]):(_vm.downloadsIsEmpty)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('No downloads for selected period.')))])]):_c('div',[_c('ul',{staticClass:"c-downloads-list"},_vm._l((_vm.downloads),function(download){return _c('li',{key:download.id},[(download.thumbnail)?_c('img',{attrs:{"src":download.thumbnail + '?w=480&h=660&fit=crop&crop=focalpoint&fp-x=0&fp-y=0'}}):_vm._e(),_c('div',[_c('router-link',{attrs:{"to":{
                        name: 'resource',
                        params: {
                          slug: _vm.createSlug(download.assetTitle),
                          id: download.assetId,
                        },
                      }}},[_c('p',[_vm._v(_vm._s(download.title))])]),_c('small',[_vm._v(_vm._s(download.assetTitle))]),_c('span',{staticClass:"c-progress"},[_c('span',{style:({
                          width: (download.downloads / _vm.totalDownloads) * 100 + '%',
                        })})]),_c('p',{staticClass:"c-downloads-list__count"},[_vm._v(" "+_vm._s(_vm.formatNumber(download.downloads))+" ")])],1)])}),0),(_vm.downloadsPagination.total > _vm.downloads.length)?_c('div',{staticClass:"c-downloads-list--sticky-bottom"},[_c('router-link',{attrs:{"to":{
                    name: 'resource-downloads',
                    query: { days: _vm.downloadsInterval },
                  }}},[_vm._v(_vm._s(_vm.$t('View All Downloads')))])],1):_vm._e()])]):_vm._e(),(_vm.isSuperAdmin)?_c('div',{staticClass:"c-tile"},[_c('div',{staticClass:"c-tile__title"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('svgicon',{attrs:{"name":"pulse"}}),_c('h6',[_vm._v(_vm._s(_vm.$t('Completions')))])],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.trainingsInterval),expression:"trainingsInterval"}],staticClass:"c-select-small",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.trainingsInterval=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"7"}},[_vm._v(_vm._s(_vm.$t('Last 7 Days')))]),_c('option',{attrs:{"value":"30"}},[_vm._v(_vm._s(_vm.$t('Last 30 Days')))])])]),_c('hr',{staticClass:"c-divider--full-width"}),(_vm.trainingsIsLoading)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Loading...')))])]):(_vm.trainingsIsError)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(" "+_vm._s(_vm.$t('Could not load training completions.'))+" ")])]):(_vm.trainingsIsEmpty)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(" "+_vm._s(_vm.$t('No training completions for selected period.'))+" ")])]):_c('div',[_c('ul',{staticClass:"c-downloads-list"},_vm._l((_vm.trainings),function(training){return _c('li',{key:training.id},[(training.thumbnail)?_c('img',{attrs:{"src":training.thumbnail + '?w=480&h=660&fit=crop&crop=focalpoint&fp-x=0&fp-y=0'}}):_vm._e(),_c('div',[_c('router-link',{attrs:{"to":{
                        name: 'resource',
                        params: {
                          slug: _vm.createSlug(training.assetTitle),
                          id: training.assetId,
                        },
                      }}},[_c('p',[_vm._v(_vm._s(training.title))])]),_c('small',[_vm._v(_vm._s(training.assetTitle))]),_c('span',{staticClass:"c-progress"},[_c('span',{style:({
                          width: (training.trainings / _vm.totalTrainings) * 100 + '%',
                        })})]),_c('p',{staticClass:"c-downloads-list__count"},[_vm._v(" "+_vm._s(_vm.formatNumber(training.trainings))+" ")])],1)])}),0)])]):_vm._e(),_c('div',{staticClass:"c-tile"},[_c('div',{staticClass:"c-tile__title"},[_c('router-link',{attrs:{"to":{ name: 'users' }}},[_c('svgicon',{attrs:{"name":"user-admin"}}),_c('h6',[_vm._v(_vm._s(_vm.$t('Users')))])],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.usersWidgetShows),expression:"usersWidgetShows"}],staticClass:"c-select-small",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.usersWidgetShows=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"users"}},[_vm._v(_vm._s(_vm.$t('New Users')))]),_c('option',{attrs:{"value":"logins"}},[_vm._v(_vm._s(_vm.$t('Most Logins')))])])],1),_c('hr',{staticClass:"c-divider--full-width"}),(_vm.usersWidgetShows === 'users')?_c('div',[(_vm.usersIsLoading)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Loading...')))])]):(_vm.usersIsError)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Could not load users.')))])]):(_vm.usersIsEmpty)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('No users added.')))])]):_c('div',{staticClass:"c-tile__table"},[_c('table',{staticClass:"c-table c-table--small"},[_c('thead',[_c('th',[_vm._v(_vm._s(_vm.$t('Name')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Practice')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Date Added')))])]),_c('tbody',_vm._l((_vm.users),function(user){return _c('tr',{key:user.id},[_c('td',[_c('router-link',{attrs:{"to":{ name: 'edit-user', params: { id: user.id } }}},[_vm._v(_vm._s(user.fullName))])],1),(user.practice && user.practice.id)?_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'view-practice',
                            params: { id: user.practice.id },
                          }}},[_vm._v(_vm._s(user.practiceName))])],1):_c('td',[_vm._v("—")]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(user.createdAt)))])])}),0)]),(_vm.usersPagination.total > _vm.users.length)?_c('div',{staticClass:"c-downloads-list--sticky-bottom"},[_c('router-link',{attrs:{"to":{ name: 'users' }}},[_vm._v(_vm._s(_vm.$t('View All Users')))])],1):_vm._e()])]):_c('div',[(_vm.loginsIsLoading)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Loading...')))])]):(_vm.loginsIsError)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('Could not load users.')))])]):(_vm.loginsIsEmpty)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('No data.')))])]):_c('div',{staticClass:"c-tile__table"},[_c('table',{staticClass:"c-table c-table--small"},[_c('thead',[_c('th',[_vm._v(_vm._s(_vm.$t('Name')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Practice')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Logins')))])]),_c('tbody',_vm._l((_vm.logins),function(login){return _c('tr',{key:login.id},[_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'edit-user',
                            params: { id: login.id },
                          }}},[_vm._v(_vm._s(login.fullName))])],1),(login.practiceId)?_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'view-practice',
                            params: { id: login.practiceId },
                          }}},[_vm._v(_vm._s(login.practiceTitle))])],1):_c('td',[_vm._v("—")]),_c('td',[_vm._v(_vm._s(_vm.formatNumber(login.logins)))])])}),0)]),(_vm.usersPagination.total > _vm.users.length)?_c('div',{staticClass:"c-downloads-list--sticky-bottom"},[_c('router-link',{attrs:{"to":{ name: 'users' }}},[_vm._v(_vm._s(_vm.$t('View All Users')))])],1):_vm._e()])])])])])],1)]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }