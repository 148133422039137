
































































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import { formatNumber } from '@/i18n';

import tableViewMixin from '@/components/mixins/table-view';
import CheckboxControl from '@/components/controls/checkbox.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import TreatmentsListFilter from '@/components/forms/treatments-list-filter.vue';
import { ProductsActions } from '@/store/modules/products/types';
import Treatment, { TreatmentStatus } from '@/store/modules/treatments/treatment';
import { TreatmentActions } from '@/store/modules/treatments/types';

export default mixins(tableViewMixin).extend({
  name: 'TreatmentsView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    TreatmentsListFilter,
    CheckboxControl,
  },
  data() {
    return {
      selectedItems: [] as number[],
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('treatments', {
      treatments: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('treatments', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isEmpty: 'listIsEmpty',
    }),
    TreatmentStatus() {
      return TreatmentStatus;
    },
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.treatments.length;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('treatments', {
      restoreAction: TreatmentActions.RESTORE,
      removeAction: TreatmentActions.DELETE,
    }),
    dropdownItems(treatment: Treatment) {
      const items: DropdownItems = {
        edit: {
          label: this.$t('Edit'),
          icon: 'pencil',
        },
      };

      if (treatment.status === TreatmentStatus.Inactive) {
        items.restore = {
          label: this.$t('Restore'),
          icon: 'restore',
        };
      } else {
        items.remove = {
          label: this.$t('Delete'),
          hasTopBorder: true,
          icon: 'trash',
        };
      }

      return items;
    },

    handleClick(treatment: Treatment, action: string) {
      if ('edit' === action) {
        this.$router.push({
          name: 'edit-treatment',
          params: { id: String(treatment.id) },
        });
      }

      if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this treatment?'));

        if (answer) {
          this.restoreAction({ id: treatment.id });
        }
      }

      if ('remove' === action) {
        const answer = window.confirm(this.$t('Do you really want to delete this treatment?'));

        if (answer) {
          this.removeAction({ id: treatment.id });
        }
      }
    },

    isSelected(treatment: Treatment): boolean {
      return this.selectedItems.indexOf(treatment.id) !== -1;
    },

    selectItem(treatment: Treatment) {
      if (this.isSelected(treatment)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== treatment.id);
        return;
      }

      this.selectedItems.push(treatment.id);
    },

    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.treatments.map((treatment: Treatment) => treatment.id);
      }
    },
  },
});
