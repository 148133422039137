


































import Vue from 'vue';

import api from '@/api';

import { ListStatus } from '@/store/types';
import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import PublicSite from '@/store/modules/treatments/public-site';

import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';

export default Vue.extend({
  inject: {
    $validator: '$validator',
  },
  name: 'PublicSiteSelection',
  components: {
    Autocomplete,
  },
  $_veeValidate: {
    name() {
      return this.name || 'sites';
    },
    value() {
      return this.value;
    },
  },
  props: {
    name: {
      type: String,
      default: 'sites',
    },
    value: {
      type: Array as () => Taxonomy[],
      default: () => [] as Taxonomy[],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchInput: '',
      publicSites: [] as PublicSite[],
      listStatus: ListStatus.Loading,
    };
  },
  computed: {
    publicSitesOptions(): AutocompleteOption[] {
      const selectedIds = (this.value as Taxonomy[]).map(({ id }) => id);

      return (this.publicSites as PublicSite[])
        .filter((publicSite) => {
          return selectedIds.indexOf(publicSite.id) === -1;
        })
        .map((publicSite) => ({
          label: publicSite.title,
          value: publicSite.id,
        }))
        .filter((option) => option.label.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
    },
    arePublicSitesLoaded(): boolean {
      return this.listStatus === ListStatus.Loaded;
    },
    hasError() {
      return this.errors.has(this.name);
    },
    fieldError() {
      return this.errors.items.find((item) => item.field === this.name);
    },
    fieldErrorRule(): string {
      if (!this.fieldError || !this.fieldError.rule) {
        return '';
      } else {
        return this.fieldError.rule;
      }
    },
    fieldErrorMsg(): string {
      if (!this.fieldError || !this.fieldError.msg) {
        return '';
      } else {
        if (this.label) {
          return this.fieldError.msg.replace(this.name, this.label);
        }

        return this.fieldError.msg;
      }
    },
    showFieldError(): boolean {
      return Boolean(this.fieldErrorMsg.length);
    },
  },
  methods: {
    loadPublicSites() {
      this.listStatus = ListStatus.Loading;

      api.public_sites
        .list()
        .then((res) => {
          this.publicSites = res.data.data.map((item) => PublicSite.fromJSON(item));
          this.listStatus = ListStatus.Loaded;
        })
        .catch((err) => {
          this.listStatus = ListStatus.Failed;
        });
    },
    add(option: AutocompleteOption) {
      const item = (this.publicSites as PublicSite[]).find(({ id }) => id === option.value);

      if (item) {
        this.$emit('input', [
          ...this.value,
          Taxonomy.fromJSON({
            id: item.id,
            title: item.title,
          } as TaxonomyJSON),
        ]);
      }
    },
    remove(siteId: number) {
      this.$emit(
        'input',
        [...this.value].filter(({ id }) => id !== siteId),
      );
    },
  },
  created() {
    this.loadPublicSites();
  },
});
