










































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'LocationsMenuBlock',
  computed: {
    hasMoreMenuLocations() {
      return this.menuListTotalItems > 2;
    },
    numberOfLocations() {
      return this.isLoaded && this.menuListTotalItems > 0 ? '(' + this.menuListTotalItems + ')' : '';
    },
    ...mapState('locations', ['menuLocationsList', 'menuListTotalItems']),
    ...mapGetters('locations', {
      isLoading: 'menuListIsLoading',
      isLoaded: 'menuListIsLoaded',
    }),
  },
});
