














import Vue, { PropOptions } from 'vue';
import BlogPost from '@/store/modules/blog/post';

export default Vue.extend({
  name: 'BlogPostMetaBlock',
  props: {
    card: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      required: true,
    } as PropOptions<BlogPost>,
  },
});
