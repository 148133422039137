















import Vue, { PropOptions } from 'vue';
import { mapState, mapGetters } from 'vuex';

import Taxonomy from '@/store/models/taxonomy';

import MultiSelectInput, { MultiSelectValueItem } from '@/components/controls/multiselect-input.vue';

export default Vue.extend({
  name: 'TreatmentsSelection',
  inject: {
    $validator: '$validator',
  },
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },
  components: {
    MultiSelectInput,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    } as PropOptions<MultiSelectValueItem[]>,
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    ...mapState('treatments', {
      treatments: 'allTreatments',
    }),
    ...mapGetters('treatments', {
      isLoaded: 'allTreatmentsIsLoaded',
    }),
    options(): MultiSelectValueItem[] {
      const ids = (this.value as MultiSelectValueItem[]).map(({ id }) => id);

      return (this.treatments as Taxonomy[])
        .filter(({ id }) => ids.indexOf(id) === -1)
        .filter(({ title }) => title.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
    },
  },
});
