import { GetterTree } from 'vuex';
import { RootState, ListStatus, PublishStatus, EditableStatus } from '@/store/types';
import { PractitionersState } from '@/store/modules/practitioners/types';
import { PracticeStatus } from '@/store/modules/practices/types';
import { createEditableGetters } from '@/store/utils/editable';
import i18next from '@/i18n';

const { hasUnsavedChanges: editableHasUnsavedChanges } = createEditableGetters<PractitionersState>(
  'editableStatus',
  'editablePractitioner',
  'initialEditable',
);

export const moduleGetters: GetterTree<PractitionersState, RootState> = {
  /**
   * List
   */
  listIsLoading: (state) => (state.listStatus === ListStatus.Loading ? true : false),
  listIsLoaded: (state) => (state.listStatus === ListStatus.Loaded ? true : false),
  listIsError: (state) => (state.listStatus === ListStatus.Failed ? true : false),
  listIsEmpty: (state) =>
    !(state.practitionersList && state.practitionersList.length > 0 && state.listStatus !== ListStatus.Loading)
      ? true
      : false,

  /**
   * Menu List
   */
  menuListIsLoading: (state) => (state.menuListStatus === ListStatus.Loading ? true : false),
  menuListIsLoaded: (state) => (state.menuListStatus === ListStatus.Loaded ? true : false),
  menuListIsError: (state) => (state.menuListStatus === ListStatus.Failed ? true : false),
  menuListIsEmpty: (state) =>
    !(
      state.menuPractitionersList &&
      state.menuPractitionersList.length > 0 &&
      state.menuListStatus !== ListStatus.Loading
    )
      ? true
      : false,

  /**
   * Practitioners Hours List
   */
  practitionersHoursListIsInitial: (state) => state.practitionersHoursListStatus === ListStatus.Initial,
  practitionersHoursListIsLoading: (state) => state.practitionersHoursListStatus === ListStatus.Loading,
  practitionersHoursListIsLoaded: (state) => state.practitionersHoursListStatus === ListStatus.Loaded,
  practitionersHoursListIsFailed: (state) => state.practitionersHoursListStatus === ListStatus.Failed,
  practitionersHoursListIsEmpty: (state, getters) =>
    getters.practitionersHoursListIsLoaded && !state.practitionersHoursList.length,

  /**
   * Edit
   */
  editableIsLoading: (state) => (state.editableStatus === EditableStatus.Loading ? true : false),
  editableIsLoaded: (state) => (state.editableStatus === EditableStatus.Loaded ? true : false),
  editableIsSaving: (state) => (state.editableStatus === EditableStatus.Saving ? true : false),
  editableIsSaved: (state) => (state.editableStatus === EditableStatus.Saved ? true : false),
  editableIsError: (state) => (state.editableStatus === EditableStatus.Failed ? true : false),
  editableIsSavingDraft: (state) =>
    state.editableStatus === EditableStatus.Saving &&
    state.editablePractitioner &&
    state.editablePractitioner.status === PublishStatus.Draft
      ? true
      : false,
  editableIsSavedDraft: (state) =>
    state.editableStatus === EditableStatus.Saved &&
    state.editablePractitioner &&
    state.editablePractitioner.status === PublishStatus.Draft
      ? true
      : false,
  editableIsUpdating: (state) =>
    state.editableStatus === EditableStatus.Saving &&
    state.editablePractitioner &&
    state.editablePractitioner.status === PublishStatus.Pending
      ? true
      : false,
  editableIsUpdated: (state) =>
    state.editableStatus === EditableStatus.Saved &&
    state.editablePractitioner &&
    state.editablePractitioner.status === PublishStatus.Pending
      ? true
      : false,
  editableIsPublishing: (state) =>
    state.editableStatus === EditableStatus.Saving &&
    state.editablePractitioner &&
    state.editablePractitioner.status === PublishStatus.Published
      ? true
      : false,
  editableIsPublished: (state) =>
    state.editableStatus === EditableStatus.Saved &&
    state.editablePractitioner &&
    state.editablePractitioner.status === PublishStatus.Published
      ? true
      : false,
  editableHasUnsavedChanges,
  editablePracticeId: (state) =>
    state.editablePractitioner && state.editablePractitioner.practiceId ? state.editablePractitioner.practiceId : 0,
  hasPracticeAssigned: (state, getters) => (getters.editablePracticeId !== 0 ? true : false),
  editableStatusLabel: (state) => {
    let label = '';
    switch (state.editablePractitioner && state.editablePractitioner.status) {
      case PublishStatus.Published:
        label = i18next.t('Published');
        break;
      case PublishStatus.Pending:
        label = i18next.t('Review Pending');
        break;
      case PublishStatus.Deleted:
        label = i18next.t('Deleted');

        if (
          state.editablePractitioner &&
          state.editablePractitioner.practice &&
          state.editablePractitioner &&
          state.editablePractitioner.practice.status === PracticeStatus.Inactive
        ) {
          label = i18next.t('Practice Deleted');
        }

        break;
      case PublishStatus.Draft:
      default:
        label = i18next.t('Draft');
        break;
    }
    return label;
  },
};

export default moduleGetters;
