
















































































import Vue from 'vue';
import FormInput from '@/components/controls/form-input.vue';
import { mapState, mapActions } from 'vuex';
import { LocationsActions } from '@/store/modules/locations/types';

export default Vue.extend({
  name: 'LocationSocialLinksForm',
  components: {
    FormInput,
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    ...mapState('locations', {
      location: 'editable',
    }),
    showAllLabel() {
      return '+ ' + (!this.showAll ? this.$t('Show All') : this.$t('Show Less'));
    },
  },
  methods: {
    toggleAll() {
      this.showAll = !this.showAll;
    },
    ...mapActions('locations', {
      updateLocation: LocationsActions.UPDATE_EDITABLE,
    }),
  },
});
