




















import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { SettingsActions } from '@/store/modules/settings/types';

export default Vue.extend({
  name: 'TopBannerLayout',
  computed: {
    ...mapGetters('settings', ['topBanner', 'showTopBanner', 'settingsIsLoaded']),
    target() {
      if (this.topBanner.external) {
        return '_blank';
      } else {
        return '_self';
      }
    },
  },
  methods: {
    ...mapActions('settings', {
      closeBanner: SettingsActions.CLOSE_TOP_BANNER,
    }),
  },
});
