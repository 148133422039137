









































import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

import { ListStatus, RootActions } from '@/store/types';
import Country from '@/store/models/country';

import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';
import Checkbox from '@/components/controls/checkbox.vue';

export default Vue.extend({
  inject: {
    $validator: '$validator',
  },
  name: 'CountriesSelection',
  components: {
    Checkbox,
    Autocomplete,
  },
  $_veeValidate: {
    name() {
      return this.name || 'countries';
    },
    value() {
      if (this.isAllCountriesSelected && this.hasAllCountriesCheckbox) {
        return this.countries;
      }

      return this.value;
    },
  },
  props: {
    name: {
      type: String,
      default: 'countries',
    },
    value: {
      type: Array as () => Country[],
      default: () => [] as Country[],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasAllCountriesCheckbox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      countryInput: '',
      isAllCountriesSelected: false,
      localCopyOfCountries: [],
    };
  },
  computed: {
    ...mapState(['countries', 'countriesStatus']),
    countriesOptions(): AutocompleteOption[] {
      const countriesIds = this.value.map(({ id }) => id);

      return this.countries
        .filter((country) => {
          return countriesIds.indexOf(country.id) === -1;
        })
        .map((country) => ({
          label: country.name,
          value: country.id,
        }))
        .filter((option) => option.label.toLowerCase().indexOf(this.countryInput.toLowerCase()) > -1);
    },
    areCountriesLoaded(): boolean {
      return this.countriesStatus === ListStatus.Loaded;
    },
    hasError() {
      return this.errors.has(this.name);
    },
    fieldError() {
      return this.errors.items.find((item) => item.field === this.name);
    },
    fieldErrorRule(): string {
      if (!this.fieldError || !this.fieldError.rule) {
        return '';
      } else {
        return this.fieldError.rule;
      }
    },
    fieldErrorMsg(): string {
      if (!this.fieldError || !this.fieldError.msg) {
        return '';
      } else {
        if (this.label) {
          return this.fieldError.msg.replace(this.name, this.label);
        }

        return this.fieldError.msg;
      }
    },
    showFieldError(): boolean {
      return Boolean(this.fieldErrorMsg.length);
    },
  },
  methods: {
    ...mapActions({
      loadCountries: RootActions.LOAD_COUNTRIES,
    }),
    addCountry(option: AutocompleteOption) {
      const country = this.countries.find(({ id }) => id === option.value);

      if (country) {
        this.$emit('input', [
          ...this.value,
          Country.fromJSON({
            id: country.id,
            code: country.code,
            name: country.name,
          }),
        ]);
      }
    },
    removeCountry(countryId: string | number) {
      this.$emit(
        'input',
        [...this.value].filter(({ id }) => id !== countryId),
      );
    },
    updateIsAllCountriesSelected(value: boolean) {
      if (!this.hasAllCountriesCheckbox) {
        return;
      }

      if (value) {
        this.isAllCountriesSelected = true;
        this.localCopyOfCountries = [...this.value];
        this.$emit('input', []);
      } else {
        if (this.localCopyOfCountries.length) {
          this.$emit('input', [...this.localCopyOfCountries]);
        }

        this.isAllCountriesSelected = false;
      }
    },
  },
  watch: {
    disabled: {
      handler(value) {
        if (!value) {
          // Reset the local copy of countries
          this.localCopyOfCountries = [];

          if (!this.value.length && this.hasAllCountriesCheckbox) {
            this.isAllCountriesSelected = true;
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadCountries();
  },
});
