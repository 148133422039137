




















import Vue from 'vue';

import i18n from '@/i18n';
import api from '@/api';
import { RootActions } from '@/store/types';
import AutocompleteControl, { AutocompletePromise, AutocompleteOption } from '@/components/controls/autocomplete.vue';

export default Vue.extend({
  name: 'ProductSelectionControl',
  inject: {
    $validator: '$validator',
  },
  components: {
    AutocompleteControl,
  },
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: i18n.t('Product'),
    },
    label: {
      type: String,
      default: i18n.t('Product'),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'product',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: undefined,
      internalLabel: '',
      cancelTokenSource: undefined,
      isLoadingProduct: false,
    };
  },
  computed: {
    autocompleteValue() {
      return {
        label: this.internalLabel,
        value: this.value,
      };
    },
  },
  methods: {
    handleInput(option: AutocompleteOption): void {
      this.internalLabel = option.label;

      let value: number | undefined = Number(option.value);
      if (Number.isNaN(value)) {
        value = undefined;
      }

      if (this.value !== value) {
        this.internalValue = value;
        this.$emit('input', value);
      }
    },
    handleChange(option: AutocompleteOption): void {
      this.internalValue = option.value;
      this.internalLabel = option.label;
      this.$emit('input', Number(option.value));
    },
    loadProductName(): void {
      if (this.value === this.internalValue) {
        return;
      }

      if (!this.value) {
        this.internalLabel = '';

        return;
      }

      this.isLoadingProduct = true;
      api.products
        .get(this.value)
        .then((response) => {
          this.internalLabel = response.data.title;
          this.internalValue = this.value;
          this.isLoadingProduct = false;
        })
        .catch((error) => {
          this.isLoadingProduct = false;
          this.$store.dispatch(RootActions.ERROR, error);
          this.internalLabel = '';
          this.internalValue = undefined;
          this.$emit('input', undefined);
        });
    },
    searchProduct(userInput: string): AutocompletePromise {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = undefined;
      }

      if (!userInput) {
        return Promise.resolve({
          results: [],
        });
      }

      this.cancelTokenSource = api.getCancelTokenSource();

      return api.products
        .list({ search: userInput }, { cancelToken: this.cancelTokenSource.token })
        .then((response) => {
          return {
            results: response.data.data.map((item) => ({
              label: item.title ?? `#${item.id}`,
              value: item.id,
            })),
          };
        });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.loadProductName();
      },
    },
  },
});
