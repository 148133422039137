



















import Vue from 'vue';
import { mapActions } from 'vuex';
import { RootActions, EventTypes } from '@/store/types';
import { VideoPlayData } from '@/plugins/gtm/types';

type WistiaPlayerEvent =
  | 'beforeremove'
  | 'beforereplace'
  | 'betweentimes'
  | 'captionschange'
  | 'conversion'
  | 'crosstime'
  | 'end'
  | 'heightchange'
  | 'lookchange'
  | 'mutechange'
  | 'pause'
  | 'percentwatchedchanged'
  | 'play'
  | 'playbackratechange'
  | 'secondchange'
  | 'seek'
  | 'silentplaybackmodechange'
  | 'timechange'
  | 'volumechange'
  | 'widthchange';

interface WistiaPlayerAPI {
  popover: {
    hide(): void;
  };
  bind(eventType: WistiaPlayerEvent, callbackFn: (...args: any[]) => void): void;
  duration(): number;
  name(): string;
  percentWatched(): number;
  remove(): void;
  unbind(eventType: WistiaPlayerEvent, callbackFn: (...args: any[]) => void): void;
}

interface WindowWithWistia extends Window {
  _wq: {
    id: string;
    onReady?: (video: WistiaPlayerAPI) => void;
    onHasData?: (video: WistiaPlayerAPI) => void;
  }[];
}

const _wq = ((window as unknown as WindowWithWistia)._wq = (window as unknown as WindowWithWistia)._wq || []);

export default Vue.extend({
  name: 'WistiaVideoLayout',
  props: {
    id: String,
    popover: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    wistiaClass() {
      return 'wistia_async_' + this.id + ' ' + (this.popover ? 'popover=true popoverAnimateThumbnail=true wistia_async_' : '');
    },
  },
  data() {
    return {
      error: false,
      loading: true,
      wistiaAPI: undefined,
      eventDispatchIntervalID: undefined,
      latestPlayData: undefined,
      popover: true,
    };
  },
  mounted() {
    this.loadVideo();

    // Setup dispatch play data interval
    this.eventDispatchIntervalID = setInterval(() => {
      this.dispatchLatestPlayData();
    }, 1000 * 10);
  },
  beforeDestroy() {
    // Dispatch latest play data before destroying
    this.dispatchLatestPlayData();

    // Clear dispatch play data interval
    if (this.eventDispatchIntervalID) {
      clearInterval(this.eventDispatchIntervalID);
    }

    // Remove wistia video and popover
    if (this.wistiaAPI) {
      this.wistiaAPI.remove();
      this.wistiaAPI.popover.hide();
    }

    // Remove wistia video script
    this.removeVideoScript();
  },
  methods: {
    ...mapActions({
      dispatchEvent: RootActions.DISPATCH_EVENT,
    }),
    dispatchVideoPlayEvent(data: VideoPlayData) {
      this.dispatchEvent({
        type: EventTypes.VIDEO_PLAY,
        eventObj: data,
      });
    },
    dispatchLatestPlayData() {
      if (this.latestPlayData) {
        this.dispatchVideoPlayEvent(this.latestPlayData);
        this.latestPlayData = undefined;
      }
    },
    loadScript() {
      return new Promise<void>((resolve, reject) => {
        if (document.getElementById('wistia-script')) {
          return resolve();
        }

        const script = document.createElement('script');

        script.setAttribute('id', 'wistia-script');
        script.setAttribute('src', '//fast.wistia.com/assets/external/E-v1.js');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('async', 'true');

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          if (script.parentElement) {
            script.parentElement.removeChild(script);
          }

          reject();
        };

        document.body.appendChild(script);
      });
    },
    loadVideoScript() {
      return new Promise<void>((resolve, reject) => {
        if (document.getElementById(`wistia-video-${this.id}`)) {
          return resolve();
        }

        const script = document.createElement('script');

        script.setAttribute('id', `wistia-video-${this.id}`);
        script.setAttribute('src', `//fast.wistia.com/embed/medias/${this.id}.jsonp`);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('async', 'true');

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          if (script.parentElement) {
            script.parentElement.removeChild(script);
          }

          reject();
        };

        document.body.appendChild(script);
      });
    },
    loadVideo() {
      this.loading = true;

      _wq.push({
        id: this.id,
        onHasData: (video) => {
          this.wistiaAPI = video;

          const getVideoData = (): VideoPlayData => {
            return {
              video_name: video.name(),
              video_duration: video.duration(),
              percent_watched: Math.round(video.percentWatched() * 100) + '%',
            };
          };

          const playCallback = () => {
            this.dispatchVideoPlayEvent(getVideoData());

            return video.unbind;
          };

          const percentChangedCallback = () => {
            this.latestPlayData = getVideoData();
          };

          video.bind('play', playCallback);
          video.bind('percentwatchedchanged', percentChangedCallback);

          video.bind('beforeremove', () => {
            video.unbind('play', playCallback);
            video.unbind('percentwatchedchanged', percentChangedCallback);
          });
        },
      });

      return this.loadScript()
        .then(() => this.loadVideoScript())
        .then(() => {
          this.error = false;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    removeVideoScript() {
      const el = document.getElementById(`wistia-video-${this.id}`);
      if (el && el.parentElement) {
        el.parentElement.removeChild(el);
      }
    },
  },
});
