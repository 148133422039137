


































































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import { SettingsActions } from '@/store/modules/settings/types';

import TranslationsControl from '@/components/controls/translations.vue';
import FormInput from '@/components/controls/form-input.vue';
import Checkbox from '@/components/controls/checkbox.vue';

export default Vue.extend({
  name: 'GeneralSettingsForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    Checkbox,
    TranslationsControl,
  },
  computed: {
    ...mapState('settings', {
      editable: 'editableSettings',
      topBannerTranslationsStatus: 'topBannerTranslationsStatus',
    }),
    ...mapGetters('settings', {
      isLoading: 'editableSettingsIsLoading',
      isSaving: 'editableSettingsIsSaving',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
  },
  methods: {
    ...mapActions('settings', {
      updateEditable: SettingsActions.UPDATE_EDITABLE_SETTINGS,
      saveTopBannerTranslations: SettingsActions.SAVE_TOP_BANNER_TRANSLATIONS,
    }),
    updateTopBanner(changes) {
      let topBanner = this.editable.topBanner;
      topBanner = Object.assign(this.editable.topBanner, changes);
      this.updateEditable({ topBanner });
    },
  },
});
