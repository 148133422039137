































































































import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import Draggable from 'vuedraggable';

import { formatTime } from '@/i18n';
import { genWeekDays } from '@/utils/time';
import { LocationsActions } from '@/store/modules/locations/types';
import PractitionerRelationship from '@/store/modules/practitioners/practitioner-relationship';

export default Vue.extend({
  name: 'LocationPractitionersForm',
  components: {
    Draggable,
  },
  data() {
    return {
      weekDays: genWeekDays(),
    };
  },
  computed: {
    ...mapGetters('practices', {
      isPracticeReady: 'editableIsReady',
    }),
    ...mapState('locations', {
      location: 'editable',
    }),
    ...mapGetters('locations', ['hasPracticeAssigned', 'editableIsLoading', 'editableIsError']),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    practitionersIsReady() {
      return !this.editableIsLoading || !this.editableIsError;
    },
    practitioners: {
      get(): PractitionerRelationship[] {
        return this.location.practitioners;
      },
      set(practitioners: PractitionerRelationship[]) {
        this.updateLocation({
          practitioners,
        });
      },
    },
  },
  methods: {
    ...mapActions('locations', {
      updateLocation: LocationsActions.UPDATE_EDITABLE,
    }),
    togglePractitioner(practitioner: PractitionerRelationship): void {
      this.updateLocation({
        practitioners: this.location.practitioners.map((item) => {
          if (item.id === practitioner.id) {
            const clone = PractitionerRelationship.clone(item);
            clone.active = practitioner.active ? 0 : 1;

            return clone;
          }

          return item;
        }),
      });
    },
    workingHoursLabel(workingDay) {
      return `${this.weekDays[workingDay.day]} (${formatTime(workingDay.start)}-${formatTime(workingDay.end)})`;
    },
  },
});
