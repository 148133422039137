

























































import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';

import { SettingsActions } from '@/store/modules/settings/types';

import Modal from '@/components/layout/modal.vue';
import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Autocomplete from '@/components/controls/autocomplete.vue';

export default Vue.extend({
  name: 'BannedWordsSettingsView',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    Modal,
    FormInput,
    SubmitButton,
    NotificationBanner,
    Autocomplete,
  },
  data() {
    return {
      inputKey: 0,
      addInput: '',
    };
  },
  computed: {
    ...mapGetters(['isDirect']),
    ...mapState('settings', {
      editable: 'editableSettings',
    }),
    letters() {
      const out: { [key: string]: string[] } = {};

      (this.editable.bannedWords as string[])
        .sort((a, b) => {
          const aL = a.toLowerCase();
          const bL = b.toLowerCase();

          if (aL < bL) {
            return -1;
          }

          if (aL > bL) {
            return 1;
          }

          return 0;
        })
        .forEach((word) => {
          const letter = word.charAt(0).toLowerCase();

          if (!out[letter]) {
            out[letter] = [];
          }

          out[letter].push(word);
        });

      return out;
    },
    letterColumns(): Record<string, string[]>[] {
      const letters = Object.keys(this.letters);
      return [
        letters.slice(0, Math.ceil(letters.length / 3)),
        letters.slice(Math.ceil(letters.length / 3), Math.ceil((2 * letters.length) / 3)),
        letters.slice(Math.ceil((2 * letters.length) / 3)),
      ].map((columnLetters) => {
        if (!columnLetters.length) {
          return {};
        }

        return columnLetters.reduce((res, letter) => {
          res[letter] = this.letters[letter];
          return res;
        }, {} as { [key: string]: string[] });
      });
    },
  },
  methods: {
    ...mapActions('settings', {
      updateEditable: SettingsActions.UPDATE_EDITABLE_SETTINGS,
    }),
    addNew() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.editable.bannedWords.indexOf(this.addInput) !== -1) {
            this.addInput = '';
            this.inputKey += 1;
            return;
          }
          const changes = {
            bannedWords: [...this.editable.bannedWords, this.addInput],
          };
          await this.updateEditable(changes);
          this.addInput = '';
          this.inputKey += 1;
        }
      });
    },
    removeWord(word: string) {
      const changes = {
        bannedWords: this.editable.bannedWords.filter((item) => item !== word),
      };
      this.updateEditable(changes);
    },
    close() {
      if (this.isDirect) {
        this.$router.push({
          name: 'settings',
        });
      } else {
        this.$router.back();
      }
    },
  },
});
