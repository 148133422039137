


















































































import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import FormInput from '@/components/controls/form-input.vue';
import { PractitionersActions } from '@/store/modules/practitioners/types';

export default Vue.extend({
  name: 'PractitionerForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    ImageUploadControl,
  },
  data() {
    return {
      isOtherSpecialtySelected: false,
    };
  },
  computed: {
    ...mapState('settings', ['settings']),
    ...mapState('practitioners', {
      practitioner: 'editablePractitioner',
      specialtiesList: 'specialtiesList',
    }),
    /**
     * Returns true if isOtherSpecialtySelected is true or current
     * practitioner.specialty is not found in specialtiesList except "Other"
     */
    isOtherSpecialty() {
      return (
        this.isOtherSpecialtySelected ||
        this.specialtiesList
          .map((specialty) => specialty.value)
          .filter((specialty) => 'Other' !== specialty)
          .indexOf(this.practitioner.specialty) === -1
      );
    },
    selectedSpecialty: {
      /**
       * Returns selected specialty
       */
      get() {
        return this.isOtherSpecialty ? 'Other' : this.practitioner.specialty;
      },
      /**
       * Sets new specialty, updates isOtherSpecialtySelected and fires
       * handleDataUpdate method
       */
      set(specialty: string) {
        // Set isOtherSpecialtySelected
        if ('Other' === specialty) {
          this.isOtherSpecialtySelected = true;
          return;
        } else if (this.isOtherSpecialtySelected) {
          this.isOtherSpecialtySelected = false;
        }

        this.updatePractitioner({ specialty });
      },
    },
  },
  methods: {
    ...mapActions('practitioners', {
      updatePractitioner: PractitionersActions.UPDATE_EDITABLE,
    }),
  },
  mounted() {
    // Set isOtherSpecialtySelected to true if initial practitioner.specialty is
    // custom input
    if (this.isOtherSpecialty) {
      this.isOtherSpecialtySelected = true;
    }
  },
});
