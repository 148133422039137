import { captureException } from '@sentry/browser';
import { Route } from 'vue-router';
import last from 'lodash/last';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

import { setLocale } from '@/i18n';
import api from '@/api';

import {
  RootState,
  RootActions,
  RootMutations,
  EventTypes,
  ErrorMessages,
  ErrorCodes,
  RouterDispatchedActionPromise,
  HistoryEntry,
  ActionsTree,
  ListStatus,
  PublishStatus,
} from '@/store/types';

import { prepareResourcesFilters } from '@/store/utils/filters';
import { enhanceErrorWithMessage } from '@/utils/errors';
import blogApi from '@/store/utils/blog-api';

import { CountryStateJSON } from '@/store/models/country-state';
import Country, { CountryJSON } from '@/store/models/country';
import Language from '@/store/models/language';

import { NotificationsActions } from '@/store/modules/notifications/types';
import { LocationsActions } from '@/store/modules/locations/types';
import { PracticesActions } from '@/store/modules/practices/types';
import { PractitionersActions, PractitionersListOptions } from '@/store/modules/practitioners/types';
import { TreatmentActions } from '@/store/modules/treatments/types';
import { ProfileActions, UserRole } from '@/store/modules/user/profile/types';
import { UserActions } from '@/store/modules/user/types';
import { ResourcesActions, ResourcesRequestFilters } from '@/store/modules/resources/types';
import { CategoryActions } from '@/store/modules/categories/types';
import { ProductsActions } from '@/store/modules/products/types';
import { UsersActions } from '@/store/modules/users/types';
import { ReportsActions } from '@/store/modules/reports/types';
import { BlogActions } from '@/store/modules/blog/types';
import { SettingsActions } from '@/store/modules/settings/types';
import { ConsultationsActions } from '@/store/modules/consultations/types';
import { EventsActions } from '@/store/modules/events/types';
import { SupportActions } from '@/store/modules/support/types';
import { StatisticsActions } from '@/store/modules/statistics/types';
import { PrivacyRequestActions } from './modules/privacy_requests/types';

export const actions: ActionsTree<RootState, typeof RootActions> = {
  [RootActions.ERROR]({ commit, dispatch, getters }, error): Promise<void> {
    if (
      process.env.VUE_APP_MODE === 'development' ||
      (process.env.VUE_APP_MODE !== 'production' && localStorage.getItem('amps-store-errors-debug') === '1')
    ) {
      // tslint:disable-next-line: no-console
      console.error(error);
    }

    if (
      error.response &&
      error.response.status === 404 &&
      error.request &&
      error.request.responseURL &&
      (error.request.responseURL as string).match(/autosave$/g)
    ) {
      return Promise.resolve();
    }

    dispatch(RootActions.DISPATCH_EVENT, { type: EventTypes.ENCOUNTERED_ERROR, eventObj: { error } }, { root: true });

    if (api.isAxiosError(error)) {
      if (error.response) {
        const response = error.response;

        if (response.status === 401 && getters['user/isLoggedIn']) {
          // If API responses with 401 HTTP Status Code logout current user
          if (
            process.env.VUE_APP_MODE === 'development' ||
            (process.env.VUE_APP_MODE !== 'production' && localStorage.getItem('amps-store-errors-debug') === '1')
          ) {
            // tslint:disable-next-line: no-console
            console.log('logout from error action handler', error);
            // tslint:disable-next-line: no-console
            console.trace();

            return Promise.resolve();
          }
          return dispatch('user/' + UserActions.LOGOUT);
        } else if (response.status === 403) {
          // If API responses with 403 HTTP Status Code Forbidden
          commit(RootMutations.STORE_ERROR, {
            code: ErrorCodes.FORBIDDEN_ERROR,
            errorObject: error,
            message: error.message,
          });
        } else {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          commit(RootMutations.STORE_ERROR, {
            code: response.status,
            errorObject: error,
            message: error.message,
          });
        }

        // Look, if we're doing our job right, this should never get show.
        // It gets logged in sentry, and we should address.  The message should indicate
        // that they should contact support if this persists.
        let message: string = ErrorMessages.DATA_ERROR;
        if (error.errorMessage) {
          message = error.errorMessage;
        }

        dispatch('notifications/' + NotificationsActions.SHOW_ERROR, {
          title: message,
        });

        return Promise.resolve();
      }

      // The request was made but no response was received
      commit(RootMutations.STORE_ERROR, {
        code: ErrorCodes.NETWORK_ERROR,
        errorObject: error,
        message: error.message,
      });
      return Promise.resolve();
    }

    captureException(error);
    commit(RootMutations.STORE_ERROR, {
      code: ErrorCodes.UNHANDLED_ERROR,
      errorObject: error,
      message: error.message,
    });
    dispatch('notifications/' + NotificationsActions.SHOW_ERROR, {
      title: error.message,
    });

    return Promise.resolve();
  },
  [RootActions.OPEN_MODAL]({ commit }, contents) {
    commit(RootMutations.OPEN_MODAL, contents);
  },
  [RootActions.CLOSE_MODAL]({ commit }) {
    commit(RootMutations.CLOSE_MODAL);
  },
  [RootActions.CLEAR_ERRORS]({ commit, dispatch }) {
    dispatch('notifications/' + NotificationsActions.HIDE_NOTIFICATION);
    commit(RootMutations.CLEAR_ERRORS);
  },

  async [RootActions.ROUTE_ENTER](
    { commit, getters, dispatch },
    { to, from }: { to: Route; from: Route },
  ): RouterDispatchedActionPromise {
    const historyEntry: HistoryEntry = {
      to,
      from,
      timestamp: Date.now(),
    };

    commit(RootMutations.STORE_HISTORY_ENTRY, historyEntry);

    const roles: UserRole[] = last(
      to.matched.map((matchedRoute) => matchedRoute.meta.roles).filter((val) => Array.isArray(val) && val.length),
    );

    // If route requires user role
    if (roles) {
      // If user is not logged in redirect to login page
      if (!getters['user/isLoggedIn']) {
        return {
          next: {
            name: 'login',
          },
        };
      } else {
        await Promise.all([dispatch(RootActions.LOAD_LANGUAGES), dispatch(RootActions.LOAD_USER_DATA)]);

        // If user don't have practice redirect to login
        if (getters['user/profile/isUserWithoutPractice']) {
          return {
            next: {
              name: 'login',
            },
          };
        }

        // If user doesn't have required roles redirect to 404
        if (roles.indexOf(getters['user/profile/role']) === -1) {
          return {
            next: {
              name: '404',
            },
          };
        }
      }
    } else {
      if (getters['user/isLoggedIn']) {
        await dispatch(RootActions.LOAD_LANGUAGES);
      }
    }

    return Promise.resolve();
  },

  [RootActions.DISPATCH_EVENT]({ commit }, { type, eventObj }) {
    commit(RootMutations.DISPATCH_EVENT, { type, eventObj });
  },
  [RootActions.LOAD_PROGRESS_INCREMENT]({ commit, state }) {
    commit(RootMutations.SET_LOAD_PROGRESS, state.loadProgress + (100 - state.loadProgress) / 4);
  },
  [RootActions.LOAD_PROGRESS_DECREMENT]({ commit, state }) {
    commit(RootMutations.SET_LOAD_PROGRESS, state.loadProgress - (100 - state.loadProgress) / 4);
  },
  [RootActions.LOAD_PROGRESS_COMPLETE]({ commit }) {
    commit(RootMutations.SET_LOAD_PROGRESS, 100);
  },
  [RootActions.LOAD_PROGRESS_RESET]({ commit }) {
    commit(RootMutations.SET_LOAD_PROGRESS, 0);
  },
  [RootActions.LOAD_COUNTRIES]({ state, commit, dispatch }, { force = false } = {}) {
    if (!force && (state.countriesStatus === ListStatus.Loaded || state.countriesStatus === ListStatus.Loading)) {
      return Promise.resolve();
    }

    commit(RootMutations.SET_COUNTRIES_STATUS, ListStatus.Loading);

    api.countries
      .getAddressStates()
      .then((response) => {
        const obj = response.data;
        const countries: Country[] = Object.keys(obj).map((countryCode) => {
          const countryItem = obj[countryCode];
          const states: CountryStateJSON[] = Object.keys(countryItem.states).map((stateCode) => {
            return {
              code: stateCode,
              name: countryItem.states[stateCode],
            };
          });

          const countryJSON: CountryJSON = {
            id: countryItem.id,
            code: countryCode,
            name: countryItem.country,
            admin_email: countryItem.admin_email,
            states,
          };

          return Country.fromJSON(countryJSON);
        });

        commit(RootMutations.SET_COUNTRIES, countries);
        commit(RootMutations.SET_COUNTRIES_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(RootMutations.SET_COUNTRIES_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error);
      });

    return Promise.resolve();
  },
  [RootActions.LOAD_LANGUAGES]({ state, commit, dispatch }, payload?: { force?: boolean }) {
    if (
      (state.languagesStatus === ListStatus.Loaded && (!payload || !payload.force)) ||
      state.languagesStatus === ListStatus.Loading
    ) {
      return Promise.resolve();
    }

    commit(RootMutations.SET_LANGUAGES_STATUS, ListStatus.Loaded);

    api.languages
      .list()
      .then((response) => {
        commit(
          RootMutations.SET_LANGUAGES,
          response.data.map((item) => Language.fromJSON(item)),
        );
        commit(RootMutations.SET_LANGUAGES_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(RootMutations.SET_LANGUAGES_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error);
      });
    return Promise.resolve();
  },

  /**
   * MAYBE DISPATCH BY ACTION NAME
   */
  [RootActions.DISPATCH_ROUTER_ACTION]({ dispatch }, { actionName, to, from }): RouterDispatchedActionPromise {
    if (RootActions[actionName]) {
      return dispatch(actionName, { to, from });
    } else {
      return Promise.resolve();
    }
  },
  /**
   * DATA LOADING CONVENIENCE ACTIONS
   */
  [RootActions.LOAD_USER_DATA]({ dispatch, getters }): RouterDispatchedActionPromise {
    if (!getters['user/profile/isInitial']) {
      return Promise.resolve();
    }

    return dispatch('user/profile/' + ProfileActions.LOAD_PROFILE).then(() => {
      if (!getters['user/profile/isUserWithoutPractice']) {
        dispatch('locations/' + LocationsActions.LOAD_LOCATIONS_MENU_LIST);
        dispatch('practitioners/' + PractitionersActions.LOAD_PRACTITIONERS_MENU_LIST);

        blogApi.setToken(getters['user/profile/blogToken']);
      }

      // Load sitewide settings
      dispatch('settings/' + SettingsActions.LOAD_SETTINGS);

      dispatch('reports/' + ReportsActions.START_CHECKING_REQUESTED);
    });
  },
  /**
   * ROUTE BOUND ACTIONS
   */
  [RootActions.ROUTE_ENTER_ADD_LOCATION]({ dispatch, getters }, { to }): RouterDispatchedActionPromise {
    return dispatch('locations/' + LocationsActions.LOAD_LOCATION, {
      practice_id: to.query.practice_id,
    }).then(() => {
      dispatch(RootActions.LOAD_COUNTRIES);
      dispatch('settings/' + SettingsActions.LOAD_SETTINGS);
      const practiceId = getters['locations/editablePracticeId'];
      // admins do not have practice IDs
      if (practiceId !== 0) {
        dispatch('practices/' + PracticesActions.LOAD_PRACTICE, {
          id: practiceId,
        });
        dispatch('treatments/' + TreatmentActions.LOAD_TREE, {
          practice_id: practiceId,
        });
        dispatch(`treatments/${TreatmentActions.LOAD_ENHANCEMENT_LIST}`, {
          practice_id: practiceId,
        });
      }
    });
  },
  [RootActions.ROUTE_ENTER_EDIT_LOCATION]({ dispatch, getters }, { to, from }): RouterDispatchedActionPromise {
    if (from.name === 'preview-edit-location') {
      return Promise.resolve();
    }

    return dispatch('locations/' + LocationsActions.LOAD_LOCATION, {
      id: to.params.id,
      revision: to.query.autosaved ? 'autosave' : 'latest',
    }).then(() => {
      const practiceId = getters['locations/editablePracticeId'];
      dispatch(RootActions.LOAD_COUNTRIES);
      dispatch('settings/' + SettingsActions.LOAD_SETTINGS);
      dispatch('practices/' + PracticesActions.LOAD_PRACTICE, {
        id: practiceId,
      });
      dispatch('treatments/' + TreatmentActions.LOAD_TREE, {
        practice_id: practiceId,
      });
      dispatch(`treatments/${TreatmentActions.LOAD_ENHANCEMENT_LIST}`, {
        practice_id: practiceId,
      });
    });
  },
  [RootActions.ROUTE_ENTER_PREVIEW_EDIT_LOCATION]({ dispatch }, { to, from }): RouterDispatchedActionPromise {
    if (from.name === 'edit-location') {
      return dispatch('locations/' + LocationsActions.SET_IS_PREVIEW_FROM_EDIT_SCREEN);
    }

    return dispatch(RootActions.ROUTE_ENTER_EDIT_LOCATION, { to, from });
  },
  [RootActions.ROUTE_ENTER_ADD_PRACTITIONER]({ dispatch, getters }, { to }): RouterDispatchedActionPromise {
    return dispatch('practitioners/' + PractitionersActions.LOAD_PRACTITIONER, {
      ...to.query,
    }).then(() => {
      dispatch('settings/' + SettingsActions.LOAD_SETTINGS);
      const practiceId = getters['practitioners/editablePracticeId'];
      // admins do not have practice IDs
      if (practiceId) {
        return dispatch('locations/' + LocationsActions.LOAD_LOCATIONS_LIST, {
          practice_id: practiceId,
        });
      }
    });
  },
  [RootActions.ROUTE_ENTER_EDIT_PRACTITIONER]({ dispatch, getters }, { to }): RouterDispatchedActionPromise {
    return dispatch('practitioners/' + PractitionersActions.LOAD_PRACTITIONER, {
      id: to.params.id,
    }).then(() => {
      dispatch('settings/' + SettingsActions.LOAD_SETTINGS);
      const practiceId = getters['practitioners/editablePracticeId'];
      return dispatch('locations/' + LocationsActions.LOAD_LOCATIONS_LIST, {
        practice_id: practiceId,
      });
    });
  },
  async [RootActions.ROUTE_ENTER_PRACTICE]({ dispatch, getters }): RouterDispatchedActionPromise {
    const practiceId = getters['user/profile/practiceId'];
    await Promise.all([
      dispatch('practices/' + PracticesActions.LOAD_PRACTICE, {
        id: practiceId,
      }),
      dispatch('locations/' + LocationsActions.LOAD_LOCATIONS_LIST, {
        practice_id: practiceId,
      }),
      dispatch('practitioners/' + PractitionersActions.LOAD_PRACTITIONERS_LIST, { practice_id: practiceId }),
    ]);
  },
  [RootActions.ROUTE_ENTER_LOCATIONS]({ dispatch, getters }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);

    const practiceId = getters['user/profile/practiceId'];
    dispatch('locations/' + LocationsActions.LOAD_LOCATIONS_LIST, {
      practice_id: practiceId,
      ...to.params,
      ...to.query,
    });
    return Promise.resolve();
  },
  async [RootActions.ROUTE_ENTER_LOCATIONS_QUICK_REVIEW]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);
    dispatch('locations/' + LocationsActions.LOAD_LOCATIONS_LIST, {
      status: PublishStatus.Pending,
    });
    dispatch('locations/' + LocationsActions.LOAD_LOCATIONS_QUICK_REVIEW, {
      ...to.params,
    });
  },
  [RootActions.ROUTE_ENTER_PRACTITIONERS]({ dispatch, getters }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);

    const { page } = to.params;
    const { search, sort, direction, status, state, country_id } = to.query;
    let { practice_id } = to.query;

    if (getters['user/profile/practiceId']) {
      practice_id = getters['user/profile/practiceId'];
    }

    const practitionersListOptions: PractitionersListOptions = {
      practice_id,
      page,
      search,
      sort,
      direction,
      status,
      state,
      country_id,
    };

    dispatch('practitioners/' + PractitionersActions.LOAD_PRACTITIONERS_LIST, practitionersListOptions);
    return Promise.resolve();
  },
  [RootActions.ROUTE_ENTER_DASHBOARD]({ dispatch, getters }): RouterDispatchedActionPromise {
    const isUser = getters['user/profile/isUser'];
    const isSuperAdmin = getters['user/profile/isSuperAdmin'];
    const isAdmin = getters['user/profile/isAdmin'];

    // Redirect other roles to resources
    if (!isUser && !isSuperAdmin && !isAdmin) {
      return Promise.resolve({
        next: {
          name: 'resources',
          query: {},
        },
      });
    }

    if (isUser) {
      const practiceId = getters['user/profile/practiceId'];

      return Promise.all([
        dispatch('practices/' + PracticesActions.LOAD_USER_PRACTICE, {
          id: practiceId,
        }),
        dispatch('locations/' + LocationsActions.LOAD_PENDING_LIST),
        dispatch('locations/' + LocationsActions.LOAD_PUBLISHED_LIST),
        dispatch('consultations/' + ConsultationsActions.LOAD_LIST),
        dispatch('consultations/' + ConsultationsActions.LOAD_LESS_QUALIFIED_LIST),
        dispatch('consultations/' + ConsultationsActions.LOAD_USER_STATS, {
          practiceId,
        }),
        dispatch('resources/' + ResourcesActions.LOAD_LASER_CATEGORY_ID),
        dispatch('resources/' + ResourcesActions.LOAD_TRAINING_RESOURCES),
        dispatch('support/' + SupportActions.LOAD_FAQ_LIST),
      ]).then(() => {
        dispatch('resources/' + ResourcesActions.LOAD_FEATURED_RESOURCES);
        dispatch('resources/' + ResourcesActions.LOAD_LOCAL_RECENTLY_VIEWED_RESOURCES).then(() =>
          dispatch('resources/' + ResourcesActions.LOAD_RECENTLY_VIEWED_RESOURCES),
        );
        dispatch('events/' + EventsActions.LOAD_LIST);
      });
    }

    dispatch('locations/' + LocationsActions.LOAD_PENDING_LIST);
    dispatch('statistics/' + StatisticsActions.LOAD_DOWNLOADS);
    dispatch('statistics/' + StatisticsActions.LOAD_USERS);
    dispatch('statistics/' + StatisticsActions.LOAD_TRAININGS);

    return Promise.resolve();
  },
  async [RootActions.ROUTE_ENTER_RESOURCES]({ dispatch, getters, state }, { to, from }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);

    let listFilters: ResourcesRequestFilters | undefined = (state as any).resources.resourcesListFilters;

    if (!listFilters) {
      listFilters = {};
    }

    const options = prepareResourcesFilters({ ...to.params, ...to.query });
    let shouldLoadFeatured = !getters['resources/featuredIsLoaded'];
    let shouldLoadCategories = !getters['categories/isAllLoaded'];
    let shouldLoadTreatments = !getters['treatments/treatmentsTreeIsLoaded'];

    if (!from.name || from.name !== 'resources') {
      shouldLoadFeatured = true;
      shouldLoadCategories = true;
      shouldLoadTreatments = true;
    } else {
      if (!isEqual(pick(options, ['products']), pick(listFilters, ['products']))) {
        shouldLoadFeatured = true;
      }

      if (
        !isEqual(
          pick(options, ['products', 'treatments', 'search']),
          pick(listFilters, ['products', 'treatments', 'search']),
        )
      ) {
        shouldLoadCategories = true;
      }
    }

    if (shouldLoadTreatments) {
      await dispatch('treatments/' + TreatmentActions.LOAD_TREE);
    }

    dispatch('resources/' + ResourcesActions.LOAD, options);

    if (shouldLoadFeatured) {
      dispatch('resources/' + ResourcesActions.LOAD_FEATURED_RESOURCES, options);
    }

    if (shouldLoadCategories) {
      dispatch('categories/' + CategoryActions.LOAD_ALL, { filters: options });
    }

    return dispatch('resources/' + ResourcesActions.UPDATE_RESOURCES_FILTERS, options);
  },
  async [RootActions.ROUTE_ENTER_RESOURCE]({ dispatch }, { to, from }): RouterDispatchedActionPromise {
    const isDirect = !from.name || from.name !== 'resources';

    try {
      await dispatch('resources/' + ResourcesActions.LOAD_RESOURCE, {
        id: to.params.id,
        isDirect,
      });
    } catch (error) {
      return {
        next: {
          name: '404',
        },
      };
    }

    await dispatch('resources/' + ResourcesActions.LOAD_RELATED, {
      id: to.params.id,
    });
  },
  [RootActions.ROUTE_ENTER_ADD_RESOURCE]({ dispatch, getters }, { from }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);

    if (from.name === 'add-resource/add-category') {
      if (getters['categories/editableIsSaved']) {
        dispatch('categories/' + CategoryActions.LOAD_ALL);
      }

      return Promise.resolve();
    }

    return Promise.all([
      dispatch('resources/' + ResourcesActions.LOAD_EMPTY_EDITABLE),
      dispatch('categories/' + CategoryActions.LOAD_ALL),
      dispatch('products/' + ProductsActions.LOAD_TREE),
    ]).then(() => undefined);
  },
  async [RootActions.ROUTE_ENTER_EDIT_RESOURCE]({ dispatch, getters }, { to, from }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);

    if (from.name === 'edit-resource/add-category') {
      if (getters['categories/editableIsSaved']) {
        dispatch('categories/' + CategoryActions.LOAD_ALL);
      }

      return Promise.resolve();
    }

    try {
      await dispatch('resources/' + ResourcesActions.LOAD_EDITABLE, {
        id: to.params.id,
      });
    } catch (error) {
      return {
        next: {
          name: '404',
        },
      };
    }

    await Promise.all([
      dispatch('categories/' + CategoryActions.LOAD_ALL),
      dispatch('products/' + ProductsActions.LOAD_TREE),
    ]);
  },
  [RootActions.ROUTE_ENTER_RESOURCE_DOWNLOADS]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch('statistics/' + StatisticsActions.LOAD_DOWNLOADS, {
      ...to.query,
      ...to.params,
    });

    return Promise.resolve();
  },
  [RootActions.ROUTE_ENTER_LOGIN]({ getters }): RouterDispatchedActionPromise {
    if (!getters['user/isLoggedIn'] || getters['user/profile/isUserWithoutPractice']) {
      return Promise.resolve();
    }

    return Promise.resolve({
      next: {
        name: 'dashboard',
      },
    });
  },
  [RootActions.ROUTE_ENTER_CATEGORIES]({ dispatch, getters }, { to, from }): RouterDispatchedActionPromise {
    let page = 1;
    if (to.params.page) {
      page = Number(to.params.page);
    }

    if (['add-category', 'edit-category'].indexOf(from.name) === -1 || getters['categories/editableIsSaved']) {
      dispatch('categories/' + CategoryActions.LOAD, { page, ...to.query });
    }

    return Promise.resolve();
  },
  [RootActions.ROUTE_ENTER_EDIT_CATEGORY]({ dispatch }, { to, from }): RouterDispatchedActionPromise {
    dispatch('categories/' + CategoryActions.LOAD_EDITABLE, to.params);

    if (!from || from.name !== 'categories') {
      dispatch('categories/' + CategoryActions.LOAD);
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_ADD_CATEGORY]({ dispatch }, { from }): RouterDispatchedActionPromise {
    dispatch('categories/' + CategoryActions.LOAD_EDITABLE);

    if (!from || from.name !== 'categories') {
      dispatch('categories/' + CategoryActions.LOAD);
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_ADD_RESOURCE_ADD_CATEGORY]({ dispatch }, { to, from }): RouterDispatchedActionPromise {
    dispatch('categories/' + CategoryActions.LOAD_EDITABLE);

    if (!from || from.name !== 'add-resource') {
      dispatch(RootActions.ROUTE_ENTER_ADD_RESOURCE, { to, from });
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_EDIT_RESOURCE_ADD_CATEGORY]({ dispatch }, { to, from }): RouterDispatchedActionPromise {
    dispatch('categories/' + CategoryActions.LOAD_EDITABLE);

    if (!from || from.name !== 'edit-resource') {
      dispatch(RootActions.ROUTE_ENTER_EDIT_RESOURCE, { to, from });
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_USERS]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);
    dispatch('users/' + UsersActions.LOAD_LIST, { ...to.params, ...to.query });

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_EDIT_USER]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);
    return dispatch('users/' + UsersActions.LOAD_EDITABLE, { ...to.params });
  },

  [RootActions.ROUTE_ENTER_ADD_USER]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);
    return dispatch('users/' + UsersActions.LOAD_EDITABLE, { ...to.query });
  },

  [RootActions.ROUTE_ENTER_PRACTICES]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);
    dispatch('practices/' + PracticesActions.LOAD_LIST, {
      ...to.params,
      ...to.query,
    });

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_VIEW_PRACTICE]({ dispatch }, { to }): RouterDispatchedActionPromise {
    return dispatch('practices/' + PracticesActions.LOAD_PRACTICE, {
      ...to.params,
      ...to.query,
    }).then(() => {
      dispatch('locations/' + LocationsActions.LOAD_LOCATIONS_LIST, {
        practice_id: to.params.id,
      });
    });
  },

  [RootActions.ROUTE_ENTER_EDIT_PRACTICE]({ dispatch }, { to }): RouterDispatchedActionPromise {
    return dispatch('practices/' + PracticesActions.LOAD_PRACTICE, {
      ...to.params,
      ...to.query,
    }).then(() => {
      dispatch(RootActions.LOAD_COUNTRIES);
      dispatch('products/' + ProductsActions.LOAD_TREE);
      dispatch('treatments/' + TreatmentActions.LOAD_ENHANCEMENTS_TREE);
    });
  },

  [RootActions.ROUTE_ENTER_ADD_PRACTICE]({ dispatch }, { to }): RouterDispatchedActionPromise {
    return dispatch('practices/' + PracticesActions.LOAD_PRACTICE).then(() => {
      dispatch(RootActions.LOAD_COUNTRIES);
      dispatch('products/' + ProductsActions.LOAD_TREE);
      dispatch('treatments/' + TreatmentActions.LOAD_ENHANCEMENTS_TREE);
    });
  },

  [RootActions.ROUTE_ENTER_PRODUCTS]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch('products/' + ProductsActions.LOAD_LIST, {
      ...to.params,
      ...to.query,
    });

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_EDIT_PRODUCT]({ dispatch }, { to }): RouterDispatchedActionPromise {
    return dispatch('products/' + ProductsActions.LOAD_EDITABLE, {
      ...to.params,
      ...to.query,
    }).then(() => {
      return Promise.all([
        dispatch('products/' + ProductsActions.LOAD_ACCESSORIES_TREE),
        dispatch('treatments/' + TreatmentActions.LOAD_ALL_TREE),
      ]).then(() => undefined);
    });
  },

  [RootActions.ROUTE_ENTER_ADD_PRODUCT]({ dispatch }): RouterDispatchedActionPromise {
    return dispatch('products/' + ProductsActions.LOAD_EDITABLE).then(() => {
      return Promise.all([
        dispatch('products/' + ProductsActions.LOAD_ACCESSORIES_TREE),
        dispatch('treatments/' + TreatmentActions.LOAD_ALL_TREE),
      ]).then(() => undefined);
    });
  },

  [RootActions.ROUTE_ENTER_ACCESSORIES]({ dispatch, getters }, { to, from }): RouterDispatchedActionPromise {
    let page = 1;
    if (to.params.page) {
      page = Number(to.params.page);
    }

    if (['add-accessory', 'edit-accessory'].indexOf(from.name) === -1 || getters['products/accessoryIsSaved']) {
      dispatch('products/' + ProductsActions.LOAD_ACCESSORIES_LIST, {
        page,
        ...to.query,
      });
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_ADD_ACCESSORY]({ dispatch }, { from }): RouterDispatchedActionPromise {
    dispatch('products/' + ProductsActions.LOAD_ACCESSORY);
    dispatch('products/' + ProductsActions.LOAD_TREE);
    dispatch('treatments/' + TreatmentActions.LOAD_ALL_TREE);

    if (!from || from.name !== 'accessories') {
      dispatch('products/' + ProductsActions.LOAD_ACCESSORIES_LIST);
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_EDIT_ACCESSORY]({ dispatch }, { to, from }): RouterDispatchedActionPromise {
    dispatch('products/' + ProductsActions.LOAD_ACCESSORY, to.params);
    dispatch('products/' + ProductsActions.LOAD_TREE);
    dispatch('treatments/' + TreatmentActions.LOAD_ALL_TREE);

    if (!from || from.name !== 'accessories') {
      dispatch('products/' + ProductsActions.LOAD_ACCESSORIES_LIST);
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_TREATMENTS]({ dispatch, getters }, { to, from }): RouterDispatchedActionPromise {
    let page = 1;
    if (to.params.page) {
      page = Number(to.params.page);
    }

    if (['add-treatment', 'edit-treatment'].indexOf(from.name) === -1 || getters['treatments/editableIsSaved']) {
      dispatch('treatments/' + TreatmentActions.LOAD_LIST, {
        page,
        ...to.query,
      });
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_ADD_TREATMENT]({ dispatch }, { from }): RouterDispatchedActionPromise {
    dispatch('treatments/' + TreatmentActions.LOAD_EDITABLE);
    dispatch('products/' + ProductsActions.LOAD_ACCESSORIES_TREE);
    dispatch('products/' + ProductsActions.LOAD_TREE);
    if (!from || from.name !== 'treatments') {
      dispatch('treatments/' + TreatmentActions.LOAD_LIST);
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_EDIT_TREATMENT]({ dispatch }, { to, from }): RouterDispatchedActionPromise {
    dispatch('treatments/' + TreatmentActions.LOAD_EDITABLE, to.params);
    dispatch('products/' + ProductsActions.LOAD_ACCESSORIES_TREE);
    dispatch('products/' + ProductsActions.LOAD_TREE);
    if (!from || from.name !== 'treatments') {
      dispatch('treatments/' + TreatmentActions.LOAD_LIST);
    }

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_ENHANCEMENTS]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch('treatments/' + TreatmentActions.LOAD_ENHANCEMENT_LIST, {
      ...to.params,
      ...to.query,
    });

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_EDIT_ENHANCEMENT]({ dispatch }, { to }): RouterDispatchedActionPromise {
    return dispatch('treatments/' + TreatmentActions.LOAD_ENHANCEMENT, {
      ...to.params,
      ...to.query,
    });
  },

  [RootActions.ROUTE_ENTER_ADD_ENHANCEMENT]({ dispatch }): RouterDispatchedActionPromise {
    return dispatch('treatments/' + TreatmentActions.LOAD_ENHANCEMENT);
  },

  [RootActions.ROUTE_ENTER_PUBLIC_TREATMENTS]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);
    dispatch('treatments/' + TreatmentActions.LOAD_PUBLIC_TREATMENT_LIST, {
      ...to.params,
      ...to.query,
    });

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_EDIT_PUBLIC_TREATMENT]({ dispatch }, { to }): RouterDispatchedActionPromise {
    return dispatch('treatments/' + TreatmentActions.LOAD_PUBLIC_TREATMENT, {
      ...to.params,
      ...to.query,
    })
      .then(() => {
        return Promise.all([
          dispatch(RootActions.LOAD_COUNTRIES),
          dispatch('treatments/' + TreatmentActions.LOAD_ALL_TREE),
        ]);
      })
      .then(() => undefined);
  },

  [RootActions.ROUTE_ENTER_ADD_PUBLIC_TREATMENT]({ dispatch }): RouterDispatchedActionPromise {
    return dispatch('treatments/' + TreatmentActions.LOAD_PUBLIC_TREATMENT)
      .then(() => {
        return Promise.all([
          dispatch(RootActions.LOAD_COUNTRIES),
          dispatch('treatments/' + TreatmentActions.LOAD_ALL_TREE),
        ]);
      })
      .then(() => undefined);
  },

  [RootActions.ROUTE_ENTER_PUBLIC_SITES]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch('treatments/' + TreatmentActions.LOAD_PUBLIC_SITE_LIST, {
      ...to.params,
      ...to.query,
    });

    return Promise.resolve();
  },

  async [RootActions.ROUTE_ENTER_EDIT_PUBLIC_SITE]({ dispatch }, { to }): RouterDispatchedActionPromise {
    await dispatch('treatments/' + TreatmentActions.LOAD_PUBLIC_SITE, {
      ...to.params,
      ...to.query,
    });
  },

  async [RootActions.ROUTE_ENTER_ADD_PUBLIC_SITE]({ dispatch }): RouterDispatchedActionPromise {
    await dispatch('treatments/' + TreatmentActions.LOAD_PUBLIC_SITE);
  },

  [RootActions.ROUTE_ENTER_REPORTS]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch('reports/' + ReportsActions.LOAD_LIST, {
      ...to.params,
      ...to.query,
    });

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_BLOG]({ dispatch }, { to }) {
    dispatch('blog/' + BlogActions.LOAD_LIST, { ...to.query, ...to.params });
    return dispatch('blog/' + BlogActions.LOAD_CATEGORIES);
  },

  [RootActions.ROUTE_ENTER_BLOG_CATEGORY]({ dispatch }, { from, to }) {
    return dispatch(RootActions.ROUTE_ENTER_BLOG, { from, to });
  },

  [RootActions.ROUTE_ENTER_BLOG_AUTHOR]({ dispatch }, { from, to }) {
    dispatch('blog/' + BlogActions.LOAD_LIST, { ...to.query, ...to.params });
    return dispatch('blog/' + BlogActions.LOAD_AUTHOR, {
      ...to.query,
      ...to.params,
    });
  },

  [RootActions.ROUTE_ENTER_BLOG_POST]({ dispatch }, { to }) {
    return dispatch('blog/' + BlogActions.LOAD_POST, {
      ...to.query,
      ...to.params,
    });
  },

  /**
   * Settings
   */
  [RootActions.ROUTE_ENTER_SETTINGS]({ dispatch }, { from }) {
    if (!from || !from.name || from.name !== 'banned-words') {
      dispatch(RootActions.LOAD_COUNTRIES, { force: true });
      return Promise.all([
        dispatch('settings/' + SettingsActions.LOAD_EDITABLE_SETTINGS),
        dispatch('support/' + SupportActions.LOAD_FAQ_EDITABLE),
      ]);
    }
  },
  [RootActions.ROUTE_ENTER_BANNED_WORDS]({ dispatch }, { from }) {
    if (!from || !from.name || from.name !== 'settings') {
      dispatch(RootActions.LOAD_COUNTRIES, { force: true });
      return dispatch('settings/' + SettingsActions.LOAD_EDITABLE_SETTINGS);
    }
  },

  /**
   * Consultations
   */
  [RootActions.ROUTE_ENTER_CONSULTATIONS]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch('consultations/' + ConsultationsActions.LOAD_LIST, {
      ...to.params,
      ...to.query,
    });

    return Promise.resolve();
  },

  [RootActions.ROUTE_ENTER_VIEW_CONSULTATION]({ dispatch }, { to }): RouterDispatchedActionPromise {
    dispatch('consultations/' + ConsultationsActions.LOAD_EDITABLE, {
      ...to.params,
    });

    return Promise.resolve();
  },

  /**
   * Support
   */

  [RootActions.ROUTE_ENTER_SUPPORT]({ dispatch }): RouterDispatchedActionPromise {
    return dispatch('support/' + SupportActions.LOAD_FAQ_LIST);
  },

  [RootActions.ROUTE_ENTER_GDPR_INFORMATION_REQUEST]({ dispatch, getters }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);

    return Promise.resolve();
  },

  /**
   * Privacy requests
   */

  [RootActions.ROUTE_ENTER_PRIVACY_REQUESTS]({ dispatch, getters }, { to }): RouterDispatchedActionPromise {
    dispatch(RootActions.LOAD_COUNTRIES);

    dispatch('privacy_requests/' + PrivacyRequestActions.LOAD, {
      ...to.params,
      ...to.query,
    });
    return Promise.resolve();
  },
  async [RootActions.ROUTE_ENTER_VIEW_PRIVACY_REQUEST]({ dispatch, getters }, { to }): RouterDispatchedActionPromise {
    await Promise.all([
      dispatch(RootActions.LOAD_COUNTRIES),
      dispatch('privacy_requests/' + PrivacyRequestActions.LOAD_EDITABLE, {
        id: to.params.id,
      }),
    ]);
  },

  /**
   * Page Change
   */
  [RootActions.PAGE_CHANGE]({ commit, dispatch }, { to, from }): RouterDispatchedActionPromise {
    commit(RootMutations.SET_ROUTE, to);

    dispatch(RootActions.DISPATCH_EVENT, {
      type: EventTypes.PAGE_CHANGE,
      eventObj: { to, from },
    });

    return Promise.resolve();
  },
  [RootActions.SET_LANGUAGE](_, locale) {
    return setLocale(locale);
  },
};

export default actions;
