






























import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { RootActions } from '@/store/types';
import { ResourcesActions } from '@/store/modules/resources/types';

export default Vue.extend({
  name: 'ResourceFileBlock',
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('resources', {
      resource: 'currentResource',
      personalizeStatus: 'personalizeStatus',
    }),
  },
  methods: {
    ...mapActions([RootActions.DISPATCH_EVENT]),
    ...mapActions('resources', {
      personalize: ResourcesActions.PERSONALIZE,
    }),
  },
});
