


















import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'SystemBannerBlock',
  computed: {
    ...mapGetters(['showSystemBanner']),
    ...mapState(['latestError']),
  },
});
