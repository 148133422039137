

























import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { NotificationsActions, NotificationType } from '@/store/modules/notifications/types';

export default Vue.extend({
  name: 'NotificationBannerBlock',
  computed: {
    ...mapState('notifications', ['message', 'type']),
    ...mapGetters('notifications', ['isVisible', 'isNotice', 'isWarning', 'isError', 'hasList', 'hasTitle', 'hasBody']),
    notificationClass(): string {
      switch (this.type) {
        case NotificationType.Error:
          return 'c-toolbar--error c-section--dark';

        case NotificationType.Warning:
          return 'c-toolbar--warning';

        case NotificationType.Notice:
          return 'c-toolbar--notice';

        case NotificationType.Success:
          return 'c-toolbar--success c-section--dark';
      }

      return 'c-toolbar--error c-section--dark';
    },
    hasVisitLink(): boolean {
      return this.message && this.message.visit && this.message.visit.link && this.message.visit.label;
    },
    hasBodyIcon(): boolean {
      return this.message && this.message.bodyIcon;
    },
    isDismissible(): boolean {
      return this.message && this.message.dismissible;
    },
  },
  methods: {
    ...mapActions('notifications', {
      hide: NotificationsActions.HIDE_NOTIFICATION,
    }),
  },
  watch: {
    $route(to, from) {
      this.hide();
    },
  },
});
