import Vue, { PluginObject } from 'vue';
import { DriftWindow } from './drift-api';
import store from '@/store';
import { RootState } from '@/store/types';

const DRIFT_TIMEOUT_ERROR_NAME = 'DriftTimeoutError';

class Drift implements PluginObject<{}> {
  public installed: boolean = false;
  private driftAPIPromise?: Promise<DriftWindow>;

  public install(vue: typeof Vue) {
    // Make sure the plugin is only installed once
    if (this.installed) {
      return;
    }

    this.withDriftAPI()
      .then((drift) => {
        store.watch(
          (state: RootState) => {
            return {
              currentRoute: state.route,
            };
          },
          (val) => {
            if (val.currentRoute) {
              drift.page(val.currentRoute.name ? val.currentRoute.name : val.currentRoute.fullPath);
            }
          },
          { immediate: true },
        );

        drift.on('ready', (api) => {
          const el = document.getElementById('drift-widget-container');

          if (!el) {
            return;
          }

          store.watch(
            (state: RootState, getters) => ({
              modal: getters['user/profile/showTermsModal'] as boolean,
              banner: getters['user/profile/showTermsBanner'] as boolean,
            }),
            (val) => {
              if (val.modal || val.banner) {
                el.style.display = 'none';
              } else {
                el.style.display = 'block';
              }
            },
            { immediate: true },
          );
        });
      })
      .catch((error) => {
        if (error.name !== DRIFT_TIMEOUT_ERROR_NAME) {
          throw error;
        }
      });
  }

  public ready(): Promise<void> {
    if ((document as any).attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      document.addEventListener('DOMContentLoaded', () => resolve());
    });
  }

  public withDriftAPI(): Promise<DriftWindow> {
    if (!this.driftAPIPromise) {
      this.driftAPIPromise = this.ready().then((): Promise<DriftWindow> => {
        const timeout = new Date().getTime() + 10000;

        return new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (typeof (window as any).drift !== 'undefined') {
              clearInterval(interval);

              return resolve((window as any).drift);
            }

            if (new Date().getTime() > timeout) {
              clearInterval(interval);

              const error = new Error(
                'For safety reason we bail out of checking for drift script. It may be blocked or timeouted.',
              );

              error.name = DRIFT_TIMEOUT_ERROR_NAME;

              return reject(error);
            }
          }, 60);
        });
      });
    }

    return this.driftAPIPromise;
  }
}

const driftPlugin = new Drift();

export default driftPlugin;
