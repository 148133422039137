var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'c-card',
    'c-entry-card',
    'c-card__title--larger',
    {
      'u-mb-spacer-section@md': !_vm.related,
      'c-entry-card--hor@md': _vm.small,
    } ]},[_c('div',{staticClass:"c-card__item"},[(_vm.post.bannerImage)?_c('figure',{staticClass:"c-tile__element--expand"},[_c('router-link',{attrs:{"to":{ name: 'blog-post', params: { slug: _vm.post.slug } }}},[_c('img',{attrs:{"src":_vm.post.bannerImage,"alt":_vm.post.title}})])],1):_vm._e(),_c('header',{staticClass:"c-card__header"},[_c('h3',{staticClass:"c-card__title"},[_c('router-link',{attrs:{"to":{ name: 'blog-post', params: { slug: _vm.post.slug } }}},[_vm._v(" "+_vm._s(_vm.post.title)+" ")])],1),_c('blog-post-meta',{attrs:{"card":"","post":_vm.post}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.post.teaser)}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }