































import Vue, { PropOptions } from 'vue';
import { mapState, mapGetters } from 'vuex';
import FilterSkeleton from '@/components/skeletons/treatments-filter-form.vue';
import Treatment from '@/store/modules/treatments/treatment';
import ProductRelationship from '@/store/modules/products/product-relationship';

export default Vue.extend({
  name: 'TreatmentsFilterForm',
  components: {
    FilterSkeleton,
  },
  props: {
    product: {
      type: Number,
    },
    treatments: {
      type: Array,
    } as PropOptions,
  },
  computed: {
    selectedProduct: {
      get(): number {
        return this.product;
      },
      set(selected: string | number): void {
        this.emitInput({ product: Number(selected) });
      },
    },
    productTreatments(): Treatment[] {
      if (!this.product) {
        return [];
      }

      const product = this.treatmentsTree.find((item: ProductRelationship) => item.id === this.product);

      if (!product || !product.treatments) {
        return [];
      }

      return product.treatments;
    },
    selectedTreatments: {
      get(): number[] {
        return this.treatments;
      },
      set(value: number[]): void {
        this.emitInput({ treatments: value });
      },
    },
    ...mapState('treatments', ['treatmentsTree']),
    isProductFilterSelected(): boolean {
      return !!this.product;
    },
    ...mapGetters('treatments', {
      isLoading: 'treatmentsTreeIsLoading',
    }),
  },
  methods: {
    emitInput(change) {
      if (!change) {
        return;
      }

      const { product = this.product, treatments = this.treatments } = change;

      this.$emit('input', { product, treatments });
    },
    isTreatmentSelected(id: number): boolean {
      return this.treatments.indexOf(id) > -1;
    },
  },
});
