import { JSONModel } from '@/store/models/jsonModel';
import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import CountryRelationship, { CountryRelationshipJSON } from '@/store/modules/countries/country-relationship';

export enum PublicTreatmentStatus {
  Inactive = 0,
  Active = 1,
}

export interface PublicTreatmentJSON {
  id: number;
  status: number;
  title: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  treatments_count: number;
  treatments: TaxonomyJSON[];
  language_id: number;
  language_code: string;
  countries: CountryRelationshipJSON[];
  sites: TaxonomyJSON[];
  translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  };
}

const availableStatuses = Object.keys(PublicTreatmentStatus).map((key) => PublicTreatmentStatus[key]);

export default class PublicTreatment extends JSONModel<PublicTreatmentJSON> {
  public id: number = 0;
  public status: PublicTreatmentStatus = PublicTreatmentStatus.Inactive;
  public title: string = '';
  public description: string = '';
  public createdAt?: string;
  public updatedAt?: string;
  public treatmentsCount: number = 0;
  public treatments: Taxonomy[] = [];
  public languageId?: number;
  public languageCode?: string;
  public countries: CountryRelationship[] = [];
  public sites: Taxonomy[] = [];
  public translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  } = { title: {}, description: {} };

  public fromJSON(json: Partial<PublicTreatmentJSON>) {
    this.id = Number(json.id);

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.description) {
      this.description = String(json.description);
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }

    if (json.treatments) {
      this.treatments = json.treatments.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.treatments_count) {
      this.treatmentsCount = Number(json.treatments_count);
    }

    if (json.language_id) {
      this.languageId = Number(json.language_id);
    }

    if (json.language_code) {
      this.languageCode = String(json.language_code);
    }

    if (json.countries) {
      this.countries = json.countries.map((item) => CountryRelationship.fromJSON(item));
    }

    if (json.sites) {
      this.sites = json.sites.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.translations) {
      this.translations = json.translations;
    }
  }
}
