
























































import Vue from 'vue';

import i18n from '@/i18n';
import { EditableStatus } from '@/store/types';
import SubmitButton from '@/components/controls/submit-button.vue';

export default Vue.extend({
  name: 'PublishForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    SubmitButton,
  },
  data() {
    return {
      error: {
        title: '',
        body: '',
      },
    };
  },
  props: {
    title: {
      type: String,
      default: i18n.t('Publish'),
    },
    description: {
      type: String,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canRestore: {
      type: Boolean,
      default: false,
    },
    canPreview: {
      type: Boolean,
      default: true,
    },
    canDraft: {
      type: Boolean,
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
    saveLabel: {
      type: String,
      default: i18n.t('Publish'),
    },
    previewLabel: {
      type: String,
      default: 'Preview',
    },
    downloadLabel: {
      type: String,
      default: i18n.t('Download Images'),
    },
    downloadTarget: {
      type: String,
      default: '',
    },
    deleteQuestion: {
      type: String,
      default: i18n.t('Do you really want to delete this?'),
    },
    restoreQuestion: {
      type: String,
      default: i18n.t('Do you really want to restore this?'),
    },
    status: {
      type: String as () => EditableStatus,
      default: EditableStatus.Loading,
      required: true,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError() {
      if (this.error && (this.error.title !== '' || this.error.body !== '')) {
        return true;
      }

      return false;
    },
    isSaving() {
      return !(this.canDraft && this.isDraft) && this.status === EditableStatus.Saving;
    },
    isSaved() {
      return !(this.canDraft && this.isDraft) && this.status === EditableStatus.Saved;
    },
    isFailed() {
      return !(this.canDraft && this.isDraft) && this.status === EditableStatus.Failed;
    },
    isDraftSaving() {
      return this.canDraft && this.isDraft && this.status === EditableStatus.Saving;
    },
    isDraftSaved() {
      return this.canDraft && this.isDraft && this.status === EditableStatus.Saved;
    },
    isDraftFailed() {
      return this.canDraft && this.isDraft && this.status === EditableStatus.Failed;
    },
  },
  methods: {
    clearError() {
      this.error.title = '';
      this.error.body = '';
    },
    errorMessage({ title = '', body = '' }) {
      this.error.title = title;
      this.error.body = body;
    },
    save({ isDraft = false } = {}) {
      this.clearError();

      this.$emit('beforeSave', { isDraft });

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('save', { isDraft });
        } else {
          this.errorMessage({
            title: this.$t('Error'),
            body: this.$t('Please correct the errors noted next to each field and try again.'),
          });
        }
      });
    },
    remove() {
      this.clearError();

      const answer = window.confirm(this.deleteQuestion);
      if (answer) {
        this.$emit('delete');
      }
    },
    restore() {
      this.clearError();

      const answer = window.confirm(this.restoreQuestion);
      if (answer) {
        this.$emit('restore');
      }
    },
    preview() {
      this.$emit('preview');
    },
  },
});
