import { ActionTree } from 'vuex';

import api from '@/api';
import router from '@/router';
import i18next from '@/i18n';

import Pagination from '@/store/models/pagination';
import { RootState, ErrorMessages, RootActions, EditableStatus, ListStatus } from '@/store/types';
import { prepareResourcesFilters } from '@/store/utils/filters';

import { NotificationsActions } from '@/store/modules/notifications/types';
import Category from '@/store/modules/categories/category';
import { CategoryState, CategoryActions, CategoryMutations } from '@/store/modules/categories/types';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionTree<CategoryState, RootState> = {
  [CategoryActions.LOAD]({ commit, dispatch }, { page = 1, sort, direction } = {}): Promise<void> {
    commit(CategoryMutations.SET_STATUS, ListStatus.Loading);

    return api.categories
      .list({ page, sort, direction })
      .then((response) => response.data)
      .then((response) => {
        const categories = response.data.map((category) => Category.fromJSON(category));
        commit(CategoryMutations.SET_LIST_PAGINATION, Pagination.fromJSON(response));
        commit(CategoryMutations.SET_LIST, categories);
        commit(CategoryMutations.SET_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(CategoryMutations.SET_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [CategoryActions.LOAD_ALL]({ commit, dispatch }, { filters = {} } = {}): Promise<void> {
    const { products, treatments, search } = prepareResourcesFilters(filters);

    commit(CategoryMutations.SET_ALL_STATUS, ListStatus.Loading);

    return api.categories
      .listAll({ products, treatments, search })
      .then((response) => response.data)
      .then((response) => {
        const categories = response.map((category) => Category.fromJSON(category));
        commit(CategoryMutations.SET_ALL, categories);
        commit(CategoryMutations.SET_ALL_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(CategoryMutations.SET_ALL_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [CategoryActions.LOAD_EDITABLE]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(CategoryMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    if (!id) {
      commit(CategoryMutations.SET_EDITABLE, new Category());
      commit(CategoryMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);

      return Promise.resolve();
    }

    return api.categories
      .get(id)
      .then((response) => response.data)
      .then((category) => {
        commit(CategoryMutations.SET_EDITABLE, Category.fromJSON(category));
        commit(CategoryMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(CategoryMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [CategoryActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(CategoryMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [CategoryActions.SAVE_EDITABLE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(CategoryMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.editable!.id);
        successMsg = i18next.t('A new resource category was added successfully.');

        if (isUpdate) {
          successMsg = i18next.t('The resource category was successfully updated.');
        }
      })
      .then(() => api.categories.store(state.editable!.toJSON('server')))
      .then((response) => response.data)
      .then((category) => {
        commit(CategoryMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        commit(CategoryMutations.SET_EDITABLE, Category.fromJSON(category));

        router.back();
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(CategoryMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [CategoryActions.SAVE_TRANSLATIONS]({ state, commit, dispatch }, translations): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(CategoryMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Saving);
        return api.categories.translate(state.editable!.id, translations);
      })
      .then((response) => response.data)
      .then(async (categoryData) => {
        const category = Category.fromJSON(categoryData);

        await dispatch(CategoryActions.UPDATE_EDITABLE, { translations: category.translations });

        commit(CategoryMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(CategoryMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [CategoryActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    commit(CategoryMutations.SET_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.categories.remove(id))
      .then(() => {
        dispatch(CategoryActions.LOAD_ALL);
        return dispatch(CategoryActions.LOAD, {
          page: router.currentRoute.params.page,
        });
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The resource category was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(CategoryMutations.SET_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [CategoryActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    commit(CategoryMutations.SET_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.categories.restore(id))
      .then(() => {
        dispatch(CategoryActions.LOAD_ALL);
        return dispatch(CategoryActions.LOAD, {
          page: router.currentRoute.params.page,
        });
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The resource category was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(CategoryMutations.SET_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
