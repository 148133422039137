






















































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Modal from '@/components/layout/modal.vue';
import ConsultationRequest from '@/store/modules/consultations/consultation-request';
import { ConsultationRequestStatus, ConsultationRequestStatuses } from '@/store/modules/consultations/types';
import { PracticeStatus } from '@/store/modules/practices/types';

export default Vue.extend({
  components: {
    NotificationBanner,
    Modal,
  },
  computed: {
    ...mapState('consultations', {
      consultation: 'editable',
    }),
    ...mapGetters('consultations', {
      isLoading: 'editableIsLoading',
      isFailed: 'editableIsFailed',
    }),
    ...mapGetters(['isDirect']),
  },
  methods: {
    close() {
      if (this.isDirect) {
        this.$router.push({
          name: 'consultations',
        });
      } else {
        this.$router.back();
      }
    },
    requestStatus(selectedConsultationRequest: ConsultationRequest) {
      if (
        selectedConsultationRequest.status === ConsultationRequestStatus.Inactive &&
        selectedConsultationRequest.practice &&
        selectedConsultationRequest.practice.status === PracticeStatus.Inactive
      ) {
        return this.$t('Practice Deleted');
      }

      return ConsultationRequestStatuses[selectedConsultationRequest.status];
    },
  },
});
