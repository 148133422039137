





























































































































































































































































































































































import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

import { PublishStatus } from '@/store/types';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import PublishStatusBlock from '@/components/blocks/publish-status.vue';
import createSlug from '@/utils/slug';
import { formatNumber } from '@/i18n';
import { StatisticsActions } from '@/store/modules/statistics/types';

export default Vue.extend({
  name: 'AdminDashboardView',
  components: {
    NotificationBanner,
    PublishStatusBlock,
  },
  data() {
    return {
      PublishStatus,
      downloadsInterval: '30',
      trainingsInterval: '30',
      usersWidgetShows: 'users',
    };
  },
  computed: {
    /**
     * Profile
     */
    ...mapGetters('user/profile', ['isSuperAdmin']),

    /**
     * Locations
     */
    ...mapState('locations', {
      locationsTotalItems: 'pendingListTotalItems',
      locations: 'pendingList',
    }),
    ...mapGetters('locations', {
      locationsIsLoading: 'pendingListIsLoading',
      locationsIsLoaded: 'pendingListIsLoaded',
      locationsIsError: 'pendingListIsError',
    }),

    /**
     * Downloads
     */
    ...mapState('statistics', ['downloads', 'downloadsPagination', 'totalDownloads']),
    ...mapGetters('statistics', ['downloadsIsLoading', 'downloadsIsLoaded', 'downloadsIsError', 'downloadsIsEmpty']),

    /**
     * Logins
     */
    ...mapState('statistics', ['logins', 'loginsPagination']),
    ...mapGetters('statistics', ['loginsIsLoading', 'loginsIsLoaded', 'loginsIsError', 'loginsIsEmpty']),

    /**
     * Users
     */
    ...mapState('statistics', ['users', 'usersPagination']),
    ...mapGetters('statistics', ['usersIsLoading', 'usersIsLoaded', 'usersIsError', 'usersIsEmpty']),

    /**
     * Trainings
     */
    ...mapState('statistics', ['trainings', 'trainingsPagination', 'totalTrainings']),
    ...mapGetters('statistics', ['trainingsIsLoading', 'trainingsIsLoaded', 'trainingsIsError', 'trainingsIsEmpty']),
  },
  methods: {
    createSlug,
    formatNumber,
    ...mapActions('statistics', {
      loadDownloads: StatisticsActions.LOAD_DOWNLOADS,
      loadLogins: StatisticsActions.LOAD_LOGINS,
      loadUsers: StatisticsActions.LOAD_USERS,
      loadTrainings: StatisticsActions.LOAD_TRAININGS,
    }),
  },
  watch: {
    downloadsInterval(val) {
      this.loadDownloads({
        days: Number(val),
      });
    },
    trainingsInterval(val) {
      this.loadTrainings({
        days: Number(val),
      });
    },
    usersWidgetShows(val) {
      if (val === 'users') {
        this.loadUsers();
      } else {
        this.loadLogins();
      }
    },
  },
});
