import { JSONModel } from '@/store/models/jsonModel';
import { getEnumValue } from '@/utils/enum';
import PracticeRelationship, { PracticeRelationshipJSON } from '@/store/modules/practices/practice-relationship';
import LocationRelationship, { LocationRelationshipJSON } from '@/store/modules/locations/location-relationship';
import { ConsultationRequestStatus } from './types';

export interface ConsultationRequestJSON {
  id: number;
  status: number;
  pardot_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  treatment: string;
  practice: PracticeRelationshipJSON | null;
  listing: LocationRelationshipJSON | null;
  is_new: 0 | 1;
  created_at: string;
  updated_at: string;
}

export default class ConsultationRequest extends JSONModel<ConsultationRequestJSON> {
  public id: number = 0;
  public status: ConsultationRequestStatus = ConsultationRequestStatus.Active;
  public pardotId: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public phone: string = '';
  public treatment: string = '';
  public practice?: PracticeRelationship;
  public location?: LocationRelationship;
  public isNew: boolean = false;
  public createdAt: string = '';
  public updatedAt: string = '';

  protected _alias = {
    location: 'listing',
  };

  get fullName(): string {
    const name = [this.firstName, this.lastName].filter((val) => Boolean(val)).join(' ');

    if (name) {
      return name;
    }

    return this.email;
  }

  get practiceName(): string {
    return this.practice && this.practice.title ? this.practice.title : '';
  }

  get locationName(): string {
    return this.location && this.location.title ? this.location.title : '';
  }

  public fromJSON(json: Partial<ConsultationRequestJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    const status = getEnumValue<ConsultationRequestStatus>(ConsultationRequestStatus, json.status);
    if (typeof status !== 'undefined') {
      this.status = status;
    }

    if (json.pardot_id) {
      this.pardotId = String(json.pardot_id);
    }

    if (json.first_name) {
      this.firstName = String(json.first_name);
    }

    if (json.last_name) {
      this.lastName = String(json.last_name);
    }

    if (json.email) {
      this.email = String(json.email);
    }

    if (json.phone) {
      this.phone = String(json.phone);
    }

    if (json.treatment) {
      this.treatment = String(json.treatment);
    }

    if (json.practice) {
      this.practice = PracticeRelationship.fromJSON(json.practice);
    }

    if (json.listing) {
      this.location = LocationRelationship.fromJSON(json.listing);
    }

    if (json.is_new) {
      this.isNew = true;
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }
  }
}
