
















































































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import { formatNumber } from '@/i18n';

import Pagination from '@/store/models/pagination';
import {
  ConsultationsActions,
  ConsultationRequestStatus,
  ConsultationRequestStatuses,
} from '@/store/modules/consultations/types';
import ConsultationRequest from '@/store/modules/consultations/consultation-request';
import { PracticeStatus } from '@/store/modules/practices/types';

import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import ConsultationsFilter from '@/components/forms/consultations-filter.vue';
import CheckboxControl from '@/components/controls/checkbox.vue';

export default mixins(tableViewMixin).extend({
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    ConsultationsFilter,
    CheckboxControl,
  },
  data(): {
    localSelectedItems: number[];
  } {
    return {
      localSelectedItems: [],
    };
  },
  computed: {
    ...mapState('consultations', {
      consultations: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('consultations', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isEmpty: 'listIsEmpty',
    }),
    ...mapGetters('user/profile', ['isAdmin', 'isSuperAdmin']),
    selectedItems: {
      get(): number[] {
        if (!this.isLoaded || this.isEmpty || !this.localSelectedItems || !this.localSelectedItems.length) {
          return [];
        }

        const ids = (this.consultations as ConsultationRequest[]).map((item) => item.id);
        return this.localSelectedItems.filter((id) => ids.indexOf(id) !== -1);
      },
      set(val: number[]) {
        this.localSelectedItems = val;
      },
    },
    areAllItemsSelected(): boolean {
      return this.consultations.length && this.selectedItems.length === this.consultations.length;
    },
    canDelete(): boolean {
      return this.isAdmin || this.isSuperAdmin;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('consultations', {
      restoreAction: ConsultationsActions.RESTORE,
    }),
    requestStatus(selectedConsultationRequest: ConsultationRequest) {
      if (
        selectedConsultationRequest.status === ConsultationRequestStatus.Inactive &&
        selectedConsultationRequest.practice &&
        selectedConsultationRequest.practice.status === PracticeStatus.Inactive
      ) {
        return this.$t('Practice Deleted');
      }

      return ConsultationRequestStatuses[selectedConsultationRequest.status];
    },
    dropdownItems(selectedObject: ConsultationRequest) {
      const items: DropdownItems = {};

      items.view = {
        label: this.$t('View'),
        icon: 'document',
      };

      if (this.canDelete && selectedObject.status === ConsultationRequestStatus.Inactive) {
        items.restore = {
          label: this.$t('Restore'),
          icon: 'restore',
        };
      }

      return items;
    },
    handleClick(selectedObject: ConsultationRequest, action: string) {
      if ('view' === action) {
        this.$router.push({
          name: 'view-consultation',
          params: { id: String(selectedObject.id) },
        });
      }

      if (this.canDelete && 'restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this consultation request?'));

        if (answer) {
          this.restoreAction({ id: selectedObject.id });
        }
      }
    },
    isSelected(item: ConsultationRequest): boolean {
      return this.selectedItems.indexOf(item.id) !== -1;
    },
    selectItem(item: ConsultationRequest) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item.id);
        return;
      }

      this.selectedItems = [...this.selectedItems, item.id];
    },
    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.consultations.map((item: ConsultationRequest) => item.id);
      }
    },
  },
});
