




















import Vue from 'vue';
import MainHeader from '@/components/layout/main-header.vue';
import MainSidebar from '@/components/layout/main-sidebar.vue';
import MainFooter from '@/components/layout/main-footer.vue';
import SystemBanner from '@/components/blocks/system-banner.vue';
import StickyBanner from '@/components/blocks/sticky-banner.vue';
import MobileMenu from '@/components/layout/mobile-menu.vue';
import LoadingIndicator from '@/components/layout/loading-indicator.vue';
import TermsModal from '@/components/layout/terms-modal.vue';
import Snackbars from '@/components/blocks/snackbars.vue';
import TopBanner from '@/components/layout/top-banner.vue';
import Impersonation from '@/components/layout/impersonation.vue';
import AccessControl from '@/components/blocks/access-control.vue';

export default Vue.extend({
  name: 'Main',
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
    SystemBanner,
    StickyBanner,
    MobileMenu,
    LoadingIndicator,
    TermsModal,
    Snackbars,
    TopBanner,
    Impersonation,
    AccessControl,
  },
});
