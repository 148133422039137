
























import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { UserActions } from '@/store/modules/user/types';

import i18n from '@/components/i18n';

export default Vue.extend({
  name: 'ImpersonationLayout',
  components: {
    i18n,
  },
  computed: {
    ...mapGetters('user/profile', ['isImpersonating', 'displayName', 'currentProfile']),
  },
  methods: {
    ...mapActions('user', {
      endImpersonation: UserActions.END_IMPERSONATION,
    }),
  },
});
