

























































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import { ReportsActions } from '@/store/modules/reports/types';
import {
  ConsultationRequestStatuses,
  ConsultationsActions,
  ConsultationRequestStatus,
} from '@/store/modules/consultations/types';

import SubmitButton from '@/components/controls/submit-button.vue';
import PracticeSelectionControl from '@/components/controls/practice-selection.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';

export default Vue.extend({
  components: {
    SubmitButton,
    PracticeSelectionControl,
    Dropdown,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [] as number[],
    },
  },
  data() {
    return {
      availableStatuses: ConsultationRequestStatuses,
      search: '',
      status: '',
      type: 'leads',
      practice: undefined,
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    canDelete(): boolean {
      return this.isAdmin || this.isSuperAdmin;
    },
    dropdownItems(): DropdownItems {
      const items: DropdownItems = {};

      items.reset = {
        label: this.$t('Reset Filters'),
        icon: 'close',
      };

      if (this.isSuperAdmin) {
        items.report = {
          label: this.$t('Generate Report'),
          icon: 'document',
        };
      }

      if (this.canDelete && this.selectedItems && this.selectedItems.length) {
        items.restore = {
          hasTopBorder: true,
          label: this.$t('Restore'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };

        items.remove = {
          label: this.$t('Delete'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };
      }

      return items;
    },
  },
  methods: {
    ...mapActions('reports', {
      requestReport: ReportsActions.REQUEST,
    }),
    ...mapActions('consultations', {
      deleteConsultations: ConsultationsActions.BULK_DELETE,
      restoreConsultations: ConsultationsActions.BULK_RESTORE,
    }),
    initFilters(): void {
      this.search = this.$router.currentRoute.query.search || '';
      this.status = this.$router.currentRoute.query.status || ConsultationRequestStatus.Active;
      this.type = this.$router.currentRoute.query.type || 'leads';

      let newPracticeValue;

      if (this.$router.currentRoute.query.practice_id) {
        newPracticeValue = Number(this.$router.currentRoute.query.practice_id);

        if (!newPracticeValue || Number.isNaN(newPracticeValue)) {
          newPracticeValue = undefined;
        }
      }

      if (this.practice !== newPracticeValue) {
        this.practice = newPracticeValue;
      }
    },
    applyFilters(): void {
      const { sort, direction, ...queryParams } = this.$route.query;

      this.$router.push({
        params: {
          page: 1,
        },
        query: {
          ...queryParams,
          search: this.search,
          type: this.type,
          status: this.status,
          practice_id: this.practice,
        },
      });
    },
    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: 1,
          },
          query: {},
        });
      }

      if ('remove' === action) {
        this.deleteConsultations({ ids: this.selectedItems });
      }

      if ('restore' === action) {
        this.restoreConsultations({ ids: this.selectedItems });
      }

      if ('report' === action) {
        this.requestReport();
      }
    },
  },
  created(): void {
    this.initFilters();
  },
  watch: {
    $route(): void {
      this.initFilters();
    },
  },
});
