


































import Vue, { PropOptions } from 'vue';
import { mapState } from 'vuex';
import { getContentWithHighlightedRanges } from '@/utils/highlight';

export default Vue.extend({
  name: 'HighlightedTextareaControl',
  inheritAttrs: false,
  props: {
    highlight: {
      type: Array,
      default: () => [],
    } as PropOptions<string[]>,
    value: {
      type: String,
    },
  },
  data() {
    return {
      scroll: 0,
      focused: false,
      hovered: false,
    };
  },
  computed: {
    ...mapState(['isIOS']),
    backdropContent() {
      return getContentWithHighlightedRanges(this.value, this.highlight);
    },
  },
  methods: {
    adjustScroll(event) {
      if (event.target) {
        this.scroll = (event.target as HTMLTextAreaElement).scrollTop;
      }
    },
    adjustHeight() {
      if (this.$refs && this.$refs.textarea) {
        this.$refs.textarea.style.height = '';
        this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
      }
    },
    onBlur() {
      this.$emit('blur');
      this.focused = false;
    },
    onFocus() {
      this.$emit('focus');
      this.focused = true;
    },
  },
  mounted() {
    this.adjustHeight();
  },
});
