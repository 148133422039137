






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import ResourcePersonalizeCutup from '@/views/resource-personalize-cutup.vue';

export default Vue.extend({
  name: 'PreviewView',
  components: {
    ResourcePersonalizeCutup,
  },
  methods: {
    testSentry() {
      throw new Error('Test Sentry!');
    },
  },
});
