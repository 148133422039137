var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.listIsLoaded && !_vm.listIsEmpty) || (_vm.lessQualifiedListIsLoaded && !_vm.lessQualifiedListIsEmpty) || _vm.shouldShowCallsStats
  )?_c('div',{staticClass:"c-widget c-tile c-tile--item"},[_c('div',{staticClass:"c-divider c-divider--extra-small"},[_c('h6',[_c('svgicon',{staticClass:"c-icon--margin-right",attrs:{"name":"user"}}),_vm._v(" "+_vm._s(_vm.$t('Leads')))],1)]),_c('tabs',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"leads",fn:function(){return [(_vm.listIsLoaded && !_vm.listIsEmpty)?_c('div',[_vm._l((_vm.consultations.slice(0, 3)),function(consultation){return _c('div',{key:consultation.id,staticClass:"c-card"},[_c('ul',{staticClass:"c-card__group"},[_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'view-consultation',
                  params: { id: consultation.id },
                }}},[_vm._v(_vm._s(consultation.fullName)+" "),(consultation.isNew)?_c('small',{staticClass:"c-label c-label--success c-label--small u-uppercase"},[_vm._v(_vm._s(_vm.$t('New')))]):_vm._e()])],1),_c('li',[_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(consultation.createdAt)))])]),_c('li',[_c('p',[_vm._v(_vm._s(consultation.treatment))])]),(consultation.email)?_c('li',[_c('p',{staticClass:"c-card__meta"},[_c('svgicon',{attrs:{"name":"email"}}),_c('a',{attrs:{"href":'mailto:' + consultation.email}},[_vm._v(" "+_vm._s(consultation.email))])],1)]):_vm._e(),(consultation.phone)?_c('li',[_c('p',{staticClass:"c-card__meta"},[_c('svgicon',{attrs:{"name":"phone"}}),_c('a',{attrs:{"href":'phone:' + consultation.phone}},[_vm._v(" "+_vm._s(consultation.phone))])],1)]):_vm._e()]),_c('hr',{staticClass:"c-divider c-divider--extra-small"})])}),(_vm.pagination.total > 3)?_c('router-link',{staticClass:"c-button c-button--primary-transparent c-button--full",attrs:{"to":{ name: 'consultations' }}},[_vm._v(_vm._s(_vm.$t('View All')))]):_vm._e()],2):_vm._e()]},proxy:true},{key:"less",fn:function(){return [(_vm.lessQualifiedListIsLoaded && !_vm.lessQualifiedListIsEmpty)?_c('div',[_vm._l((_vm.lessQualified.slice(0, 3)),function(consultation){return _c('div',{key:consultation.id,staticClass:"c-card"},[_c('ul',{staticClass:"c-card__group"},[_c('a',{staticClass:"c-card__close",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkLessQualifiedComplete(consultation.id)}}},[_c('svgicon',{attrs:{"name":"delete"}})],1),_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'view-consultation',
                  params: { id: consultation.id },
                }}},[_vm._v(" "+_vm._s(consultation.fullName)+" ")])],1),_c('li',[_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(consultation.createdAt)))])]),_c('li',[_c('p',[_vm._v(_vm._s(consultation.treatment))])]),_c('li',[_c('p',{staticClass:"c-card__meta"},[_c('svgicon',{attrs:{"name":"email"}}),_c('a',{attrs:{"href":'mailto:' + consultation.email}},[_vm._v(" "+_vm._s(consultation.email))])],1)])]),_c('hr',{staticClass:"c-divider c-divider--extra-small"})])}),(_vm.lessQualifiedPagination.total > 3)?_c('router-link',{staticClass:"c-button c-button--primary-transparent c-button--full",attrs:{"to":{ name: 'consultations', query: { type: 'less-qualified' } }}},[_vm._v(_vm._s(_vm.$t('View All')))]):_vm._e()],2):_vm._e()]},proxy:true}],null,false,1539172931)}),(_vm.shouldShowCallsStats)?_c('div',{staticClass:"c-card"},[_c('ul',{staticClass:"c-card__group"},[_c('li',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.callsStatsMessage)}})])])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }