












































import mixins from 'vue-typed-mixins';

import { imageSizeMixin } from '@/components/mixins/image-size';
import PublishStatusBlock from '@/components/blocks/publish-status.vue';

import { PublishStatus } from '@/store/types';
import Location from '@/store/modules/locations/location';

export default mixins(imageSizeMixin).extend({
  name: 'LocationBlock',
  components: {
    PublishStatusBlock,
  },
  props: {
    location: {
      type: Object as () => Location,
    },
  },
  computed: {
    isPublished() {
      return this.location.status === PublishStatus.Published;
    },
    isPending() {
      return this.location.status === PublishStatus.Pending;
    },
    isDeclined() {
      return this.location.status === PublishStatus.Declined;
    },
    map() {
      return this.location.mapUrl
        ? this.sizedLocationMap(this.location.mapUrl)
        : require('@/assets/images/location-empty.png');
    },
  },
});
