




import Vue from 'vue';
import { mapState } from 'vuex';
import { ListStatus } from '@/store/types';

export default Vue.extend({
  name: 'CountryNameBlock',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(['countries', 'countriesStatus']),
    displayValue() {
      if (!this.value) {
        return '';
      }

      if (this.countriesStatus !== ListStatus.Loaded) {
        return '...';
      }

      const country = this.countries.find(({ id }) => id === Number(this.value));

      if (!country) {
        return '';
      }

      return country.name;
    },
  },
});
