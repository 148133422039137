





































































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import { formatNumber } from '@/i18n';

import Pagination from '@/store/models/pagination';
import { TreatmentActions } from '@/store/modules/treatments/types';
import PublicTreatment, { PublicTreatmentStatus } from '@/store/modules/treatments/public-treatment';

import CheckboxControl from '@/components/controls/checkbox.vue';
import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import PublicTreatmentsFilter from '@/components/forms/public-treatments-filter.vue';
import CountryName from '@/components/blocks/country-name.vue';

export default mixins(tableViewMixin).extend({
  name: 'PublicTreatmentsView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    PublicTreatmentsFilter,
    CheckboxControl,
    CountryName,
  },
  data() {
    return {
      selectedItems: [],
      PublicTreatmentStatus,
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('treatments', {
      publicTreatments: 'publicTreatmentList',
      pagination: 'publicTreatmentListPagination',
    }),
    ...mapGetters('treatments', {
      isLoading: 'publicTreatmentListIsLoading',
      isLoaded: 'publicTreatmentListIsLoaded',
      isEmpty: 'publicTreatmentListIsEmpty',
    }),
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.publicTreatments.length;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('treatments', {
      restoreAction: TreatmentActions.RESTORE_PUBLIC_TREATMENT,
      removeAction: TreatmentActions.DELETE_PUBLIC_TREATMENT,
    }),
    dropdownItems(publicTreatment: PublicTreatment) {
      const items: DropdownItems = {
        edit: {
          label: this.$t('Edit'),
          icon: 'pencil',
        },
      };

      if (publicTreatment.status === PublicTreatmentStatus.Inactive) {
        items.restore = {
          label: this.$t('Restore'),
          icon: 'restore',
        };
      } else {
        items.remove = {
          label: this.$t('Delete'),
          hasTopBorder: true,
          icon: 'trash',
        };
      }

      return items;
    },
    handleClick(publicTreatment: PublicTreatment, action: string) {
      if ('edit' === action) {
        this.$router.push({
          name: 'edit-public-treatment',
          params: { id: String(publicTreatment.id) },
        });
      }

      if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this public treatment?'));

        if (answer) {
          this.restoreAction({ id: publicTreatment.id });
        }
      }

      if ('remove' === action) {
        const answer = window.confirm(this.$t('Do you really want to delete this public treatment?'));

        if (answer) {
          this.removeAction({ id: publicTreatment.id });
        }
      }
    },
    isSelected(publicTreatment: PublicTreatment): boolean {
      return this.selectedItems.indexOf(publicTreatment.id) !== -1;
    },
    selectItem(publicTreatment: PublicTreatment) {
      if (this.isSelected(publicTreatment)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== publicTreatment.id);
        return;
      }

      this.selectedItems.push(publicTreatment.id);
    },
    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.publicTreatments.map((publicTreatment: PublicTreatment) => publicTreatment.id);
      }
    },
  },
});
