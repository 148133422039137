











import Vue from 'vue';

export default Vue.extend({
  name: 'ModalImageLayout',
  props: {
    preview: String,
  },
});
