


































































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import config from '@/config';

import { RootActions, EventTypes } from '@/store/types';
import ResourceFile from '@/store/modules/resources/file';

import ImageModal from '@/components/layout/modal-image.vue';
import ResourceAssetCompleteTrainingForm from '@/components/forms/resource-asset-complete-training.vue';
import { addURLParams } from '@/utils/url';

export default Vue.extend({
  name: 'ResourceFileBlock',
  components: {
    ResourceAssetCompleteTrainingForm,
  },
  props: ['file', 'parentThumbnail'],
  data() {
    return {
      showPreview: false,
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('resources', {
      resource: 'currentResource',
    }),
    thumbnail(): string {
      if (this.file && this.file.thumbnail) {
        return addURLParams(this.file.thumbnail, {
          'w': '480',
          'h': '660',
          'fit': 'crop',
          'crop': 'focalpoint',
          'fp-x': '0',
          'fp-y': '0',
        });
      }

      if (this.parentThumbnail) {
        return this.parentThumbnail;
      }

      return require('@/assets/images/paper-cover-default.png');
    },
    preview(): string | false {
      if (this.file && this.file.thumbnail) {
        return addURLParams(this.file.thumbnail, {
          w: '900',
          h: '900',
          fit: 'crop',
        });
      }

      return false;
    },
    downloadUri(): string {
      if (this.file && this.file.uri) {
        return config.API_HOSTNAME + this.file.uri.replace('/api', '');
      }

      return '';
    },
    isTraining(): boolean {
      if (this.isSuperAdmin || this.isAdmin) {
        return false;
      }

      return typeof this.resource !== 'undefined' && this.resource.training === 1;
    },
    unresolvedFiles(): ResourceFile[] {
      if (!this.isTraining) {
        return [];
      }

      return this.resource.files.filter((item) => !item.trainingComplete);
    },
    showCompleteTrainingCheckbox(): boolean {
      if (!this.isTraining) {
        return false;
      }

      // If file is completed.
      if (this.file.trainingComplete) {
        return true;
      }

      if (!this.unresolvedFiles.length) {
        return true;
      }

      return this.unresolvedFiles[0].id === this.file.id;
    },
    canDownload(): boolean {
      if (!this.isTraining) {
        return true;
      }

      if (this.showCompleteTrainingCheckbox) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions([RootActions.DISPATCH_EVENT]),
    download(event): void {
      if (!this.canDownload) {
        event.preventDefault();
        return;
      }

      this[RootActions.DISPATCH_EVENT]({
        type: EventTypes.DOWNLOAD,
        eventObj: { resource: this.resource, file: this.file },
      });
    },
    togglePreview(): void {
      this.$modal.show(ImageModal, { preview: this.preview }, { name: 'resource-preview' });
    },
  },
});
