



















































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import { TreatmentActions } from '@/store/modules/treatments/types';
import { PublicSiteStatus } from '@/store/modules/treatments/public-site';

import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Publish from '@/components/forms/publish.vue';

export default Vue.extend({
  name: 'EditPublicSiteView',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    SubmitButton,
    NotificationBanner,
    Publish,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('treatments', {
      publicSite: 'publicSite',
      editableStatus: 'publicSiteStatus',
    }),
    ...mapGetters('treatments', {
      isLoading: 'publicSiteIsLoading',
      isSaving: 'publicSiteIsSaving',
      hasUnsavedChanges: 'publicSiteHasUnsavedChanges',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    isInactive() {
      return this.publicSite.status === PublicSiteStatus.Inactive;
    },
  },
  methods: {
    ...mapActions('treatments', {
      updatePublicSite: TreatmentActions.UPDATE_PUBLIC_SITE,
      save: TreatmentActions.SAVE_PUBLIC_SITE,
      removePublicSite: TreatmentActions.DELETE_PUBLIC_SITE,
      restorePublicSite: TreatmentActions.RESTORE_PUBLIC_SITE,
    }),
    remove() {
      this.removePublicSite({ id: this.publicSite.id });
    },
    restore() {
      this.restorePublicSite({ id: this.publicSite.id });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});
