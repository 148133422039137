




















import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import { ProfileActions } from '@/store/modules/user/profile/types';
import MarkdownRederer from '@/components/blocks/markdown-renderer.vue';

export default Vue.extend({
  name: 'StickyBannerBlock',
  components: {
    MarkdownRederer,
  },
  computed: {
    ...mapGetters('user/profile', ['showTermsBanner']),
    ...mapState('settings', ['settings']),
  },
  methods: {
    ...mapActions('user/profile', {
      save: ProfileActions.SAVE_TERMS_AGREE,
      showModal: ProfileActions.SHOW_TERMS_AGREE_MODAL,
    }),
    handleDocumentClick(event) {
      const target: HTMLElement | null = event.target;

      if (!target || target.tagName.toLowerCase() !== 'a' || target.getAttribute('href') !== '#showModal') {
        return;
      }

      this.showModal();
    },
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick);
  },
});
