







































































































import { mapActions, mapState, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import config from '@/config';
import ResourceBlock from '@/components/blocks/resource.vue';
import ResourceFile from '@/components/blocks/resource-file.vue';
import ResourceLink from '@/components/blocks/resource-link.vue';
import { RootActions, EventTypes, PublishStatus } from '@/store/types';
import { imageSizeMixin } from '@/components/mixins/image-size';
import ImageModal from '@/components/layout/modal-image.vue';
import WistiaVideo from '@/components/layout/wistia-video.vue';
import ResourcePersonalize from '@/components/blocks/resource-personalize.vue';
declare global {
  interface Window {
    LimelightPlayerUtil: any;
  }
}

export default mixins(imageSizeMixin).extend({
  name: 'ResourceView',
  components: {
    ResourceBlock,
    ResourceFile,
    ResourceLink,
    WistiaVideo,
    ResourcePersonalize,
  },
  data() {
    return {
      showPreview: false,
    };
  },

  computed: {
    ...mapState('resources', ['currentResource', 'related', 'isResourceAccessedDirectly']),
    ...mapGetters('resources', {
      hasDownloads: 'hasDownloads',
      isLoaded: 'currentIsLoaded',
      isLoading: 'currentIsLoading',
      areAllTrainingAssetsCompleted: 'areAllTrainingAssetsCompleted',
    }),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin', 'isClinical', 'isInNorthAmerica']),
    fullyLoaded(): boolean {
      return this.isLoaded && !!this.currentResource;
    },
    zipUrl(): string {
      if (this.fullyLoaded) {
        return config.API_HOSTNAME + this.currentResource.zip.replace('/api', '');
      }
      return '';
    },
    isDeleted() {
      return this.currentResource.status === PublishStatus.Deleted;
    },
    storeLinkText() {
      if (this.currentResource.storeText) {
        return this.currentResource.storeText;
      }
      return 'Buy Now';
    },
    hasVideo(): boolean {
      if (this.fullyLoaded) {
        if (this.currentResource.video) {
          if (this.currentResource.video.length > 0) {
            return true;
          }
        }
        return false;
      }
      return false;
    },
    videoId(): string {
      if (this.hasVideo) {
        return this.currentResource.video;
      }
      return '';
    },
    thumbnail(): string {
      if (this.currentResource && this.currentResource.thumbnail && this.currentResource.thumbnail.thumbnail) {
        return this.sizedResourceThumbnail(this.currentResource.thumbnail.thumbnail);
      }
      return require('@/assets/images/paper-cover-default.png');
    },
    preview(): string | false {
      if (this.currentResource && this.currentResource.thumbnail && this.currentResource.thumbnail.thumbnail) {
        return this.sizedResourcePreview(this.currentResource.thumbnail.thumbnail);
      }
      return false;
    },
    backLabel(): string {
      return !this.isResourceAccessedDirectly ? this.$t('Back to results') : this.$t('All resources');
    },
    showDownloadAll(): boolean {
      if (!this.hasDownloads) {
        return false;
      }

      if (this.currentResource.training) {
        return this.areAllTrainingAssetsCompleted;
      }

      return true;
    },
  },
  methods: {
    ...mapActions([RootActions.DISPATCH_EVENT]),
    togglePreview() {
      this.$modal.show(ImageModal, { preview: this.preview }, { name: 'resource-preview' });
    },
    downloadAll() {
      this[RootActions.DISPATCH_EVENT]({
        type: EventTypes.DOWNLOAD_ALL,
        eventObj: {
          resource: this.currentResource,
        },
      });
    },
    goBack() {
      if (!this.isResourceAccessedDirectly) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: 'resources',
        });
      }
    },
  },
});
